import React, { useState, useEffect } from 'react';

import { Box, List } from '@mui/material';

import useAuth from '../../hooks/useAuth';
import SvgIconStyle from '../SvgIconStyle';
import NavigationItem from './NavigationItem';
import { useSelector } from '../../redux/store';
import ModuleCheck from '../access-checks/ModuleCheck';
import { addPinnedFolders } from '../../redux/slices/dlxDrive';
import PermissionCheck from '../access-checks/PermissionCheck';
import { getPinnedFolders } from '../../services/dlxDriveService';
import { ModuleTypes } from '../../@types/module/enums/moduleTypes';
import { Permissions } from '../../@types/auth/constants/permissions';
import DLXDriveFolderIcon from '../../utils/dlx-drive/DLXDriveFolderIcon';
import { FOLDER_DEFAULT_ICON_COLOR } from '../../utils/constants/dlxDriveConstants';
import {
  PATH_HR,
  PATH_ADMIN,
  PATH_CALENDAR,
  PATH_STANDARD,
  PATH_DASHBOARD,
  PATH_DLX_DRIVE,
  PATH_DOCUMENTS,
  PATH_ACCOUNTING,
  PATH_ADDRESSBOOK,
} from '../../routes/paths';

type NavItemProps = {
  title: string;
  path: string;
  icon: JSX.Element;
};

const Navigation = () => {
  const [pinnedFolders, setPinnedFolders] = useState<NavItemProps[]>([]);
  const { reloadNavigation } = useSelector((state) => state.dlxDrive);
  const { user } = useAuth();

  const getIcon = (name: string) => (
    <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 32, height: 32 }} />
  );

  const ICONS = {
    documents: getIcon('documents'),
    users: getIcon('ic_user'),
    permissions: getIcon('permissions'),
    company: getIcon('company_edit'),
    templates: getIcon('template'),
    tags: getIcon('tags'),
    section: getIcon('section'),
    tenants: getIcon('tenants'),
    admin: getIcon('admin'),
    hr: getIcon('ic_user_group'),
    accounting: getIcon('accounting'),
    addressBook: getIcon('addressBook'),
    dashboard: getIcon('home'),
    documentTree: getIcon('document_tree'),
    dlxDrive: getIcon('dlx_drive'),
    pinnedFolder: getIcon('folder_pinned'),
    calendar: getIcon('calendar'),
  };

  const NAVIGATION = [
    {
      title: 'layout.dashboard',
      path: PATH_DASHBOARD.root,
      icon: ICONS.dashboard,
    },
    {
      title: 'layout.hr',
      path: PATH_HR.documents,
      icon: ICONS.hr,
    },
    {
      title: 'layout.accounting',
      path: PATH_ACCOUNTING.documents,
      icon: ICONS.accounting,
    },
    {
      title: 'layout.dlxDrive',
      path: PATH_DLX_DRIVE.root,
      icon: ICONS.documentTree,
    },
    {
      title: 'layout.signingPortal',
      path: PATH_STANDARD.documents,
      icon: ICONS.section,
    },
    {
      title: 'layout.addressBook',
      path: PATH_ADDRESSBOOK.root,
      icon: ICONS.addressBook,
    },
    {
      title: 'layout.calendar',
      path: PATH_CALENDAR.root,
      icon: ICONS.calendar,
    },
    {
      title: 'layout.admin',
      path: PATH_ADMIN.adminPanel,
      icon: ICONS.admin,
    },
    {
      title: 'layout.documents',
      path: PATH_DOCUMENTS.root,
      icon: ICONS.documents,
    },
  ];

  const fetchPinnedFolders = async () => {
    const folders = await getPinnedFolders();

    const newPinnedFolders: NavItemProps[] = [];
    const pinnedFolderIds: number[] = [];

    folders.forEach((folder) => {
      let newTitle = folder.name.split(' ');

      newTitle.forEach((word: string, index: number) => {
        if (word.length > 12) {
          newTitle[index] = `${word.slice(0, 9)}...`;
        }
      });

      newPinnedFolders.push({
        title: newTitle.join(' '),
        path: `${PATH_DLX_DRIVE.root}/${folder.id}/1`,
        icon: (
          <DLXDriveFolderIcon
            icon={folder.icon}
            color={folder.color.length > 0 ? folder.color : FOLDER_DEFAULT_ICON_COLOR}
            fontSize="32px"
          />
        ),
      });

      pinnedFolderIds.push(folder.id);
    });

    setPinnedFolders(newPinnedFolders);
    addPinnedFolders(pinnedFolderIds);
  };

  useEffect(() => {
    fetchPinnedFolders();
  }, [reloadNavigation]);

  return (
    <Box key={`navigation-${reloadNavigation}`}>
      <List component="nav" sx={{ p: 1 }}>
        {/* ---- DASHBOARD ----*/}
        <NavigationItem item={NAVIGATION[0]} />

        {/* ---- DOCUMENTS ----*/}
        {user?.roles && user?.roles.length > 0 && (
          <ModuleCheck module={ModuleTypes.WorkflowsModule}>
            <NavigationItem item={NAVIGATION[8]} />
          </ModuleCheck>
        )}

        {/* ---- HR ----*/}
        {user?.roles && user?.roles.length > 0 && (
          <ModuleCheck module={ModuleTypes.HR}>
            <PermissionCheck permissions={[Permissions.UseHRModule]}>
              <NavigationItem item={NAVIGATION[1]} />
            </PermissionCheck>
          </ModuleCheck>
        )}

        {/* ---- ACCOUNTING ----*/}
        {user?.roles && user?.roles.length > 0 && (
          <ModuleCheck module={ModuleTypes.Accounting}>
            <PermissionCheck permissions={[Permissions.UseAccountingModule]}>
              <NavigationItem item={NAVIGATION[2]} />
            </PermissionCheck>
          </ModuleCheck>
        )}

        {/* ---- DLX DRIVE ----*/}
        <NavigationItem item={NAVIGATION[3]} isDefault />

        {pinnedFolders.length > 0 &&
          pinnedFolders.map((item, index) => (
            <NavigationItem key={`pinned-folder-${index}`} item={item} isPinned />
          ))}

        {/* ---- STANDARD ----*/}
        <NavigationItem item={NAVIGATION[4]} />

        {/* ---- ADDRESS BOOK ----*/}
        {user?.roles && user?.roles.length > 0 && (
          <ModuleCheck module={ModuleTypes.AddressBook}>
            <PermissionCheck permissions={[Permissions.UseAddressBook]}>
              <NavigationItem item={NAVIGATION[5]} />
            </PermissionCheck>
          </ModuleCheck>
        )}

        {/* ---- CALENDAR ----*/}
        <ModuleCheck module={ModuleTypes.Calendar}>
          <PermissionCheck permissions={[Permissions.ViewCalendar]}>
            <NavigationItem item={NAVIGATION[6]} />
          </PermissionCheck>
        </ModuleCheck>

        {/* ---- ADMIN ----*/}
        {(user?.isAdmin || user?.isSuperAdmin) && <NavigationItem item={NAVIGATION[7]} />}
      </List>
    </Box>
  );
};

export default Navigation;
