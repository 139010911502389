import React, { useState, SyntheticEvent } from 'react';

import { TabList, TabPanel, TabContext } from '@mui/lab';
import { Box, Tab, Button, useTheme } from '@mui/material';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import useLocales from '../../../hooks/useLocales';
import FormTypesList from '../../forms/FormTypesList';
import useResponsive from '../../../hooks/useResponsive';
import AdminPanelBackButton from '../AdminPanelBackButton';
import FormTemplatesList from '../../forms/FormTemplatesList';

// ----------------------------------------------------------------------

const FormsList: React.FC = () => {
  const { t } = useLocales();
  const theme = useTheme();
  const [value, setValue] = useState('templates');
  const [downloadTemplatesReport, setDownloadTemplatesReport] = useState(false);
  const [downloadTypesReport, setDownloadTypesReport] = useState(false);
  const isMobile = useResponsive('down', 'sm');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleReportClick = () => {
    if (value === 'templates') {
      setDownloadTemplatesReport(true);
    } else {
      setDownloadTypesReport(true);
    }
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <AdminPanelBackButton />

      <TabContext value={value}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            px: isMobile ? 0 : 1,
            mb: 1,
          }}
        >
          <TabList onChange={handleChange}>
            <Tab label={t('formTemplates.formTemplates')} value="templates" />

            <Tab label={t('formTemplates.formTypes.formTypes')} value="types" />
          </TabList>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleReportClick}
            sx={{
              whiteSpace: 'nowrap',
              color: theme.palette.grey[200],
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              },
              my: isMobile ? 2 : 0,
            }}
          >
            <DownloadRoundedIcon fontSize="small" sx={{ mr: 1 }} />
            {t('documents.buttons.createReport')}
          </Button>
        </Box>

        <TabPanel value="templates">
          <FormTemplatesList
            downloadTemplatesReport={downloadTemplatesReport}
            setDownloadTemplatesReport={setDownloadTemplatesReport}
          />
        </TabPanel>

        <TabPanel value="types">
          <FormTypesList
            downloadTypesReport={downloadTypesReport}
            setDownloadTypesReport={setDownloadTypesReport}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default FormsList;
