import axios from 'src/utils/httpClients/axios';
import { mapJsonArray } from 'src/utils/mapUtils';

import { AUTH0_API } from 'src/config';
import { Tenant } from 'src/@types/tenants/tenant';
import { PagingFilter } from 'src/@types/shared/pagingFilter';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { TenantCreate, TenantUpdate, InviteAdminModel } from 'src/@types/tenants/types';

// ----------------------------------------------------------------------

const baseUrl = `${AUTH0_API.domain}api/tenants`;

async function getPagedTenants(filter: PagingFilter) {
  const response = await axios.get<PagedResponse<Tenant>>(baseUrl, { params: { ...filter } });

  return {
    total: response.data.total,
    items: mapJsonArray(response.data.items, Tenant),
  };
}

async function getTenant(id: number) {
  const response = await axios.get<PagedResponse<Tenant>>(`${baseUrl}/${id}`);
  return new Tenant().parseJson(response.data);
}

async function createTenant(tenant: TenantCreate) {
  await axios.post(baseUrl, { ...tenant });
}

async function editTenant(tenant: TenantUpdate) {
  await axios.put(`${baseUrl}/${tenant.id}`, { ...tenant });
}

async function inviteAdmin(model: InviteAdminModel) {
  await axios.post(`${baseUrl}/${model.tenantId}/invite-admin`, { ...model });
}

async function setActivation(id: number, isDeactivated: boolean) {
  await axios.put(`${baseUrl}/${id}/set-activation`, { isDeactivated });
}

async function exportCsv() {
  const response = await axios.post(`${baseUrl}/export-csv`, {
    offset: 0,
    limit: 99999,
    timezoneOffset: new Date().getTimezoneOffset(),
  });
  return new Blob([response.data]);
}

const aspTenantService = {
  getPagedTenants,
  getTenant,
  createTenant,
  editTenant,
  inviteAdmin,
  setActivation,
  exportCsv,
};

export default aspTenantService;
