import dayjs, { Dayjs } from 'dayjs';
import { format, addDays } from 'date-fns';
import { endOfDay, startOfDay } from 'date-fns/esm';
import React, { useRef, useState, useEffect } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Box, MenuItem, useTheme, TextField, IconButton } from '@mui/material';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

import useLocales from 'src/hooks/useLocales';

import { ValueName } from 'src/@types/shared/valueName';

import Tip from 'src/components/Tip';

//--------------------------------------------

export const formatStartDate = (start: Date): string => `${format(start, 'yyyy-MM-dd')}T00:00:00`;

export const formatEndDate = (end: Date): string => `${format(end, 'yyyy-MM-dd')}T23:59:59`;

const date = new Date();

const defaultThisMonthDate = {
  startDate: `${format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')}T00:00:00`,
  endDate: `${format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd')}T23:59:59`,
};

enum Periods {
  Custom = 0,
  Today = 1,
  Last7Days = 2,
  Last30Days = 3,
  ThisYear = 4,
}

const periods: ValueName<Periods>[] = [
  {
    value: Periods.Custom,
    localeName: 'billingReport.periods.custom',
  },
  {
    value: Periods.Today,
    localeName: 'billingReport.periods.today',
  },
  {
    value: Periods.Last7Days,
    localeName: 'billingReport.periods.last7Days',
  },
  {
    value: Periods.Last30Days,
    localeName: 'billingReport.periods.last30Days',
  },
  {
    value: Periods.ThisYear,
    localeName: 'billingReport.periods.thisYear',
  },
];

export type DateValuesProps = {
  startDate: string;
  endDate: string;
};

type StatisticsDateRangeProps = {
  defaultRangeDate: { startDate: string; endDate: string };
  setDateStatistics: (value: DateValuesProps) => void;
};

const StatisticsDateRange: React.FC<StatisticsDateRangeProps> = ({
  defaultRangeDate,
  setDateStatistics,
}) => {
  const { t } = useLocales();
  const theme = useTheme();
  const [period, setPeriod] = useState<Periods>(Periods.Custom);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);

  const defaultDate = {
    startDate: dayjs(defaultThisMonthDate.startDate.split('T')[0]),
    endDate: dayjs(defaultThisMonthDate.endDate.split('T')[0]),
  };

  const dayFormat = (dateStr: string) => dayjs(dateStr.split('T')[0]);

  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayFormat(defaultRangeDate.startDate),
    dayFormat(defaultRangeDate.endDate),
  ]);

  const [dateValue, setDateValue] = useState<DateValuesProps>(defaultRangeDate);

  const initialPeriodRef = useRef(period);
  const initialDateRange = useRef(dateRange);

  const handleReset = () => {
    setPeriod(Periods.Custom);
    setDateValue({ startDate: defaultRangeDate.startDate, endDate: defaultRangeDate.endDate });
    setFieldsDisabled(false);

    setDateRange([defaultDate.startDate, defaultDate.endDate]);

    setDateStatistics({
      startDate: defaultThisMonthDate.startDate,
      endDate: defaultThisMonthDate.endDate,
    });
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as Periods;
    setPeriod(value);
    switch (value) {
      case Periods.Custom: {
        setFieldsDisabled(false);
        break;
      }
      case Periods.Today: {
        let start = startOfDay(new Date());
        let end = endOfDay(new Date());

        setDateValue({
          startDate: formatStartDate(start),
          endDate: formatEndDate(end),
        });

        setFieldsDisabled(true);
        break;
      }
      case Periods.Last7Days: {
        let start = addDays(startOfDay(new Date()), -6);
        let end = endOfDay(new Date());

        setDateValue({
          startDate: formatStartDate(start),
          endDate: formatEndDate(end),
        });

        setFieldsDisabled(true);
        break;
      }
      case Periods.Last30Days: {
        let start = addDays(startOfDay(new Date()), -29);
        let end = endOfDay(new Date());

        setDateValue({
          startDate: formatStartDate(start),
          endDate: formatEndDate(end),
        });

        setFieldsDisabled(true);
        break;
      }
      case Periods.ThisYear: {
        const currentYear = new Date().getFullYear();

        let start = new Date(`${currentYear}-1-1`);
        let end = endOfDay(new Date());

        setDateValue({
          startDate: formatStartDate(start),
          endDate: formatEndDate(end),
        });

        setFieldsDisabled(true);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const isInitialPeriod = period === initialPeriodRef.current;
    const isInitialDateRange =
      dateRange[0] === initialDateRange.current[0] && dateRange[1] === initialDateRange.current[1];

    if (
      period === Periods.Custom &&
      dateRange[0] &&
      dateRange[1] &&
      (!isInitialPeriod || !isInitialDateRange)
    ) {
      const start = dayjs(`${dateRange[0]}`).toDate();
      const end = dayjs(`${dateRange[1]}`).toDate();

      setDateValue({
        startDate: formatStartDate(start),
        endDate: formatEndDate(end),
      });

      localStorage.setItem('dateRangeFrom', formatStartDate(start));
      localStorage.setItem('dateRangeTo', formatEndDate(end));
    }
  }, [period, dateRange]);

  useEffect(() => {
    if (dateValue.startDate !== '' && dateValue.endDate !== '') {
      setDateStatistics({ startDate: dateValue.startDate, endDate: dateValue.endDate });
    }
  }, [dateValue]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2.5, width: '100%' }}>
      <TextField
        id="period-select"
        size="small"
        select
        fullWidth
        label={t('billingReport.fields.period')}
        value={period}
        onChange={handlePeriodChange}
      >
        {periods.map((p, index) => (
          <MenuItem key={index} id={`period-${index}`} value={p.value}>
            {t(p.localeName)}
          </MenuItem>
        ))}
      </TextField>

      <DateRangePicker
        disabled={fieldsDisabled}
        value={dateRange}
        defaultValue={dateRange}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          textField: {
            id: 'date-range-picker',
            variant: 'outlined',
            size: 'small',
            fullWidth: true,
            label: t('billingReport.fields.dateRange'),
          },
        }}
        sx={{
          opacity: fieldsDisabled ? 0 : 1,
          width: fieldsDisabled ? '0%' : '100%',
          transition: 'opacity 0.3s ease, width 0.3s ease',
        }}
        onChange={(newValue) => {
          setDateRange(newValue);
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 0,
          alignItems: 'center',
          mr: 1,
        }}
      >
        <Tip titleLocale="shared.buttons.reset">
          <IconButton
            size="small"
            sx={{ color: theme.palette.secondary.light }}
            onClick={handleReset}
          >
            <RefreshIcon />
          </IconButton>
        </Tip>
      </Box>
    </Box>
  );
};

export default StatisticsDateRange;
