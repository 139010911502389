import { debounce } from 'lodash';
import { useParams, useNavigate } from 'react-router';
import React, { useState, useEffect, useCallback } from 'react';

import { Box, Card, Fade, Stack, Drawer, Checkbox, Container } from '@mui/material';

import useNotifications from 'src/hooks/useNotifications';

import Page from '../../components/Page';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import { PATH_DLX_DRIVE } from '../../routes/paths';
import useResponsive from '../../hooks/useResponsive';
import { getFolders } from '../../services/dlxDriveService';
import eventBus, { EventTypes } from '../../utils/eventBus';
import DLXDriveList from '../../sections/dlx-drive/DLXDriveList';
import { DLXDriveFilterType } from '../../@types/dlx-drive/types';
import DLXDriveTitle from '../../sections/dlx-drive/DLXDriveTitle';
import DLXDriveInfoBar from '../../sections/dlx-drive/DLXDriveInfoBar';
import FolderTree from '../../sections/dlx-drive/folder-tree/FolderTree';
import DLXDriveSearchBar from '../../sections/dlx-drive/DLXDriveSearchBar';
import DLXDrivePagination from '../../sections/dlx-drive/DLXDrivePagination';
import DLXDriveBreadcrumbs from '../../sections/dlx-drive/DLXDriveBreadcrumbs';
import DLXDriveRowsPerPage from '../../sections/dlx-drive/DLXDriveRowsPerPage';
import DLXDriveSortActions from '../../sections/dlx-drive/DLXDriveSortActions';
import DLXDriveFolderActions from '../../sections/dlx-drive/DLXDriveFolderActions';
import DLXDriveSortByItems from '../../@types/dlx-drive/enums/DLXDriveSortByItems';
import DLXDriveDocumentActions from '../../sections/dlx-drive/DLXDriveDocumentActions';
import DLXDriveBulkActionComponent from '../../sections/dlx-drive/DLXDriveBulkActionComponent';
import {
  addChildrenState,
  addBreadcrumbsState,
  addStopLoadingState,
  setDriveListIdState,
  addStartLoadingState,
  setCheckedItemsState,
  addSelectedFolderState,
} from '../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

const DLXDrive = () => {
  const { t } = useLocales();
  const [folderId, setFolderId] = useState<number | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const { breadcrumbs, reloadDriveList, reloadFillForm, children, checkedItems, reloadSearch } =
    useSelector((state) => state.dlxDrive);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [sortDescending, setSortDescending] = useState<boolean>(false);
  const [sortingColumn, setSortingColumn] = useState<DLXDriveSortByItems>(0);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>(t('layout.dlxDrive'));
  const [showFolderTree, setShowFolderTree] = useState<boolean>(() => {
    const showFolderTree = localStorage.getItem('showFolderTree');
    return showFolderTree ? JSON.parse(showFolderTree) : true;
  });
  const { enqueueSuccess, enqueueError } = useNotifications();
  const { id, page } = useParams();
  const navigate = useNavigate();
  const isMobile = useResponsive('only', 'xs');
  const showDrawer = useResponsive('down', 'lg');
  const [folderTreeExpandedItems, setFolderTreeExpandedItems] = useState<string[]>(() => {
    const folderTreeExpandedItems = localStorage.getItem('folderTreeExpandedItems');

    return folderTreeExpandedItems ? JSON.parse(folderTreeExpandedItems) : [];
  });

  const handleSelectAll = () => {
    const newCheckedItems = [...checkedItems];

    if (isChecked) {
      children.forEach((item) => {
        const index = newCheckedItems.findIndex((checkedItem) => checkedItem.id === item.id);

        if (index !== -1) {
          newCheckedItems.splice(index, 1);
        }
      });
    } else {
      children.forEach((item) => {
        if (!newCheckedItems.some((checkedItem) => checkedItem.id === item.id)) {
          if (item.extension === undefined) {
            newCheckedItems.push({
              id: item.id,
              isFolder: true,
              name: item.name,
              actions: item.actions,
            });
          } else {
            newCheckedItems.push({
              id: item.id,
              isFolder: false,
              name: item.name,
              status: item.status,
              actions: item.actions,
            });
          }
        }
      });
    }

    setCheckedItemsState(newCheckedItems);
  };

  const getData = async () => {
    const filter: DLXDriveFilterType = {
      id: id ? parseInt(id, 10) : null,
      limit,
      offset,
      searchQuery,
      sortDescending,
      sortingColumn,
    };

    try {
      const data = await getFolders(filter);

      const { id, name, actions, prefix, children, breadcrumbs, total, color, icon } = data;

      addSelectedFolderState({ id, name, prefix, actions, color, icon });
      addChildrenState(children.items);

      setTotal(total);

      addBreadcrumbsState(breadcrumbs);
    } catch (error) {
      if (error instanceof Error) {
        enqueueError(error.message);
      }
    } finally {
      addStopLoadingState();
    }
  };

  const debounceGetData = useCallback(debounce(getData, 500), [
    id,
    limit,
    offset,
    searchQuery,
    sortDescending,
    sortingColumn,
  ]);

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = event.target.value;

    localStorage.setItem('rowsPerPage', newRowsPerPage);
    setLimit(parseInt(newRowsPerPage, 10));
  };

  useEffect(() => {
    addStartLoadingState();
    debounceGetData();
  }, [offset, sortDescending, sortingColumn, folderId, reloadDriveList, reloadFillForm]);

  useEffect(() => {
    if (id === undefined) {
      setOffset(0);
      setDriveListIdState();
    } else if (page && page === '1') {
      setDriveListIdState();
    } else {
      navigate(`${PATH_DLX_DRIVE.root}/${id}/1`);
    }
  }, [limit]);

  useEffect(() => {
    if (searchQuery !== null) {
      debounceGetData();
    }
  }, [searchQuery]);

  useEffect(() => {
    // ----- Folder ID -----

    if (!id) {
      setFolderId(null);
    }

    if (id && parseInt(id, 10) !== folderId) {
      setFolderId(parseInt(id, 10));
    }

    // ----- Page Number -----

    if (!page) {
      setOffset(0);
    }

    if (page && parseInt(page, 10) - 1 !== offset) {
      setOffset(parseInt(page, 10) - 1);
    }
  }, [id, page]);

  useEffect(() => {
    if (breadcrumbs.length > 0) {
      const newTitle = `${breadcrumbs[breadcrumbs.length - 1].name} - ${t('layout.dlxDrive')}`;

      if (pageTitle !== newTitle) {
        setPageTitle(newTitle);
      }
    }
  }, [breadcrumbs]);

  useEffect(() => {
    setSearchQuery(null);
  }, [reloadSearch]);

  useEffect(() => {
    let isAllChecked = true;

    if (checkedItems.length === 0 || children.length === 0) {
      isAllChecked = false;
    }

    children.forEach((item) => {
      if (!checkedItems.some((checkedItem) => checkedItem.id === item.id)) {
        isAllChecked = false;
      }
    });

    setIsChecked(isAllChecked);
  }, [checkedItems, children]);

  useEffect(() => {
    localStorage.setItem('showFolderTree', JSON.stringify(showFolderTree));
  }, [showFolderTree, folderTreeExpandedItems]);

  useEffect(() => {
    localStorage.setItem('folderTreeExpandedItems', JSON.stringify(folderTreeExpandedItems));
  }, [folderTreeExpandedItems]);

  useEffect(() => {
    const useRowsPerPage = localStorage.getItem('rowsPerPage');

    if (useRowsPerPage) {
      setLimit(parseInt(useRowsPerPage, 10));
    }

    const documentSignedSubscription = eventBus.subscribe(
      EventTypes.DocumentSigned,
      (msg: string) => {
        enqueueSuccess(msg);
      }
    );

    const forbidSignSubscription = eventBus.subscribe(EventTypes.ForbidSign, (msg: string) => {
      enqueueError(msg);
    });

    return () => {
      documentSignedSubscription.unsubscribe();
      forbidSignSubscription.unsubscribe();
    };
  }, []);

  return (
    <Page title={pageTitle}>
      <Container maxWidth={showFolderTree ? false : 'xl'}>
        <Stack direction="row" spacing={4}>
          {showFolderTree && !showDrawer && (
            <Card sx={{ width: { xl: '25%', lg: '30%' } }}>
              <FolderTree
                showDrawer={showDrawer || false}
                setShowFolderTree={setShowFolderTree}
                folderTreeExpandedItems={folderTreeExpandedItems}
                setFolderTreeExpandedItems={setFolderTreeExpandedItems}
              />
            </Card>
          )}

          <Box
            sx={{
              width: showFolderTree && !showDrawer ? { xl: '75%', lg: '70%' } : '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <DLXDriveBreadcrumbs
              showFolderTree={showFolderTree}
              setIsDrawerOpen={setShowFolderTree}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'left' : 'center',
                gap: 2,
                my: isMobile ? 0 : 0.5,
              }}
            >
              <Box flexGrow={1} sx={{ overflow: 'hidden' }} id="dfODF45">
                <DLXDriveTitle />
              </Box>

              <DLXDriveFolderActions isRootFolder={breadcrumbs.length === 1} />
            </Box>

            {breadcrumbs.length > 1 && folderId && <DLXDriveDocumentActions folderId={folderId} />}

            {
              // ---------------------
              //   For LARGE screens
              // ---------------------

              !isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Checkbox
                    disabled={children.length === 0}
                    checked={isChecked}
                    onClick={handleSelectAll}
                    sx={{ ml: '9px' }}
                    id="VFemjU"
                  />

                  <Box flexGrow={1}>
                    <DLXDriveSearchBar setSearchQuery={setSearchQuery} />
                  </Box>

                  <DLXDriveSortActions
                    sortingColumn={sortingColumn}
                    setSortingColumn={setSortingColumn}
                    sortDescending={sortDescending}
                    setSortDescending={setSortDescending}
                  />
                </Box>
              )
            }

            {
              // ---------------------
              //   For SMALL screens
              // ---------------------

              isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <Box flexGrow={1}>
                    <DLXDriveSearchBar setSearchQuery={setSearchQuery} />
                  </Box>

                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}
                  >
                    <Checkbox
                      disabled={children.length === 0}
                      checked={isChecked}
                      onClick={handleSelectAll}
                      sx={{ ml: '9px' }}
                      id="rKtIli"
                    />

                    <DLXDriveSortActions
                      sortingColumn={sortingColumn}
                      setSortingColumn={setSortingColumn}
                      sortDescending={sortDescending}
                      setSortDescending={setSortDescending}
                    />
                  </Box>
                </Box>
              )
            }

            <DLXDriveList items={children} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
                mt: 1,
              }}
            >
              {
                // ---------------------
                //   For LARGE screens
                // ---------------------

                !isMobile && (
                  <Box flexGrow={1}>
                    <DLXDriveInfoBar totalFolderItems={children.length} />
                  </Box>
                )
              }

              {
                // ---------------------
                //   For LARGE screens
                // ---------------------

                !isMobile && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 2,
                    }}
                    id="cxLas4a"
                  >
                    <DLXDriveRowsPerPage limit={limit} setLimit={handleRowsPerPageChange} />

                    {(id || breadcrumbs.length > 0) && (
                      <DLXDrivePagination
                        total={total}
                        limit={limit}
                        offset={offset}
                        id={id || breadcrumbs[0].id.toString()}
                      />
                    )}
                  </Box>
                )
              }

              {
                // ---------------------
                //   For SMALL screens
                // ---------------------

                isMobile && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'right',
                      alignItems: 'right',
                    }}
                    id="cxLas4a"
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                      {(id || breadcrumbs.length > 0) && (
                        <DLXDrivePagination
                          total={total}
                          limit={limit}
                          offset={offset}
                          id={id || breadcrumbs[0].id.toString()}
                        />
                      )}
                    </Box>

                    <DLXDriveRowsPerPage limit={limit} setLimit={handleRowsPerPageChange} />
                  </Box>
                )
              }
            </Box>
          </Box>
        </Stack>

        <Fade in={checkedItems.length > 0} timeout={800}>
          <Box>
            <DLXDriveBulkActionComponent />
          </Box>
        </Fade>
      </Container>

      <Drawer open={showDrawer && showFolderTree} onClose={() => setShowFolderTree(false)}>
        <FolderTree
          showDrawer={showDrawer || false}
          setShowFolderTree={setShowFolderTree}
          folderTreeExpandedItems={folderTreeExpandedItems}
          setFolderTreeExpandedItems={setFolderTreeExpandedItems}
        />
      </Drawer>
    </Page>
  );
};

export default DLXDrive;
