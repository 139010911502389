import React from 'react';

import { MenuItem, Typography } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { FOLDER_ACTIONS_MENU_ICON_SIZE } from '../../../utils/constants/dlxDriveConstants';

type DLXDriveFolderActionsMenuItemProps = {
  disabled: boolean;
  src: string;
  name: string;
  onClick: () => void;
  id: string;
};

const DLXDriveFolderActionsMenuItem: React.FC<DLXDriveFolderActionsMenuItemProps> = ({
  disabled,
  src,
  name,
  onClick,
  id,
}) => {
  const { t } = useLocales();

  return (
    <MenuItem
      disabled={disabled}
      onClick={onClick}
      id={id}
      sx={{
        py: 1,
        px: 2,
        borderRadius: 1,
        m: 1,
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <img
        src={src}
        width={FOLDER_ACTIONS_MENU_ICON_SIZE}
        height={FOLDER_ACTIONS_MENU_ICON_SIZE}
        alt={t(name)}
      />
      <Typography noWrap>{t(name)}</Typography>
    </MenuItem>
  );
};

export default DLXDriveFolderActionsMenuItem;
