import { FormElementError } from '../enums/FormElementError';
import { ErrorsListProps, ElementValidationProps } from '../types';

// ----------------------------------------------------------------------

const validateIsNumber = ({ el, errorMessage, value }: ElementValidationProps) => {
  let newError: ErrorsListProps | null = null;
  let isNumber: boolean;

  if (!value || value === '') {
    isNumber = true;
  } else {
    isNumber = /^-?\d+(\.\d+)?$/.test(value);
  }

  if (!isNumber) {
    newError = {
      elementID: el.id,
      elementName: el.label,
      errorCode: FormElementError.NotNumber,
      errorMessage,
    };

    return newError;
  }
};

export default validateIsNumber;
