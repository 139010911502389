import React from 'react';

import { Box, Typography } from '@mui/material';

// ----------------------------------------------------------------

type RenderFieldNameProps = {
  name: string;
  extension: string;
};

const RenderNameField: React.FC<RenderFieldNameProps> = ({ name, extension }) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      gap: 1,
    }}
  >
    <img
      width={30}
      height={30}
      src={`/icons/icon_${extension}.svg`}
      alt={`${extension}`.toUpperCase()}
    />

    <Typography>{name}</Typography>
  </Box>
);

export default RenderNameField;
