import React, { useRef, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import { Box, Divider, MenuItem, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';

import useAuth from '../../../hooks/useAuth';
import MyAvatar from '../../../components/MyAvatar';
import { PATH_PROFILE } from '../../../routes/paths';
import MenuPopover from '../../../components/MenuPopover';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { IconButtonAnimate } from '../../../components/animate';
import { clearCheckedItemsState } from '../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------------

type AccountPopoverProps = {
  id: string;
};

const AccountPopover: React.FC<AccountPopoverProps> = ({ id }) => {
  const { t } = useLocales();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueError } = useNotifications();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    clearCheckedItemsState();

    try {
      logout?.();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      enqueueError('layout.snackbar.unableToLogout');
    }
  };

  const handleOpenProfile = () => {
    const activeRoot = !!matchPath({ path: PATH_PROFILE.root, end: false }, pathname);
    if (activeRoot) {
      navigate(0);
    } else {
      navigate(PATH_PROFILE.root);
    }
    handleClose();
  };

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        id={id}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
        id="dashboardHeader08"
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <MenuItem
          onClick={handleOpenProfile}
          sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1, m: 1 }}
          id="dashboardHeader09"
        >
          {t('profile.editProfile')}
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={handleLogout}
          sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1, m: 1 }}
          id="dashboardHeader10"
        >
          {t('layout.logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
