import React from 'react';

import { Box } from '@mui/material';

import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------

type RenderDateTimeFieldProps = {
  date: string;
  format: 'date' | 'dateTime';
};

const RenderDateTimeField: React.FC<RenderDateTimeFieldProps> = ({ date, format }) => {
  const { currentLang } = useLocales();

  if (format === 'date') {
    const dateTime = new Date(date).toLocaleDateString(currentLang.value);

    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {dateTime}
      </Box>
    );
  }

  const dateTime = new Date(date).toLocaleString(currentLang.value);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      {dateTime}
    </Box>
  );
};

export default RenderDateTimeField;
