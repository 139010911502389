import pdf from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import fileDownload from 'js-file-download';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  Stack,
  Table,
  Button,
  Dialog,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  CardContent,
  DialogTitle,
  DialogContent,
  TableContainer,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { fDateTime } from 'src/utils/formatTime';

import { ValidationStatus, AdocValidationData } from 'src/@types/documents/validationReport';

import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';

import { useSelector } from '../../redux/store';

const { Page, Text, View, Font, Document, StyleSheet } = pdf;

// ----------------------------------------------------------------------

type DocumentAdocValidationReportModalProps = {
  data: AdocValidationData;
  handleClose: () => void;
};

type DataRowProps = {
  field: string;
  value?: string;
};

const styles = StyleSheet.create({
  page: {
    padding: 5,
    fontFamily: 'Ubuntu',
  },
  section: {
    margin: 5,
    padding: 5,
  },
  viewer: {
    width: '100%',
    height: window.innerHeight,
  },
  text: {
    fontSize: 14,
  },
  approverText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: 18,
    textAlign: 'center',
    margin: 10,
  },
});

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

export default function DocumentAdocValidationReportModal({
  data,
  handleClose,
}: DocumentAdocValidationReportModalProps) {
  const { t } = useLocales();
  const { document } = useSelector((state) => state.document);
  const theme = useTheme();

  // eslint-disable-next-line react/no-unstable-nested-components
  const DataRow = ({ field, value }: DataRowProps) => (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">{t(field)}</Typography>
      </TableCell>
      <TableCell sx={{ textAlign: 'end' }}>
        <Typography>{value}</Typography>
      </TableCell>
    </TableRow>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const Status = () => {
    switch (data.validationResult) {
      case ValidationStatus.Valid: {
        return (
          <Typography color={theme.palette.success.main} sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:checkmark-circle-2-outline" />{' '}
            {t('documents.validationReport.valid')}
          </Typography>
        );
      }
      case ValidationStatus.NoSignatures: {
        return (
          <Typography color="grey" sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:checkmark-circle-2-outline" />{' '}
            {t('documents.validationReport.noSignatures')}
          </Typography>
        );
      }
      case ValidationStatus.Invalid:
      default: {
        return (
          <Typography color={theme.palette.error.main} sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:close-circle-outline" />{' '}
            {t('documents.validationReport.invalid')}
          </Typography>
        );
      }
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const PdfRow = ({ field, value }: DataRowProps) => (
    <View>
      <Text>
        {t(field)}: {value}
      </Text>
    </View>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const PdfEmptyLine = () => (
    <View>
      <Text> </Text>
    </View>
  );

  // renames helps for react18 for types
  const DocumentRe: any = Document;
  const PageRe: any = Page;

  // eslint-disable-next-line react/no-unstable-nested-components
  const PdfContent = () => (
    <DocumentRe>
      <PageRe size="A4" style={styles.page}>
        <PdfRow field="documents.validationReport.documentName" value={data.name} />
        <PdfRow
          field="documents.validationReport.signaturesCount"
          value={data.adocSimpleReport?.signaturesCount.toString()}
        />
        <PdfRow
          field="documents.validationReport.validSignaturesCount"
          value={data.adocSimpleReport?.validSignaturesCount.toString()}
        />
        <PdfRow
          field="documents.validationReport.validationTime"
          value={fDateTime(data.adocSimpleReport?.validationTime || '')}
        />
        <PdfRow
          field="documents.validationReport.validationPolicy"
          value={data.adocSimpleReport?.validationPolicy?.policyName}
        />
        <PdfEmptyLine />
        {!!data.adocSimpleReport?.signatureOrTimestamp?.length && (
          <>
            <PdfRow field="documents.validationReport.signatures" value="" />
            <PdfEmptyLine />
            {data.adocSimpleReport?.signatureOrTimestamp?.map((item) => (
              <>
                <PdfRow
                  field="documents.validationReport.signedBy"
                  value={item.signature?.signedBy}
                />
                <PdfRow
                  field="documents.validationReport.signingTime"
                  value={fDateTime(item.signature?.signingTime || '')}
                />
                <PdfRow
                  field="documents.validationReport.signatureFormat"
                  value={item.signature?.signatureFormat}
                />
                <PdfRow
                  field="documents.validationReport.signatureLevel"
                  value={item.signature?.signatureLevel?.value}
                />
                <PdfRow
                  field="documents.validationReport.certificateExpirationDate"
                  value={item.signature?.certificateExpirationDate}
                />
                <PdfEmptyLine />
              </>
            ))}
          </>
        )}
      </PageRe>
    </DocumentRe>
  );

  const onDownload = async () => {
    const pdf = await ReactPDF.pdf(<PdfContent />).toBlob();
    fileDownload(pdf, `${(document?.name || '') + fDateTime(new Date())}.pdf`);
  };

  return (
    <Dialog fullWidth open onClose={handleClose}>
      <DialogTitle sx={{ mb: 2 }}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Typography
              variant="h5"
              sx={{
                mr: 2,
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
            >
              {t('documents.validationReport.validationReport')}
            </Typography>
          </Box>

          <Status />

          <IconButton size="small" onClick={handleClose} id="m8mf2ji1">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Scrollbar>
          <TableContainer sx={{ mb: 4 }}>
            <Table>
              <TableBody>
                <DataRow field="documents.validationReport.documentName" value={data.name} />
                <DataRow
                  field="documents.validationReport.signaturesCount"
                  value={data.adocSimpleReport?.signaturesCount.toString()}
                />
                <DataRow
                  field="documents.validationReport.validSignaturesCount"
                  value={data.adocSimpleReport?.validSignaturesCount.toString()}
                />
                <DataRow
                  field="documents.validationReport.validationTime"
                  value={fDateTime(data.adocSimpleReport?.validationTime || '')}
                />
                <DataRow
                  field="documents.validationReport.validationPolicy"
                  value={data.adocSimpleReport?.validationPolicy?.policyName}
                />

                {!!data.adocSimpleReport?.creationReportMetadata && (
                  <>
                    <DataRow
                      field="documents.validationReport.authors"
                      value={data.adocSimpleReport?.creationReportMetadata?.authors}
                    />
                    <DataRow
                      field="documents.validationReport.creationDocumentName"
                      value={data.adocSimpleReport?.creationReportMetadata?.documentName}
                    />
                    <DataRow
                      field="documents.validationReport.documentRegistration"
                      value={data.adocSimpleReport?.creationReportMetadata?.documentRegistration}
                    />
                  </>
                )}

                {!!data.adocSimpleReport?.unsignableReportMetadata && (
                  <>
                    <DataRow
                      field="documents.validationReport.adocType"
                      value={data.adocSimpleReport?.unsignableReportMetadata?.adocType}
                    />
                    <DataRow
                      field="documents.validationReport.documentFormat"
                      value={data.adocSimpleReport?.unsignableReportMetadata?.documentFormat}
                    />
                    <DataRow
                      field="documents.validationReport.documentCreationSoftware"
                      value={
                        data.adocSimpleReport?.unsignableReportMetadata?.documentCreationSoftware
                      }
                    />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={onDownload}
              type="button"
              variant="contained"
              color="secondary"
              sx={{ mb: 1, mr: 1 }}
              startIcon={<Iconify icon="eva:download-fill" width={28} height={28} />}
              id="rhutleok"
            >
              {t('documents.buttons.download')}
            </Button>
          </Box>

          {!!data.adocStructureErrors && (
            <TableContainer sx={{ mb: 4 }}>
              <Table>
                <TableBody>
                  <DataRow
                    field="documents.validationReport.adocStructureErrors"
                    value={data.adocStructureErrors}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!!data.adocSimpleReport?.signatureOrTimestamp?.length && (
            <>
              <Typography variant="h6">{t('documents.validationReport.signatures')}</Typography>
              {data.adocSimpleReport?.signatureOrTimestamp?.map((item, index) => (
                <Card key={index} sx={{ border: '1px solid #AAAAAA30', mt: 2 }}>
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    <TableContainer sx={{ minWidth: 240 }}>
                      <Table>
                        <TableBody>
                          <DataRow
                            field="documents.validationReport.signedBy"
                            value={item.signature?.signedBy}
                          />
                          <DataRow
                            field="documents.validationReport.signingTime"
                            value={fDateTime(item.signature?.signingTime || '')}
                          />
                          <DataRow
                            field="documents.validationReport.signatureFormat"
                            value={item.signature?.signatureFormat}
                          />
                          <DataRow
                            field="documents.validationReport.signatureLevel"
                            value={item.signature?.signatureLevel?.value}
                          />
                          <DataRow
                            field="documents.validationReport.signatureAuthor"
                            value={item.signatureAdocMetadata?.signatureAuthor}
                          />
                          <DataRow
                            field="documents.validationReport.signatureFileName"
                            value={item.signatureAdocMetadata?.signatureFileName}
                          />
                          <DataRow
                            field="documents.validationReport.signingReason"
                            value={item.signatureAdocMetadata?.signingReason}
                          />
                          <DataRow
                            field="documents.validationReport.certificateExpirationDate"
                            value={fDateTime(item.signature?.certificateExpirationDate || '')}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </Scrollbar>
      </DialogContent>
    </Dialog>
  );
}
