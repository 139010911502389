import React, { FC } from 'react';
import { Droppable, DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';

import { List } from '@mui/material';

import FormApproversListItem from './FormApproversListItem';
import { DraggableApproverProps } from '../../../@types/forms/types';

// ----------------------------------------------------------------------

export type FormApproversListProps = {
  isDocLogixIntegration: boolean;
  items: DraggableApproverProps[];
  handleItemChange: (item: DraggableApproverProps) => void;
  onDragEnd: OnDragEndResponder;
};

const FormApproversList: FC<FormApproversListProps> = React.memo(
  ({ isDocLogixIntegration, items, handleItemChange, onDragEnd }) => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map((item: DraggableApproverProps, index: number) => {
              const checkedItems = items.filter((item) => item.checked);
              const itemOrderNumber = checkedItems.findIndex(
                (checkedItem) => checkedItem.id === item.id
              );

              return (
                <FormApproversListItem
                  key={item.id}
                  item={item}
                  handleItemChange={handleItemChange}
                  index={index}
                  itemOrderNumber={itemOrderNumber}
                />
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
);

export default FormApproversList;
