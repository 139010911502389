import i18next from 'i18next';
import { createSlice } from '@reduxjs/toolkit';

import axios from 'src/utils/httpClients/axios';
import { getSession } from 'src/utils/auth/jwt';
import { dataUrlToFile } from 'src/utils/fileUtils';

import documentService from 'src/services/documentService';
import { DS_API, HOST_API, DS_API_AUTH_KEY } from 'src/config';
import { DocumentSignatureType } from 'src/@types/documents/enums/documentSignatureTypes';
import {
  DSFile,
  EParakstsDocument,
  ApproveInitRequest,
  AcknowledgeInitRequest,
  SmartIdInitSignatureRequest,
  MobileIdInitSignatureRequest,
  EParakstsInitSignatureRequest,
} from 'src/@types/documents/types';

// utils
import { dispatch } from '../store';
import eventBus, { EventTypes } from '../../utils/eventBus';
import { setControlCode, clearControlCode } from './controlCode';
import { handleMessages, increaseDocumentReloadState } from './document';
import { getFreeSignaturesCount, reduceFreeSignaturesCount } from './user';
import { getTenantAuthSettings } from '../../services/asp-identity/aspCompanyService';
import { addStopLoadingState, setDriveListIdState, addStartLoadingState } from './dlxDrive';

// ----------------------------------------------------------------------

type SignatureSessionState = {
  sessionId: string;
  redirectUrl: string;
  signedFile: any;
  error: string;
  isStatusStarted: boolean;
};

const initialState: SignatureSessionState = {
  sessionId: '',
  redirectUrl: '',
  signedFile: '',
  error: '',
  isStatusStarted: false,
};

const slice = createSlice({
  name: 'signatureSession',
  initialState,
  reducers: {
    clearState(state) {
      state.sessionId = initialState.sessionId;
      state.error = initialState.error;
      state.signedFile = initialState.signedFile;
      state.isStatusStarted = initialState.isStatusStarted;
    },

    setControlCodeSession(state, action) {
      state.sessionId = action.payload.sessionId;
    },

    setEparakstsSession(state, action) {
      state.redirectUrl = action.payload.redirectUrl;
    },

    setSignedFile(state, action) {
      state.signedFile = action.payload;
    },

    hasError(state, action) {
      state.error = action.payload;
    },

    setStatusStarted(state) {
      state.isStatusStarted = true;
    },

    setStatusFinished(state) {
      state.isStatusStarted = false;
    },
  },
});

export default slice.reducer;

export function setStatusStarted() {
  dispatch(slice.actions.setStatusStarted());
}

export function setStatusFinished() {
  dispatch(slice.actions.setStatusFinished());
}

export type GetSessionData = {
  documentId: number;
  signatureType: DocumentSignatureType;
  documentName?: string;
  documentExtension?: string;
  comment: string | null;
};

type BaseInit = {
  type: string;
  timestamp: boolean;
  timestampPosition: string;
  timestampImage: string;
  language: string;
  displayText: string;
  files: DSFile[];
};

type BaseSignatureInit = BaseInit & {
  digestAlgorithm: string;
  signDate: string;
};

type SmartIdSignatureInit = BaseSignatureInit & {
  countryCode: string;
  companyCode: string | null;
  companyAddress: string | null;
  companyName: string | null;
  signerPosition: string;
  signerSubdivision: string;
  signCoordinates: [number, number, number];
};

type EParakstSignatureInit = BaseSignatureInit & {
  companyCode: string | null;
  companyAddress: string | null;
  companyName: string | null;
  originUrl: string;
  signCoordinates: [number, number, number];
  documents: EParakstsDocument[];
};

type MobileIdSignatureInit = BaseSignatureInit & {
  phone: string;
  companyCode: string | null;
  companyAddress: string | null;
  companyName: string | null;
  signerPosition: string;
  signerSubdivision: string;
};

type ApproveInit = BaseInit & {
  approveDate: string;
};

let cancelSession = false;

export async function removeSession(sessionId: string) {
  const config = {
    headers: {
      api_key: DS_API_AUTH_KEY,
    },
  };

  cancelSession = true;
  await axios.delete(`${DS_API}/session/${sessionId}`, config);
}

export async function initSmartIdSignatureSession(data: SmartIdInitSignatureRequest) {
  if (!data.personalCode) {
    return;
  }

  const model = {
    type: data.fileExtension,
    timestamp: true,
    timestampPosition: data.timestampPosition,
    timestampImage: data.timestampImage,
    companyCode: data.companyCode,
    companyAddress: data.companyAddress,
    companyName: data.companyName,
    language: data.language,
    displayText: getSession().user?.timestampName,
    digestAlgorithm: 'SHA256',
    signDate: new Date().toISOString(),
    personalCode: data.personalCode,
    countryCode: data.countryCode,
    files: data.files,
    documentIds: data.documentIds,
    timestampPage: data.timestampPage,
    signerPosition: data.signerPosition,
    signerSubdivision: data.signerSubdivision,
    signCoordinates: data.signCoordinates,
    comment: data.comment,
  } as SmartIdSignatureInit;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      api_key: DS_API_AUTH_KEY,
    },
  };

  try {
    const response = await axios.post(`${DS_API}/smartId/sign`, model, config);

    dispatch(
      slice.actions.setControlCodeSession({ sessionId: response.data.data.session.sessionId })
    );
    setControlCode(response.data.data.controlCode);

    const getSessionData = {
      documentId: data.documentId,
      signatureType: DocumentSignatureType.SmartId,
      documentName: data.documentName,
      documentExtension: data.fileExtension,
      comment: data.comment,
    } as GetSessionData;

    await getSigningSession(response.data.data.session.sessionId, getSessionData);
  } catch (error: any) {
    throw error;
  }
}

export async function initEParakstsSignatureSession(data: EParakstsInitSignatureRequest) {
  setStatusStarted();
  addStartLoadingState();

  const model = {
    signType: data.signType,
    type: data.fileExtension,
    timestamp: true,
    timestampPosition: data.timestampPosition,
    timestampImage: data.timestampImage,
    timestampPage: data.timestampPage,
    language: data.language,
    displayText: getSession().user?.timestampName,
    digestAlgorithm: 'SHA256',
    signDate: new Date().toISOString(),
    companyName: data.companyName,
    companyCode: data.companyCode,
    companyAddress: data.companyAddress,
    originUrl: data.originUrl,
    files: data.files,
    documentIds: data.documentIds,
    signCoordinates: data.signCoordinates,
    comment: data.comment,
    documents: data.documents,
  } as EParakstSignatureInit;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      api_key: DS_API_AUTH_KEY,
    },
  };

  try {
    const response = await axios.post(`${DS_API}/Eparaskts-Mobile/InitSignSession`, model, config);

    const { redirectUrl } = response.data.data;

    if (redirectUrl && redirectUrl.length > 0) {
      dispatch(slice.actions.setEparakstsSession({ redirectUrl }));
      window.open(redirectUrl, '_parent', 'noreferrer');
    }
  } catch (error: any) {
    throw error;
  }
}

export async function initMobileIdSignatureSession(data: MobileIdInitSignatureRequest) {
  if (!data.personalCode) {
    return;
  }

  const model = {
    type: data.fileExtension,
    timestamp: true,
    timestampPosition: data.timestampPosition,
    timestampImage: data.timestampImage,
    companyCode: data.companyCode,
    companyAddress: data.companyAddress,
    companyName: data.companyName,
    language: data.language,
    displayText: getSession().user?.timestampName,
    digestAlgorithm: 'SHA256',
    signDate: new Date().toISOString(),
    personalCode: data.personalCode,
    phone: data.phone,
    files: data.files,
    documentIds: data.documentIds,
    timestampPage: data.timestampPage,
    signerPosition: data.signerPosition,
    signerSubdivision: data.signerSubdivision,
    signCoordinates: data.signCoordinates,
    comment: data.comment,
  } as MobileIdSignatureInit;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      api_key: DS_API_AUTH_KEY,
    },
  };

  try {
    const response = await axios.post(`${DS_API}/mobile/sign`, model, config);

    dispatch(
      slice.actions.setControlCodeSession({ sessionId: response.data.data.session.sessionId })
    );
    setControlCode(response.data.data.controlCode);

    const getSessionData = {
      documentId: data.documentId,
      signatureType: DocumentSignatureType.MobileId,
      documentName: data.documentName,
      documentExtension: data.fileExtension,
      comment: data.comment,
    } as GetSessionData;

    await getSigningSession(response.data.data.session.sessionId, getSessionData);
  } catch (error: any) {
    throw error;
  }
}

export async function approveDocument(data: ApproveInitRequest) {
  const model = {
    type: data.fileExtension,
    timestamp: true,
    timestampPosition: data.timestampPosition,
    timestampImage: data.timestampImage,
    language: data.language,
    displayText: getSession().user?.timestampName,
    approveDate: new Date().toISOString(),
    files: data.files,
    documentIds: data.documentIds,
    timestampPage: data.timestampPage,
    comment: data.comment,
  } as ApproveInit;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      api_key: DS_API_AUTH_KEY,
    },
  };

  try {
    const response = await axios.post(`${DS_API}/document/approve`, model, config);
    eventBus.publish({
      topic: EventTypes.ForbidSign,
      payload: {},
    });
    const fileContent = `data:application/pdf;base64,${response.data.data[0].content}`;
    const file = await dataUrlToFile(fileContent, response.data.data[0].name, 'application/pdf');
    dispatch(slice.actions.setSignedFile(file));
    await approveAcknowledge(
      data.documentId,
      response.data.data[0].content,
      DocumentSignatureType.Unqualified,
      data.comment,
      ''
    );
  } catch (error: any) {
    throw error;
  }
}

export async function acknowledgeDocument(data: AcknowledgeInitRequest) {
  try {
    // check this for right notification message
    eventBus.publish({
      topic: EventTypes.ForbidSign,
      payload: {},
    });

    const initSignRes = await documentService.getInitSign(
      data.documentId,
      DocumentSignatureType.Acknowledge
    );

    if (initSignRes !== null) {
      await approveAcknowledge(
        data.documentId,
        initSignRes.file,
        DocumentSignatureType.Acknowledge,
        data.comment,
        ''
      );
    }
  } catch (error: any) {
    throw error;
  }
}

export async function signByHand(model: any) {
  try {
    await axios.post(`${HOST_API}Documents/handwritten-sign`, model);
  } catch (error: any) {
    throw error;
  }
}

export async function getSigningSession(sessionId: string, data: GetSessionData) {
  if (!sessionId) {
    return;
  }

  cancelSession = false;

  await checkStatus(`${DS_API}/session/${sessionId}`, data);
}

async function checkStatus(url: string, data: GetSessionData, iteration: number = 1) {
  const maxDurationSeconds = 300;
  const iterationTimeoutSeconds = 3;

  if (iteration === maxDurationSeconds / iterationTimeoutSeconds || cancelSession) {
    return;
  }

  try {
    const config = {
      headers: {
        api_key: DS_API_AUTH_KEY,
      },
    };
    const response = await axios.get(url, config);

    switch (response.data.data.sessionStatus) {
      case 'Started':
        setTimeout(async () => {
          await checkStatus(url, data, ++iteration);
        }, iterationTimeoutSeconds * 1000);

        setStatusStarted();
        break;
      case 'Finished': {
        eventBus.publish({
          topic: EventTypes.ForbidSign,
          payload: {},
        });
        clearControlCode();

        if (cancelSession) {
          break;
        }

        const sessionId = url.split('/').pop()?.split('?')[0];
        const requestCode = url.split('/').pop()?.split('?')[1]?.replace('requestCode=', '');

        if (sessionId && requestCode) {
          await signDocumentsEparaksts(sessionId, requestCode);
        } else {
          await signDocuments(data.signatureType, sessionId || '', data.comment);
        }

        const tenant = await getTenantAuthSettings();

        if (!tenant.isCompany) {
          await reduceFreeSignaturesCount();
          await getFreeSignaturesCount();
        }

        setStatusFinished();
        break;
      }
      case 'Unknown':
      case 'Failed':
      default:
        clearControlCode();

        setStatusFinished();
        break;
    }
  } catch (error: any) {
    dispatch(slice.actions.hasError(error));
    clearControlCode();
  }
}

export async function approveAcknowledge(
  id: number,
  fileContent: string,
  signatureType: DocumentSignatureType,
  comment: string | null,
  sessionId: string
) {
  try {
    const request = {
      file: fileContent,
      signatureType,
      comment,
      sessionId,
    };

    const config = {
      headers: {
        api_key: DS_API_AUTH_KEY,
      },
    };

    await axios.put(`${HOST_API}documents/${id}/approve`, request, config);
    eventBus.publish({
      topic: EventTypes.DocumentSigned,
      payload: {},
    });
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  } finally {
    increaseDocumentReloadState();
  }
}

const signDocumentsEparaksts = async (sessionId: string, requestCode: string) => {
  const baseUrl = `${DS_API}/Eparaskts-Mobile`;
  const eparakstsPlugin = new window.eParakstsTokenSigning();
  const i18nextLng = localStorage.getItem('i18nextLng');

  const lang = () => {
    switch (i18nextLng) {
      case 'lv':
        return 'lv';
      case 'ru':
        return 'ru';
      default:
        return 'en';
    }
  };

  try {
    setStatusStarted();

    setStatusStarted();
    addStartLoadingState();

    const signCertificate = await eparakstsPlugin.getCertificate({
      lang: lang(),
      operation: 'sign',
    });

    const authCertificate = await eparakstsPlugin.getCertificate({
      lang: lang(),
      operation: 'auth',
    });

    setStatusStarted();
    addStartLoadingState();

    const hash = await axios.post(`${baseUrl}/CalculateDigestsForCardSign`, {
      sessionId,
      requestCode,
      signCertificate,
      authCertificate,
    });
    const { data } = hash;

    setStatusStarted();
    addStartLoadingState();

    const signHex = await eparakstsPlugin.sign(signCertificate, data.data, {
      lang: lang(),
      operation: 'sign',
    });

    const { hex } = signHex;

    setStatusStarted();
    addStartLoadingState();

    const finalizeCardSignResponse = await axios.post(`${baseUrl}/FinalizeCardSign`, {
      sessionId,
      hex,
    });

    const { totalCount, successfullySigned, errors } = finalizeCardSignResponse.data.data;

    if (errors.length > 0) {
      for (let error of errors) {
        eventBus.publish({
          topic: EventTypes.ForbidSign,
          payload: `${error.fileName} - ${error.message}`,
        });
      }
    }

    if (successfullySigned > 0) {
      await signDocuments(
        data.signatureType,
        sessionId || '',
        data.comment,
        i18next.t('documents.snackbar.successfullySignedOfDocuments', {
          successfullySigned,
          totalCount,
        })
      );
    } else {
      addStopLoadingState();
    }
  } catch (error) {
    addStopLoadingState();
    console.error(error);
  }
};

export const signDocuments = async (
  signatureType: DocumentSignatureType,
  sessionId: string,
  comment: string | null,
  message?: string
) => {
  try {
    const request = {
      signatureType,
      sessionId,
      comment,
      message,
    };

    const config = {
      headers: {
        api_key: DS_API_AUTH_KEY,
      },
    };

    await axios.put(`${HOST_API}documents/sign`, request, config);
    eventBus.publish({
      topic: EventTypes.DocumentSigned,
      payload: {},
    });
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  } finally {
    setDriveListIdState();
    addStopLoadingState();

    if (signatureType === DocumentSignatureType.EParaksts) {
      handleMessages('documents.snackbar.signedSuccessfully');
    } else {
      increaseDocumentReloadState();
    }
  }
};
