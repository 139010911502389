import React from 'react';

import useLocales from '../../hooks/useLocales';
import { getStatusString } from '../documentUtils';
import { DocumentStatuses } from '../../@types/documents/enums/documentStatuses';

// ----------------------------------------------------------------

type GetStatusNameProps = {
  status: DocumentStatuses;
};

const GetStatusName: React.FC<GetStatusNameProps> = ({ status }) => {
  const { t } = useLocales();
  const statusString = getStatusString(status);

  return <div>{t(statusString)}</div>;
};

export default GetStatusName;
