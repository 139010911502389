import React, { useRef, useState } from 'react';

// @mui
import { Stack, MenuItem, Typography, ListItemText } from '@mui/material';

// hooks
import useLocales from '../../../hooks/useLocales';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

type LanguagePopoverProps = {
  id: string;
};

const LanguagePopover: React.FC<LanguagePopoverProps> = ({ id }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && { bgcolor: 'action.selected' }),
        }}
        id={id}
      >
        <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
          {currentLang.value}
        </Typography>
      </IconButtonAnimate>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Stack spacing={0.5} sx={{ p: 1 }}>
          {allLang.map((option, index) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                setOpen(false);
              }}
              sx={{ height: 40, borderRadius: 1, px: 1 }}
              id={`dashboardHeader03_${index}`}
            >
              <Typography variant="subtitle1" sx={{ textTransform: 'uppercase', mr: 2 }}>
                {option.value}
              </Typography>

              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
};

export default LanguagePopover;
