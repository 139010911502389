// i18n
// scroll bar
import 'simplebar/src/simplebar.css';
// map
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactDOM from 'react-dom/client';
// lightbox
import 'react-image-lightbox/style.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';
// editor
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { PersistGate } from 'redux-persist/lib/integration/react';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// @mui
import './locales/i18n';
// highlight
import './utils/highlight';
// redux
import { store, persistor } from './redux/store';
// components
import LoadingScreen from './components/LoadingScreen';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { MenuDrawerProvider } from './contexts/MenuDrawerContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version
// import { AuthProvider } from './contexts/JWTContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';
// import { AuthProvider } from './contexts/Auth0Context';
// import { AuthProvider } from './contexts/FirebaseContext';
//
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <SettingsProvider>
          <MenuDrawerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </MenuDrawerProvider>
        </SettingsProvider>
      </PersistGate>
    </ReduxProvider>
  </HelmetProvider>
);
