import { Trans } from 'react-i18next';
import { ColorResult } from 'react-color';
import React, { useState, useEffect, MouseEvent, ChangeEvent, SyntheticEvent } from 'react';

import Tab from '@mui/material/Tab';
import { styled } from '@mui/system';
import Slider from '@mui/material/Slider';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import PaletteIcon from '@mui/icons-material/Palette';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  Grid,
  Link,
  Tabs,
  Alert,
  Badge,
  Input,
  Radio,
  Button,
  Dialog,
  Select,
  Switch,
  Divider,
  Tooltip,
  MenuItem,
  FormGroup,
  FormLabel,
  TextField,
  IconButton,
  InputLabel,
  RadioGroup,
  Typography,
  DialogTitle,
  FormControl,
  Autocomplete,
  ToggleButton,
  DialogActions,
  DialogContent,
  FormControlLabel,
  DialogContentText,
  ToggleButtonGroup,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import ColorPicker from 'src/components/color-picker/ColorPicker';

import { useSelector } from '../../../redux/store';
import { setIsLoading } from '../../../redux/slices/calendar';
import { getCustomLists } from '../../../services/formService';
import { CalendarEventType } from '../../../@types/calendar/types';
import { getEventTypesList } from '../../../services/calendarService';
import { UserDataType } from '../../../@types/forms/enums/UserDataType';
import { FormElementType } from '../../../@types/forms/enums/FormElementType';
import { EL_FONT_FAMILY } from '../../../@types/forms/constants/ElementSettings';
import { ElementProps, ImageDimensionsProps } from '../../../@types/forms/types';
import { FormElementValidationType } from '../../../@types/forms/enums/FormElementValidationType';
import {
  TextStyle,
  FontFamily,
  TextAlignment,
  TextDecoration,
} from '../../../@types/forms/enums/ElementProperties';
import {
  ELEMENT_IS_EMAIL,
  ELEMENT_IS_NUMBER,
  ELEMENT_NO_VALIDATION,
  ElementValidationsProps,
  ELEMENT_NUMBER_OF_CHARACTERS,
} from '../../../@types/forms/constants/ElementValidations';

//-----------------------------------------------------------------

type ElementSettingsDialogProps = {
  open: boolean;
  element: ElementProps | null;
  elements: ElementProps[];
  setElements: (elements: ElementProps[]) => void;
  handleClose: (isDialogOpen: boolean) => void;
  handleDelete: () => void;
  handleChange: (changedElement: ElementProps) => void;
};

type ElementTextStyleProps = {
  bold: boolean;
  italic: boolean;
};

type ElementTextDecorationProps = {
  underline: boolean;
};

export type HyperlinkProps = {
  text: string;
  url: string;
};

export type CountryProps = {
  code: string;
  label: string;
};

export type FormGroupOptionProps = {
  label: string;
  isChecked: boolean;
};

export type CustomListProps = {
  id: number;
  name: string;
  options?: string[];
};

export type ChildElement = {
  id: string;
  name: string;
};

enum AddressBookType {
  All = 'all',
  External = 'external',
  Internal = 'inernal',
}

const ElementSettingsDialog: React.FC<ElementSettingsDialogProps> = ({
  open,
  element,
  elements,
  setElements,
  handleClose,
  handleDelete,
  handleChange,
}) => {
  const { t, currentLang } = useLocales();
  const { eventTypesList } = useSelector((state) => state.calendar);
  const [selectedElement, setSelectedElement] = useState<ElementProps | null>(null);
  const [elementTextStyle, setElementTextStyle] = useState<ElementTextStyleProps | null>(null);
  const [elementTextDecoration, setElementTextDecoration] =
    useState<ElementTextDecorationProps | null>(null);
  const [options, setOptions] = useState<string[]>([]);
  const [formGroupOptions, setFormGroupOptions] = useState<FormGroupOptionProps[]>([]);
  const [uploadedFile, setUploadedFile] = useState<string | ArrayBuffer | null>(null);
  const [customLists, setCustomLists] = useState<CustomListProps[]>([]);
  const [selectedList, setSelectedList] = useState<CustomListProps | null>(null);
  const [isOpenTextRotation, setIsOpenTextRotation] = useState(false);
  const [isOpenColor, setIsOpenColor] = useState(false);
  const [textRotation, setTextRotation] = useState<number | string | Array<number | string> | null>(
    null
  );
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [staticText, setStaticText] = useState<string | undefined>(undefined);
  const [daysOfCountry, setDaysOfCountry] = useState<CountryProps>({
    code: 'LT',
    label: t('shared.countries.lt'),
  });
  const [dateRangeTemplate, setDateRangeTemplate] = useState<string | undefined>(undefined);
  const [openedTabIndex, setOpenedTabIndex] = useState(0);
  const [isElementRequired, setIsElementRequired] = useState(false);
  const [validation, setValidation] = useState<ElementValidationsProps>(ELEMENT_NO_VALIDATION);
  const [hyperlink, setHyperlink] = useState<HyperlinkProps>({ text: '', url: '' });
  const [userInfo, setUserInfo] = useState<number | null | undefined>(null);
  const [prefix, setPrefix] = useState<string | undefined>(undefined);
  const [numberOfCharacters, setNumberOfCharacters] = useState<number[]>([]);
  const [selectedChildElement, setSelectedChildElement] = useState<ChildElement | null>(null);
  const [childElements, setChildElements] = useState<ChildElement[]>([]);
  const [sameTypeElementsList, setSameTypeElementsList] = useState<ChildElement[]>([]);
  const [calendarEventType, setCalendarEventType] = useState<CalendarEventType | null>(null);
  const [isPastDisabled, setIsPastDisabled] = useState(false);
  const [isForExternal, setForExternal] = useState(false);
  const [isForInternal, setForInternal] = useState(false);
  const [isForAll, setForAll] = useState(true);
  const [addressBookType, setAddressBookType] = useState(AddressBookType.All);

  const handleAddreessBookType = (newOption: AddressBookType) => {
    setAddressBookType(newOption);

    setForAll(newOption === AddressBookType.All);
    setForExternal(newOption === AddressBookType.External);
    setForInternal(newOption === AddressBookType.Internal);
  };

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom-end" />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
    },
  });

  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  const countries = [
    {
      code: 'LT',
      label: t('shared.countries.lt'),
    },
    {
      code: 'LV',
      label: t('shared.countries.lv'),
    },
    {
      code: 'EE',
      label: t('shared.countries.ee'),
    },
  ];

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setTextRotation(newValue);
  };

  const handleTextRotationInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextRotation(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleTextColorChange = (color: ColorResult | string) => {
    if (typeof color === 'string') {
      setSelectedColor(color);
    } else {
      setSelectedColor(color.hex);
    }
  };

  const handleTextRotationBlur = () => {
    if (textRotation && typeof textRotation === 'number' && textRotation < 0) {
      setTextRotation(0);
    } else if (textRotation && typeof textRotation === 'number' && textRotation > 360) {
      setTextRotation(360);
    }
  };

  const handleDoclogixFieldIdChange = (doclogixCoreAttributeShortName: string) => {
    if (selectedElement !== null) {
      setSelectedElement({ ...selectedElement, doclogixCoreAttributeShortName });
    }
  };

  const handleLabelChange = (label: string) => {
    if (selectedElement !== null) {
      setSelectedElement({ ...selectedElement, label });
    }
  };

  const handleFontChange = (font: FontFamily) => {
    if (selectedElement !== null) {
      setSelectedElement({ ...selectedElement, font });
    }
  };

  const handleFontSizeChange = (fontSize: number) => {
    if (selectedElement !== null) {
      setSelectedElement({ ...selectedElement, fontSize });
    }
  };

  const handleAlignmentChange = (textAlignment: TextAlignment) => {
    if (selectedElement !== null && textAlignment !== null) {
      setSelectedElement({ ...selectedElement, textAlignment });
    }
  };

  const handleTextStyleChange = (textStyle: TextStyle) => {
    if (textStyle === TextStyle.Bold && elementTextStyle) {
      setElementTextStyle({ ...elementTextStyle, bold: !elementTextStyle.bold });
    } else if (textStyle === TextStyle.Bold) {
      setElementTextStyle({ bold: true, italic: false });
    } else if (textStyle === TextStyle.Italic && elementTextStyle) {
      setElementTextStyle({ ...elementTextStyle, italic: !elementTextStyle.italic });
    } else if (textStyle === TextStyle.Italic) {
      setElementTextStyle({ bold: false, italic: true });
    }
  };

  const handleTextDecorationChange = () => {
    if (elementTextDecoration && elementTextDecoration.underline) {
      setElementTextDecoration({ underline: false });
    }

    if (elementTextDecoration && !elementTextDecoration.underline) {
      setElementTextDecoration({ underline: true });
    }
  };

  const handleAddOption = () => {
    setOptions([...options, `${t('forms.writeOptionTitle')}`]);
  };

  const handleDeleteOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);

    setOptions([...newOptions]);
  };

  const handleOptionFocus = (index: number, defaultValue: string, defaultOption: string) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      if (newOptions[index] === defaultValue || newOptions[index] === defaultOption) {
        newOptions[index] = '';
      }
      return newOptions;
    });
  };

  const handleOptionBlur = (index: number, defaultValue: string) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      if (newOptions[index].trim() === '') {
        newOptions[index] = defaultValue;
      }
      return newOptions;
    });
  };

  const handleOptionChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newOptions = [...options];
    newOptions.splice(index, 1, event.target.value);

    setOptions([...newOptions]);
  };

  const handleBlur = (index: number, defaultLabel: string) => {
    setFormGroupOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      if (newOptions[index].label.trim() === '') {
        newOptions[index].label = defaultLabel;
      }
      return newOptions;
    });
  };

  const handleAddFormGroupOption = () => {
    setFormGroupOptions([
      ...formGroupOptions,
      { label: `${t('forms.writeOptionTitle')}`, isChecked: false },
    ]);
  };

  const handleDeleteFormGroupOption = (index: number) => {
    const newOptions = [...formGroupOptions];
    newOptions.splice(index, 1);

    setFormGroupOptions([...newOptions]);
  };

  const handleFocus = (index: number, defaultValue: string) => {
    setFormGroupOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      if (newOptions[index].label === defaultValue) {
        newOptions[index].label = '';
      }
      return newOptions;
    });
  };

  const handleFormGroupOptionChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newOptions = [...formGroupOptions];
    newOptions.splice(index, 1, { label: event.target.value, isChecked: false });

    setFormGroupOptions([...newOptions]);
  };

  const handleUserInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setUserInfo(parseInt(value, 10));
  };

  const handleUniqueIdentifierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setPrefix(value);
  };

  const handleNumberOfCharactersChange = (event: Event, newValue: number | number[]) => {
    setNumberOfCharacters(newValue as number[]);
  };

  const getTextStyle = () => {
    if (elementTextStyle) {
      const { bold, italic } = elementTextStyle;
      let textStyle = TextStyle.Regular;

      if (bold && !italic) {
        textStyle = TextStyle.Bold;
      }

      if (!bold && italic) {
        textStyle = TextStyle.Italic;
      }

      if (bold && italic) {
        textStyle = TextStyle.BoldItalic;
      }

      return textStyle;
    }

    return TextStyle.Regular;
  };

  const getTextDecoration = () => {
    if (elementTextDecoration) {
      const { underline } = elementTextDecoration;

      if (underline) {
        return TextDecoration.Underline;
      }
    }

    return TextDecoration.None;
  };

  const getImageDimensions = async (file: any) => {
    let img = new Image();
    img.src = file;

    await img.decode();
    const { width, height } = img;
    const imageDimensions: ImageDimensionsProps = { width, height };

    if (selectedElement !== null && selectedElement !== undefined) {
      setSelectedElement({ ...selectedElement, file: uploadedFile, imageDimensions });
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files?.length) {
      getBase64FromFile(files[0]);
    }
  };

  const handleStaticTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    setStaticText(newValue);
  };

  const markElementAsChild = (childElement: ChildElement, isChild: boolean) => {
    const newElements = [...elements];

    newElements.forEach((el) => {
      if (el.id === childElement.id) {
        el.isChild = isChild;
      }
    });

    setElements(newElements);
  };

  const handleAddChildElement = () => {
    const newChildElements = [...childElements];

    if (selectedChildElement) {
      newChildElements.push(selectedChildElement);

      markElementAsChild(selectedChildElement, true);
    }

    setChildElements(newChildElements);

    setSelectedChildElement(null);
  };

  const handleDeleteChildElement = (i: number) => {
    const newChildElements = [...childElements];

    markElementAsChild(newChildElements[i], false);

    newChildElements.splice(i, 1);
    setChildElements(newChildElements);
  };

  const handleChildInputChange = (newInputValue: ChildElement | null) => {
    setSelectedChildElement(newInputValue);
  };

  const getBase64FromFile = (file: File) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setUploadedFile(reader.result);
    };
  };

  const fetchCustomLists = async () => {
    const lists = await getCustomLists();

    setCustomLists(lists);
  };

  const clearStateOnClose = () => {
    setSelectedElement(null);
    setOptions([]);
    setFormGroupOptions([]);
    setUploadedFile(null);
    setSelectedList(null);
    setElementTextStyle(null);
    setElementTextDecoration(null);
    setTextRotation(null);
    setIsOpenTextRotation(false);
    setSelectedColor(null);
    setIsOpenColor(false);
    setStaticText(undefined);
    setDaysOfCountry({
      code: 'LT',
      label: t('shared.countries.lt'),
    });
    setDateRangeTemplate(undefined);
    setCalendarEventType(null);
    setHyperlink({ text: '', url: '' });
    setUserInfo(null);
    setNumberOfCharacters([]);
    setSelectedChildElement(null);
    setChildElements([]);
    setSameTypeElementsList([]);
    setPrefix('');
    setIsPastDisabled(false);
  };

  useEffect(() => {
    setSelectedElement(element);
  }, [element]);

  useEffect(() => {
    // CALLBACK FUNCTION
    if (selectedElement !== null) {
      handleChange(selectedElement);
    }

    // REQUIRED
    if (selectedElement) {
      setIsElementRequired(selectedElement.required);
    }

    // EXTERNAL, INTERNAL, ALL - ADDRESS BOOK
    if (
      selectedElement &&
      selectedElement.type === FormElementType.AddressBook &&
      selectedElement.isForExternal !== undefined &&
      selectedElement.isForInternal !== undefined &&
      selectedElement.isForAll !== undefined
    ) {
      setForExternal(selectedElement.isForExternal);
      setForInternal(selectedElement.isForInternal);
      setForAll(selectedElement.isForAll);

      const prevValueAddressBook = selectedElement.isForInternal
        ? AddressBookType.Internal
        : selectedElement.isForExternal
          ? AddressBookType.External
          : AddressBookType.All;

      setAddressBookType(prevValueAddressBook);
    }

    // VALIDATION
    if (selectedElement) {
      setValidation(selectedElement.validations);
    }

    // OPTIONS
    const elOptions = selectedElement?.options;

    if (elOptions && elOptions !== options) {
      setOptions(elOptions);
    }

    // FORM GROUP OPTIONS
    const elFormGroupOptions = selectedElement?.formGroupOptions;

    if (elFormGroupOptions && elFormGroupOptions !== formGroupOptions) {
      setFormGroupOptions(elFormGroupOptions);
    }

    // CUSTOM LIST
    if (selectedElement?.type === FormElementType.CustomList) {
      fetchCustomLists();

      if (selectedElement && selectedElement.list !== undefined) {
        setSelectedList(selectedElement.list);
      }
    }

    // IMAGE
    if (selectedElement?.type === FormElementType.Image) {
      if (selectedElement && selectedElement.file !== undefined) {
        setUploadedFile(selectedElement.file);
      }
    }

    // TEXT STYLE
    if (selectedElement) {
      if (selectedElement.textStyle === 'Bold' && !elementTextStyle) {
        setElementTextStyle({ bold: true, italic: false });
      } else if (selectedElement.textStyle === 'Italic' && !elementTextStyle) {
        setElementTextStyle({ bold: false, italic: true });
      } else if (selectedElement.textStyle === 'BoldItalic' && !elementTextStyle) {
        setElementTextStyle({ bold: true, italic: true });
      } else if (selectedElement.textStyle === 'Regular' && !elementTextStyle) {
        setElementTextStyle({ bold: false, italic: false });
      }
    }

    // TEXT DECORATION
    if (selectedElement) {
      if (selectedElement.textDecoration === 1 && !elementTextDecoration) {
        setElementTextDecoration({ underline: true });
      } else if (selectedElement.textDecoration === 0 && !elementTextDecoration) {
        setElementTextDecoration({ underline: false });
      }
    }

    // ROTATION
    if (selectedElement && !textRotation) {
      setTextRotation(selectedElement.rotate);
    }

    // COLOR
    if (selectedElement && !selectedColor) {
      setSelectedColor(selectedElement.color);
    }

    // STATIC TEXT
    if (selectedElement && !staticText) {
      setStaticText(selectedElement.staticText);
    }

    // DAYS OF COUNTRY
    if (selectedElement && selectedElement.daysOfCountry) {
      setDaysOfCountry(selectedElement.daysOfCountry);
    }

    // DATE RANGE TEMPLATE
    if (selectedElement && !dateRangeTemplate) {
      setDateRangeTemplate(selectedElement.dateRangeTemplate);
    }

    // DATE PAST DISABLED
    if (selectedElement && selectedElement.disablePast) {
      setIsPastDisabled(selectedElement.disablePast);
    }

    // CALENDAR EVENT TYPE
    if (selectedElement && selectedElement.calendarEventType === null) {
      setCalendarEventType(null);
    }

    if (selectedElement && selectedElement.calendarEventType) {
      const eventType = eventTypesList.find((et) => et.id === selectedElement.calendarEventType);

      if (eventType !== undefined) {
        setCalendarEventType(eventType);
      } else {
        setCalendarEventType(null);
      }
    }

    // HYPERLINK
    if (selectedElement && selectedElement.hyperlink) {
      if (
        selectedElement.hyperlink.text !== hyperlink.text ||
        selectedElement.hyperlink.url !== hyperlink.url
      ) {
        const newHyperlink: HyperlinkProps = {
          text: selectedElement.hyperlink.text,
          url: selectedElement.hyperlink.url,
        };

        setHyperlink(newHyperlink);
      }
    }

    // User Data
    if (selectedElement) {
      setUserInfo(selectedElement.userDataOption);
    }

    // Number of characters
    if (selectedElement && selectedElement.numberOfCharacters && numberOfCharacters.length === 0) {
      setNumberOfCharacters(selectedElement.numberOfCharacters);
    }

    // Child elements
    if (selectedElement && elements) {
      const sameTypeElementsArr: ChildElement[] = [];

      elements.forEach((el) => {
        if (
          selectedElement.type === el.type &&
          el.id !== selectedElement.id &&
          !el.isChild &&
          !el.isParent
        ) {
          sameTypeElementsArr.push({ id: el.id, name: el.label });
        }
      });

      setSameTypeElementsList(sameTypeElementsArr);
    }

    if (selectedElement && selectedElement.childElements) {
      setChildElements(selectedElement.childElements);
    }

    if (selectedElement && selectedElement.prefix) {
      setPrefix(selectedElement.prefix);
    }
  }, [selectedElement]);

  useEffect(() => {
    if (selectedElement !== null) {
      setSelectedElement({ ...selectedElement, textStyle: getTextStyle() });
    }
  }, [elementTextStyle]);

  useEffect(() => {
    if (selectedElement !== null) {
      setSelectedElement({ ...selectedElement, textDecoration: getTextDecoration() });
    }
  }, [elementTextDecoration]);

  useEffect(() => {
    if (
      selectedElement !== null &&
      typeof textRotation === 'number' &&
      selectedElement.rotate !== textRotation
    ) {
      setSelectedElement({ ...selectedElement, rotate: textRotation });
    }
  }, [textRotation]);

  useEffect(() => {
    if (selectedElement !== null) {
      setSelectedElement({ ...selectedElement, options });
    }
  }, [options]);

  useEffect(() => {
    if (selectedElement !== null) {
      setSelectedElement({ ...selectedElement, formGroupOptions });
    }
  }, [formGroupOptions]);

  useEffect(() => {
    if (selectedElement !== null && selectedElement !== undefined) {
      if (selectedElement.type === FormElementType.Image) {
        getImageDimensions(uploadedFile);
      }

      setSelectedElement({ ...selectedElement, file: uploadedFile });
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (selectedElement !== null && selectedElement !== undefined && selectedList !== null) {
      setSelectedElement({ ...selectedElement, list: selectedList });
    }
  }, [selectedList]);

  useEffect(() => {
    if (selectedElement && selectedColor) {
      setSelectedElement({ ...selectedElement, color: selectedColor });
    }
  }, [selectedColor]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, staticText });
    }
  }, [staticText]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, daysOfCountry });
    }
  }, [daysOfCountry]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, dateRangeTemplate });
    }
  }, [dateRangeTemplate]);

  useEffect(() => {
    if (selectedElement && calendarEventType) {
      const { id } = calendarEventType;
      setSelectedElement({ ...selectedElement, calendarEventType: id });
    }

    if (selectedElement && !calendarEventType) {
      setSelectedElement({ ...selectedElement, calendarEventType: null });
    }
  }, [calendarEventType]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, required: isElementRequired });
    }
  }, [isElementRequired]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, isForExternal });
    }
  }, [isForExternal]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, isForInternal });
    }
  }, [isForInternal]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, isForAll });
    }
  }, [isForAll]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, disablePast: isPastDisabled });
    }
  }, [isPastDisabled]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, validations: validation });
    }
  }, [validation]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, hyperlink });
    }
  }, [hyperlink]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, userDataOption: userInfo });
    }
  }, [userInfo]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, numberOfCharacters });
    }
  }, [numberOfCharacters]);

  useEffect(() => {
    if (selectedElement && childElements.length > 0) {
      setSelectedElement({ ...selectedElement, childElements, isParent: true });
    }

    if (selectedElement && childElements.length === 0) {
      setSelectedElement({ ...selectedElement, childElements, isParent: false });
    }
  }, [childElements]);

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement({ ...selectedElement, prefix });
    }
  }, [prefix]);

  useEffect(() => {
    if (eventTypesList.length === 0) {
      try {
        getEventTypesList({ offset: 0, limit: 9999, searchQuery: '' });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  return (
    <>
      {element !== null && (
        <Dialog open={open} fullWidth maxWidth="xs" scroll="paper">
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}
          >
            {`${t('forms.editElement')}: ${selectedElement ? selectedElement.label : ''}`}

            <IconButton
              size="small"
              onClick={() => {
                clearStateOnClose();
                handleClose(false);
              }}
              id="kt7iavzx"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText tabIndex={-1}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                <TextField
                  fullWidth
                  label={t('forms.elements.settings.integrationId')}
                  variant="outlined"
                  size="small"
                  value={selectedElement?.doclogixCoreAttributeShortName}
                  onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    handleDoclogixFieldIdChange(event.target.value);
                  }}
                  id="17ta291m"
                  InputLabelProps={{
                    shrink: Boolean(selectedElement?.doclogixCoreAttributeShortName),
                  }}
                />

                <TextField
                  fullWidth
                  label={t('forms.elements.settings.title')}
                  variant="outlined"
                  size="small"
                  value={selectedElement?.label || ''}
                  onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    handleLabelChange(event.target.value);
                  }}
                  id="km7jl08b"
                  InputLabelProps={{
                    shrink: Boolean(selectedElement?.label),
                  }}
                />

                {eventTypesList.length > 0 &&
                  selectedElement?.type === FormElementType.DateRange && (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Autocomplete
                        id="km9ol08b"
                        value={calendarEventType}
                        options={eventTypesList}
                        getOptionLabel={(option) => {
                          const itemName = JSON.parse(option.name);

                          return itemName[currentLang.value];
                        }}
                        onChange={(event, value) => setCalendarEventType(value)}
                        renderInput={(params) => (
                          <TextField {...params} label={t('calendar.events.typesCalendarEvents')} />
                        )}
                        size="small"
                        sx={{ flex: 1 }}
                      />

                      <CustomTooltip
                        title={
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              p: 1,
                            }}
                          >
                            {/* ---- Read ----*/}
                            <Typography variant="body1">
                              {t('calendar.events.eventTypesListInfoText')}
                            </Typography>
                          </Box>
                        }
                        arrow
                      >
                        <HelpIcon />
                      </CustomTooltip>
                    </Box>
                  )}

                {selectedElement?.type !== FormElementType.Image &&
                  selectedElement?.type !== FormElementType.Signature && (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                        <Autocomplete
                          value={selectedElement?.font || EL_FONT_FAMILY[0]}
                          onChange={(event: SyntheticEvent<Element, Event>, value: FontFamily) => {
                            if (value !== null) {
                              handleFontChange(value);
                            }
                          }}
                          sx={{ flexGrow: 1 }}
                          disableClearable
                          fullWidth
                          size="small"
                          options={EL_FONT_FAMILY}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('forms.elements.settings.font')}
                              id="zyj3mjxp"
                            />
                          )}
                          popupIcon={<ExpandMoreRoundedIcon />}
                          id="qk3RYn"
                        />

                        <Autocomplete
                          value={selectedElement?.fontSize || 11}
                          onChange={(event: SyntheticEvent<Element, Event>, value: number) => {
                            if (value !== null) {
                              handleFontSizeChange(value);
                            }
                          }}
                          sx={{ width: '100px' }}
                          disableClearable
                          size="small"
                          options={[8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72]}
                          getOptionLabel={(option) => option.toString()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('forms.elements.settings.fontSize')}
                              id="ixnu827a"
                            />
                          )}
                          popupIcon={<ExpandMoreRoundedIcon />}
                          id="G6qpLX"
                        />
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ToggleButtonGroup
                          value={selectedElement?.textAlignment}
                          exclusive
                          size="small"
                          sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                          onChange={(event: MouseEvent<HTMLElement>, value: TextAlignment) =>
                            handleAlignmentChange(value)
                          }
                        >
                          <ToggleButton value={TextAlignment.Left}>
                            <FormatAlignLeftIcon />
                          </ToggleButton>

                          <ToggleButton value={TextAlignment.Center}>
                            <FormatAlignCenterIcon />
                          </ToggleButton>

                          <ToggleButton value={TextAlignment.Right}>
                            <FormatAlignRightIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup
                          size="small"
                          sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                        >
                          <ToggleButton
                            value={TextStyle.Bold}
                            onClick={() => handleTextStyleChange(TextStyle.Bold)}
                            selected={elementTextStyle ? elementTextStyle.bold : false}
                          >
                            <FormatBoldIcon />
                          </ToggleButton>

                          <ToggleButton
                            value={TextStyle.Italic}
                            onClick={() => handleTextStyleChange(TextStyle.Italic)}
                            selected={elementTextStyle ? elementTextStyle.italic : false}
                          >
                            <FormatItalicIcon />
                          </ToggleButton>

                          <ToggleButton
                            value="underlined"
                            onClick={() => handleTextDecorationChange()}
                            selected={
                              elementTextDecoration ? elementTextDecoration.underline : false
                            }
                          >
                            <FormatUnderlinedIcon />
                          </ToggleButton>

                          <ToggleButton
                            value="rotate"
                            disabled
                            onClick={() => {
                              if (isOpenColor) {
                                setIsOpenColor(false);
                              }

                              setIsOpenTextRotation(!isOpenTextRotation);
                            }}
                          >
                            <Badge badgeContent={textRotation} max={999} color="primary">
                              <RotateRightIcon />
                            </Badge>
                          </ToggleButton>

                          <ToggleButton
                            value="color"
                            aria-label="color"
                            onClick={() => {
                              if (isOpenTextRotation) {
                                setIsOpenTextRotation(false);
                              }

                              setIsOpenColor(!isOpenColor);
                            }}
                          >
                            <Badge
                              variant="dot"
                              sx={{
                                '& .MuiBadge-badge': {
                                  color: selectedColor,
                                  backgroundColor: selectedColor,
                                },
                              }}
                            >
                              <PaletteIcon />
                            </Badge>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>

                      {/* ROTATE */}
                      <Box display={isOpenTextRotation ? 'block' : 'none'}>
                        <Typography id="input-slider" gutterBottom variant="caption">
                          Rotate Text
                        </Typography>
                        <Grid container spacing={2} alignItems="center" sx={{ px: 1 }}>
                          <Grid item xs>
                            <Slider
                              max={360}
                              value={typeof textRotation === 'number' ? textRotation : 0}
                              onChange={handleSliderChange}
                              aria-labelledby="input-slider"
                            />
                          </Grid>
                          <Grid item>
                            <Input
                              value={textRotation}
                              size="small"
                              onChange={handleTextRotationInputChange}
                              onBlur={handleTextRotationBlur}
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 360,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {/* COLOR */}
                      <Box
                        sx={{
                          display: isOpenColor ? 'flex' : 'none',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ColorPicker
                          color={selectedColor || '#221271'}
                          setColor={(color) => handleTextColorChange(color)}
                          spacing={2}
                        />
                      </Box>
                    </>
                  )}

                {selectedElement?.type === FormElementType.Dropdown && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {options.map((o, i) => (
                      <Box
                        key={`dropdown-option-${i + 1}`}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          label={`${t('forms.option')} ${i + 1}`}
                          placeholder={`${t('forms.writeOptionTitle')}`}
                          value={o}
                          size="small"
                          onFocus={() =>
                            handleOptionFocus(
                              i,
                              `${t('forms.option')} ${i + 1}`,
                              `${t('forms.writeOptionTitle')}`
                            )
                          }
                          onBlur={() => handleOptionBlur(i, `${t('forms.writeOptionTitle')}`)}
                          onChange={(event) => handleOptionChange(i, event)}
                          id={`Q4OIyP_${i}`}
                        />

                        <IconButton onClick={() => handleDeleteOption(i)} id="VhDi3p">
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    ))}
                    <Box>
                      <Link
                        sx={{ minWidth: 'max-content', p: 2 }}
                        component="button"
                        variant="body1"
                        onClick={(event) => {
                          event.preventDefault();
                          handleAddOption();
                        }}
                        id="o8VMyO"
                      >
                        {t('forms.buttons.addAnotherOption')}
                      </Link>
                    </Box>
                  </Box>
                )}

                {selectedElement?.type === FormElementType.Checkbox && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {formGroupOptions.map((o, i) => (
                      <Box
                        key={`dropdown-option-${i + 1}`}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          label={`${t('forms.option')} ${i + 1}`}
                          placeholder={`${t('forms.writeOptionTitle')}`}
                          value={o.label}
                          size="small"
                          onFocus={() => handleFocus(i, `${t('forms.writeOptionTitle')}`)}
                          onBlur={() => handleBlur(i, `${t('forms.writeOptionTitle')}`)}
                          onChange={(event) => handleFormGroupOptionChange(i, event)}
                          id={`Q4OIyP_${i}`}
                        />

                        <IconButton onClick={() => handleDeleteFormGroupOption(i)} id="VhDi3p">
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    ))}
                    <Box>
                      <Link
                        sx={{ minWidth: 'max-content', p: 2 }}
                        component="button"
                        variant="body1"
                        onClick={(event) => {
                          event.preventDefault();
                          handleAddFormGroupOption();
                        }}
                        id="o8VMyO"
                      >
                        {t('forms.buttons.addAnotherOption')}
                      </Link>
                    </Box>
                  </Box>
                )}

                {selectedElement?.type === FormElementType.RadioButton && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {formGroupOptions.map((o, i) => (
                      <Box
                        key={`radio-option-${i + 1}`}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          label={`${t('forms.option')} ${i + 1}`}
                          placeholder={`${t('forms.writeOptionTitle')}`}
                          value={o.label}
                          size="small"
                          onFocus={() => handleFocus(i, `${t('forms.writeOptionTitle')}`)}
                          onBlur={() => handleBlur(i, `${t('forms.writeOptionTitle')}`)}
                          onChange={(event) => handleFormGroupOptionChange(i, event)}
                          id={`Q4OIyP_${i}`}
                        />

                        <IconButton onClick={() => handleDeleteFormGroupOption(i)} id="VhDi3p">
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    ))}

                    <Box>
                      <Link
                        sx={{ minWidth: 'max-content', p: 2 }}
                        component="button"
                        variant="body1"
                        onClick={(event) => {
                          event.preventDefault();
                          handleAddFormGroupOption();
                        }}
                        id="o8VMyO"
                      >
                        {t('forms.buttons.addAnotherOption')}
                      </Link>
                    </Box>
                  </Box>
                )}

                {selectedElement?.type === FormElementType.CustomList && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} id="IbZvuL">
                    <Autocomplete
                      value={selectedList}
                      onChange={(event: any, newList: CustomListProps | null) => {
                        setSelectedList(newList);
                      }}
                      options={customLists}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label={t('forms.elements.settings.lists')} />
                      )}
                    />
                  </Box>
                )}

                {selectedElement?.type === FormElementType.Image && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {uploadedFile && (
                      <Box>
                        <img src={`${uploadedFile}`} alt={`${selectedElement.label}`} />
                      </Box>
                    )}

                    <Button variant="contained" component="label" id="4hjdd5">
                      {t('forms.buttons.uploadImage')}
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        hidden
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
                      />
                    </Button>
                  </Box>
                )}

                {selectedElement?.type === FormElementType.Signature && (
                  <Box sx={{ mt: 2 }}>
                    <ColorPicker
                      color={selectedColor || '#221271'}
                      setColor={(color) => handleTextColorChange(color)}
                      spacing={2}
                    />
                  </Box>
                )}

                {selectedElement?.type === FormElementType.StaticText && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                      label="Multiline"
                      value={staticText}
                      multiline
                      maxRows={4}
                      onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        handleStaticTextChange(event)
                      }
                      id="fFGH5ui"
                      InputLabelProps={{
                        shrink: !!staticText,
                      }}
                    />
                  </Box>
                )}

                {selectedElement?.type === FormElementType.InputField && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} id="fYWH5ui">
                    <Autocomplete
                      size="small"
                      value={validation}
                      disableClearable
                      getOptionLabel={(option) => t(option.label)}
                      onChange={(
                        event: SyntheticEvent<Element, Event>,
                        newValue: ElementValidationsProps | null
                      ) => {
                        if (newValue) {
                          setValidation(newValue);
                        }
                      }}
                      options={[
                        ELEMENT_NO_VALIDATION,
                        ELEMENT_IS_NUMBER,
                        ELEMENT_IS_EMAIL,
                        ELEMENT_NUMBER_OF_CHARACTERS,
                      ]}
                      renderInput={(params) => (
                        <TextField {...params} label={t('forms.validations.validation')} />
                      )}
                    />
                  </Box>
                )}

                {selectedElement?.type === FormElementType.InputField &&
                  validation.value === FormElementValidationType.NumberOfCharacters && (
                    <Box id="fYWH5ui">
                      <Slider
                        value={numberOfCharacters}
                        onChange={handleNumberOfCharactersChange}
                        marks={marks}
                        valueLabelDisplay="auto"
                        min={1}
                        max={100}
                        size="medium"
                      />
                    </Box>
                  )}

                {selectedElement?.type === FormElementType.DateRange && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Autocomplete
                      value={daysOfCountry}
                      onChange={(event: any, newValue: CountryProps | null) =>
                        setDaysOfCountry(newValue || countries[0])
                      }
                      size="small"
                      fullWidth
                      disableClearable
                      options={countries}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt={option.label}
                          />
                          {option.label} ({option.code})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('forms.elements.settings.daysOffByCountry')}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />

                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={openedTabIndex}>
                          <Tab
                            label={t('forms.elements.settings.variables')}
                            onClick={() => setOpenedTabIndex(0)}
                            id="GAqf57F"
                          />
                          <Tab
                            label={t('forms.elements.settings.example')}
                            onClick={() => setOpenedTabIndex(1)}
                            id="TWEc3vb"
                          />
                        </Tabs>
                      </Box>

                      <Box sx={{ display: openedTabIndex === 0 ? 'block' : 'none', mt: 2 }}>
                        <Alert severity="warning" icon={false} sx={{ position: 'relative' }}>
                          <strong>[s]</strong> - {t('forms.elements.settings.startDate')};
                          <br />
                          <strong>[e]</strong> - {t('forms.elements.settings.endDate')};
                          <br />
                          <strong>[c]</strong> - {t('forms.elements.settings.countedCalendarDays')};
                          <br />
                          <strong>[w]</strong> - {t('forms.elements.settings.countedWorkingDays')}.
                        </Alert>
                      </Box>
                      <Box sx={{ display: openedTabIndex === 1 ? 'block' : 'none', mt: 2 }}>
                        <Alert severity="info" icon={false} sx={{ position: 'relative' }}>
                          <strong>{t('forms.elements.settings.template')}</strong> - from [s] to [e]
                          ([w] working days);
                          <br />
                          <strong>{t('forms.elements.settings.userFilled')}</strong> -
                          08/20/2023-08/26/2023;
                          <br />
                          <strong>{t('forms.elements.settings.inTheGeneratedDocument')}</strong> -
                          from 8/20/2023 to 8/26/2023 (5 working days);
                          <br />
                        </Alert>
                      </Box>
                    </Box>

                    <TextField
                      value={dateRangeTemplate}
                      size="small"
                      label={t('forms.elements.settings.template')}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setDateRangeTemplate(event.target.value);
                      }}
                      id="POImh7u"
                    />
                  </Box>
                )}

                {selectedElement?.type === FormElementType.Hyperlink && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                      label={`${t('forms.elements.settings.linkText')}`}
                      value={hyperlink.text}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setHyperlink({ ...hyperlink, text: event.target.value });
                      }}
                      id="RWemh7u"
                    />

                    <TextField
                      label={`${t('forms.elements.settings.websiteAddress')}`}
                      value={hyperlink.url}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setHyperlink({ ...hyperlink, url: event.target.value });
                      }}
                      id="RWe8f6f"
                    />
                  </Box>
                )}

                {selectedElement?.type === FormElementType.User && (
                  <Box>
                    <FormControl>
                      <FormLabel>{t('forms.elements.settings.userData')}</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={userInfo}
                        onChange={handleUserInfoChange}
                      >
                        <FormControlLabel
                          value={UserDataType.Name}
                          control={<Radio />}
                          label={`${t('forms.elements.settings.nameSurname')}`}
                          id="nrR6ym"
                        />
                        <FormControlLabel
                          value={UserDataType.Email}
                          control={<Radio />}
                          label={`${t('forms.elements.settings.emailAddress')}`}
                          id="GcgODE"
                        />
                        <FormControlLabel
                          value={UserDataType.Phone}
                          control={<Radio />}
                          label={`${t('forms.elements.settings.phoneNumber')}`}
                          id="5QY11g"
                        />
                        <FormControlLabel
                          value={UserDataType.Company}
                          control={<Radio />}
                          label={`${t('forms.elements.settings.company')}`}
                          id="oXQKZX"
                        />
                        <FormControlLabel
                          value={UserDataType.Job}
                          control={<Radio />}
                          label={`${t('forms.elements.settings.jobTitle')}`}
                          id="qa9wLh"
                        />
                        <FormControlLabel
                          value={UserDataType.Address}
                          control={<Radio />}
                          label={`${t('forms.elements.settings.address')}`}
                          id="1UASlG"
                        />
                        <FormControlLabel
                          value={UserDataType.ManagersName}
                          control={<Radio />}
                          label={`${t('forms.elements.settings.managersName')}`}
                          id="8beGAS"
                        />
                        <FormControlLabel
                          value={UserDataType.ManagersEmail}
                          control={<Radio />}
                          label={`${t('forms.elements.settings.managersEmail')}`}
                          id="fsycom"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}

                {selectedElement?.type === FormElementType.RegistrationNumber && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                      label={`${t('forms.elements.settings.uniqueIdentifier')}`}
                      value={prefix}
                      onChange={handleUniqueIdentifierChange}
                      id="YlyX1j"
                    />
                  </Box>
                )}

                {(selectedElement?.type === FormElementType.InputField ||
                  selectedElement?.type === FormElementType.Date ||
                  selectedElement?.type === FormElementType.DateRange) &&
                  !selectedElement.isChild && (
                    <Box>
                      <Divider sx={{ mb: 2 }}>
                        <Typography variant="body2">
                          {t('forms.elements.settings.addChildEl')}
                        </Typography>
                      </Divider>
                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                          <Autocomplete
                            value={selectedChildElement}
                            onChange={(event, newInputValue) => {
                              handleChildInputChange(newInputValue);
                            }}
                            options={sameTypeElementsList.filter(
                              (t) => !childElements.some((c) => c.id === t.id)
                            )}
                            getOptionLabel={(option) => `${option.name} (${option.id})`}
                            fullWidth
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t('forms.elements.settings.sameTypeEl')}`}
                              />
                            )}
                            id="F335YI"
                          />

                          <IconButton
                            disabled={selectedChildElement === null}
                            onClick={() => handleAddChildElement()}
                            id="R5Txzg"
                          >
                            <AddIcon color={selectedChildElement ? 'primary' : 'disabled'} />
                          </IconButton>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 1,
                            width: '100%',
                            maxHeight: '160px',
                            overflow: 'auto',
                          }}
                        >
                          {childElements.length > 0 &&
                            childElements.map((t, i) => (
                              <Box
                                key={`form-template-${t}`}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Typography noWrap>{`${t.name} (${t.id})`}</Typography>

                                <IconButton onClick={() => handleDeleteChildElement(i)} id="4FdwWb">
                                  <ClearIcon color="error" />
                                </IconButton>
                              </Box>
                            ))}
                        </Box>
                      </FormGroup>
                    </Box>
                  )}

                {(selectedElement?.type === FormElementType.Date ||
                  selectedElement?.type === FormElementType.DateRange) && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isPastDisabled}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setIsPastDisabled(event.target.checked)
                          }
                          id="m4ecak6n"
                        />
                      }
                      label={t('forms.disablePastDates')}
                    />
                  </FormGroup>
                )}

                {element.type === FormElementType.AddressBook && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="options-dropdown-label">{t('forms.selectOption')}</InputLabel>
                      <Select
                        size="small"
                        labelId="options-dropdown-label"
                        value={addressBookType}
                        onChange={(e) => handleAddreessBookType(e.target.value as AddressBookType)}
                        label={t('forms.selectOption')}
                        IconComponent={ExpandMoreRoundedIcon}
                        id="JdnR00"
                      >
                        <MenuItem id="JdnR01" value={AddressBookType.All}>
                          {t('forms.all')}
                        </MenuItem>
                        <MenuItem id="JdnR02" value={AddressBookType.Internal}>
                          {t('forms.internal')}
                        </MenuItem>
                        <MenuItem id="JdnR03" value={AddressBookType.External}>
                          {t('forms.external')}
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {/* /!* ----- Custom tooltip for AddressBook *!/  */}
                    <CustomTooltip
                      title={
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 1,
                          }}
                        >
                          {/*   /!* ---- All ----*!/  */}
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <Trans
                              i18nKey="forms.allDescritpion"
                              values={{ all: t('forms.all') }}
                              components={{ bold: <strong /> }}
                            />
                          </Typography>

                          {/*  /!* ---- Internal ----*!/ */}
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <Trans
                              i18nKey="forms.internalDescription"
                              values={{ internal: t('forms.internal') }}
                              components={{ bold: <strong /> }}
                            />
                          </Typography>

                          {/* /!* ---- External ----*!/  */}
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <Trans
                              i18nKey="forms.externalDescription"
                              values={{ external: t('forms.external') }}
                              components={{ bold: <strong /> }}
                            />
                          </Typography>
                        </Box>
                      }
                      arrow
                    >
                      <HelpIcon />
                    </CustomTooltip>
                  </Box>
                )}

                {(element.type === FormElementType.InputField ||
                  element.type === FormElementType.Dropdown ||
                  element.type === FormElementType.Checkbox ||
                  element.type === FormElementType.RadioButton ||
                  element.type === FormElementType.Date ||
                  element.type === FormElementType.DateRange ||
                  element.type === FormElementType.CustomList ||
                  element.type === FormElementType.Signature ||
                  element.type === FormElementType.AddressBook) && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isElementRequired}
                        color="primary"
                        onClick={() => setIsElementRequired(!isElementRequired)}
                        id="VrdTaF"
                      />
                    }
                    label={`${t('forms.required')}`}
                  />
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'left', gap: 3 }}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    clearStateOnClose();
                    handleDelete();
                    handleClose(false);
                  }}
                  id="6pKJkV"
                >
                  {t('shared.buttons.delete')}
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    clearStateOnClose();
                    handleClose(false);
                  }}
                  id="yd0sown5"
                >
                  {t('forms.buttons.save')}
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ElementSettingsDialog;
