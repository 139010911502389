import { createSlice } from '@reduxjs/toolkit';

import documentSignerService from 'src/services/documentSignerService';
import { DocumentSignerState, DocumentSignerSetRequest } from 'src/@types/documents/types';

import { dispatch } from '../store';
import { Tabs } from '../../sections/documents/signing/DocumentSigning';

// ----------------------------------------------------------------------

export enum SignType {
  onlyApprove = 'onlyApprove',
  onlyQualified = 'onlyQualified',
  handWrittenSign = 'handWrittenSign',
}

const initialState: DocumentSignerState = {
  isLoading: false,
  error: null,
  signers: [],
  signType: SignType.onlyApprove,
  tab: Tabs.Approve,
  showTabs: {
    MOBILE_ID: false,
    SMART_ID: false,
    EPARAKSTS: false,
    EPARAKSTS_CARD: false,
    APPROVE: true,
    ACKNOWLEDGE: false,
    SIGNATURE_PAD: false,
  },
};

const slice = createSlice({
  name: 'documentSigner',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.signers = initialState.signers;
      state.tab = initialState.tab;
      state.showTabs = initialState.showTabs;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSignersSuccess(state, action) {
      state.isLoading = false;
      state.signers = action.payload;
    },

    getSignersError(state, action) {
      state.isLoading = false;
      state.signers = initialState.signers;
      state.error = action.payload;
    },

    setSignerSigned(state, action) {
      state.isLoading = false;
      state.signers = state.signers.map((s) =>
        s.email === action.payload ? { ...s, signed: new Date() } : s
      );
    },

    setSignType(state, action) {
      state.signType = action.payload;
    },

    setTab(state, action) {
      state.tab = action.payload;
    },

    setShowTabs(state, action) {
      state.showTabs = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function clearSignersState() {
  dispatch(slice.actions.clearState());
}

export function setSignerSigned(email?: string) {
  if (email) {
    dispatch(slice.actions.setSignerSigned(email));
  }
}

export async function getSigners(id: number | null) {
  if (id) {
    dispatch(slice.actions.startLoading());
    try {
      const data = await documentSignerService.getSigners(id);

      dispatch(slice.actions.getSignersSuccess(data));
    } catch (error) {
      dispatch(slice.actions.getSignersError(error));
    }
  } else {
    dispatch(slice.actions.getSignersError('documents.errors.documentNotFound'));
  }
}

export async function saveSigners(request: DocumentSignerSetRequest) {
  if (request.documentId) {
    dispatch(slice.actions.startLoading());
    try {
      await documentSignerService.saveSigners(request);
      dispatch(slice.actions.finishLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  } else {
    dispatch(slice.actions.hasError('documents.errors.documentNotFound'));
  }
}

export const setSignType = (signType: SignType) => {
  dispatch(slice.actions.setSignType(signType));
};

export const setTab = (tab: Tabs) => {
  dispatch(slice.actions.setTab(tab));
};

export const setShowTabs = (showTabs: DocumentSignerState['showTabs']) => {
  dispatch(slice.actions.setShowTabs(showTabs));
};

export const setStartLoading = () => {
  dispatch(slice.actions.startLoading());
};

export const setFinishLoading = () => {
  dispatch(slice.actions.finishLoading());
};

export const setinitialState = () => {
  setSignType(SignType.onlyApprove);
  setTab(Tabs.Approve);
  setShowTabs({
    MOBILE_ID: false,
    SMART_ID: false,
    EPARAKSTS: false,
    EPARAKSTS_CARD: false,
    APPROVE: true,
    ACKNOWLEDGE: false,
    SIGNATURE_PAD: false,
  });
};
