import * as console from 'node:console';
import { useSearchParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import { Box, Card, Fade, Stack, Drawer, Container } from '@mui/material';
import {
  GridColDef,
  DataGridPro,
  GridToolbar,
  useGridApiRef,
  GridDataSource,
  GridRowSelectionModel,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';

import useNotifications from 'src/hooks/useNotifications';

import Page from '../../components/Page';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';
import eventBus, { EventTypes } from '../../utils/eventBus';
import documentService from '../../services/documentService';
import DLXDriveTitle from '../../sections/dlx-drive/DLXDriveTitle';
import { DocumentsRequestProps } from '../../@types/documents/types';
import FolderTree from '../../sections/dlx-drive/folder-tree/FolderTree';
import DLXDriveBreadcrumbs from '../../sections/dlx-drive/DLXDriveBreadcrumbs';
import DLXDriveFolderActions from '../../sections/dlx-drive/DLXDriveFolderActions';
import DLXDriveDocumentActions from '../../sections/dlx-drive/DLXDriveDocumentActions';
import DLXDriveBulkActionComponent from '../../sections/dlx-drive/DLXDriveBulkActionComponent';
import {
  DEFAULT_COLUMNS,
  FAKE_RESPONSE_FOR_DATA_GRID,
} from '../../@types/documents/constants/DefaultColumns';

// ----------------------------------------------------------------

const Folders = () => {
  const { t } = useLocales();
  const [folderId, setFolderId] = useState<number | null>(null);
  const { breadcrumbs, checkedItems } = useSelector((state) => state.dlxDrive);
  const [pageTitle, setPageTitle] = useState<string>(t('layout.dlxDrive'));
  const [showFolderTree, setShowFolderTree] = useState<boolean>(() => {
    const showFolderTree = localStorage.getItem('showFolderTree');
    return showFolderTree ? JSON.parse(showFolderTree) : true;
  });
  const { enqueueSuccess, enqueueError } = useNotifications();
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useResponsive('only', 'xs');
  const showDrawer = useResponsive('down', 'lg');
  const [folderTreeExpandedItems, setFolderTreeExpandedItems] = useState<string[]>(() => {
    const folderTreeExpandedItems = localStorage.getItem('folderTreeExpandedItems');

    return folderTreeExpandedItems ? JSON.parse(folderTreeExpandedItems) : [];
  });

  // TODO
  const [searchParams, setSearchParams] = useSearchParams();

  const [documentsRequest, setDocumentsRequest] = useState<DocumentsRequestProps>({
    folderId: id ? Number(id) : null,
    formTemplateId: null,
    filterModel: { items: [] },
    sortModel: [],
    paginationModel: { pageSize: 1, page: 0 },
  });

  // TODO
  // const [filterModel, setFilterModel] = useState<GridFilterModel>();
  // const [sortModel, setSortModel] = useState<GridSortModel>();
  // const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
  //   pageSize: 1,
  //   page: 0,
  // });

  const filterModelParams = searchParams.get('filterModel');
  const sortModelParams = searchParams.get('sortModel');
  const paginationModelParams = searchParams.get('paginationModel');
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const apiRef = useGridApiRef();
  const pageSizeOptions = [1, 5, 10, 50];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    Extension: false,
    Actions: false,
    SignerDetails: false,
  });

  const fetchRows = () => {};

  const dataSource: GridDataSource = React.useMemo(
    () => ({
      getRows: async (params) => {
        const getRowsResponse = FAKE_RESPONSE_FOR_DATA_GRID;

        return {
          rows: getRowsResponse.rows,
          rowCount: getRowsResponse.rowCount,
        };
      },
    }),
    [fetchRows]
  );

  const hiddenFields = ['id', 'Extension', 'Actions', 'SignerDetails'];

  const getTogglableColumns = (columns: GridColDef[]) =>
    columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);

  const autosizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    outliersFactor: 2,
    expand: true,
  };

  const getDocuments = async () => {
    const response = await documentService.getDocuments(documentsRequest);

    console.log(response);

    return response;
  };

  useEffect(() => {
    const newDocumentsRequest = { ...documentsRequest };

    // filterModel
    if (filterModelParams) {
      newDocumentsRequest.filterModel = JSON.parse(filterModelParams);
    } else {
      newDocumentsRequest.filterModel = { items: [] };
    }

    // sortModel
    if (sortModelParams) {
      newDocumentsRequest.sortModel = JSON.parse(sortModelParams);
    } else {
      newDocumentsRequest.sortModel = [];
    }

    // paginationModel
    if (paginationModelParams) {
      newDocumentsRequest.paginationModel = JSON.parse(paginationModelParams);
    } else {
      newDocumentsRequest.paginationModel = { pageSize: 1, page: 0 };
    }

    setDocumentsRequest(newDocumentsRequest);
  }, [filterModelParams, sortModelParams, paginationModelParams]);

  useEffect(() => {
    const newDocumentsRequest = { ...documentsRequest };

    // --- documentsRequest ---

    // folderId
    newDocumentsRequest.folderId = id ? Number(id) : null;

    // formTemplateId
    newDocumentsRequest.formTemplateId = null;

    // --- searchParams ---

    // filterModel
    searchParams.set('filterModel', JSON.stringify({ items: [] }));

    // sortModel
    searchParams.set('sortModel', JSON.stringify([]));

    // paginationModel
    searchParams.set('paginationModel', JSON.stringify({ pageSize: 1, page: 0 }));

    // --- setDocumentsRequest & setSearchParams ---

    setSearchParams(searchParams);
    setDocumentsRequest(newDocumentsRequest);
  }, [id]);

  useEffect(() => {
    getDocuments();
  }, [documentsRequest]);

  // TODO ^^^

  useEffect(() => {
    if (breadcrumbs.length > 0) {
      const newTitle = `${breadcrumbs[breadcrumbs.length - 1].name} - ${t('layout.dlxDrive')}`;

      if (pageTitle !== newTitle) {
        setPageTitle(newTitle);
      }
    }
  }, [breadcrumbs]);

  useEffect(() => {
    localStorage.setItem('showFolderTree', JSON.stringify(showFolderTree));
  }, [showFolderTree, folderTreeExpandedItems]);

  useEffect(() => {
    localStorage.setItem('folderTreeExpandedItems', JSON.stringify(folderTreeExpandedItems));
  }, [folderTreeExpandedItems]);

  useEffect(() => {
    const documentSignedSubscription = eventBus.subscribe(
      EventTypes.DocumentSigned,
      (msg: string) => {
        enqueueSuccess(msg);
      }
    );

    const forbidSignSubscription = eventBus.subscribe(EventTypes.ForbidSign, (msg: string) => {
      enqueueError(msg);
    });

    return () => {
      documentSignedSubscription.unsubscribe();
      forbidSignSubscription.unsubscribe();
    };
  }, []);

  return (
    <Page title={pageTitle}>
      <Container maxWidth={showFolderTree ? false : 'xl'}>
        <Stack direction="row" spacing={4}>
          {showFolderTree && !showDrawer && (
            <Card sx={{ width: { xl: '25%', lg: '30%' } }}>
              <FolderTree
                showDrawer={showDrawer || false}
                setShowFolderTree={setShowFolderTree}
                folderTreeExpandedItems={folderTreeExpandedItems}
                setFolderTreeExpandedItems={setFolderTreeExpandedItems}
              />
            </Card>
          )}

          <Box
            sx={{
              width: showFolderTree && !showDrawer ? { xl: '75%', lg: '70%' } : '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <DLXDriveBreadcrumbs
              showFolderTree={showFolderTree}
              setIsDrawerOpen={setShowFolderTree}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'left' : 'center',
                gap: 2,
                my: isMobile ? 0 : 0.5,
              }}
            >
              <Box flexGrow={1} sx={{ overflow: 'hidden' }} id="dfODF45">
                <DLXDriveTitle />
              </Box>

              <DLXDriveFolderActions isRootFolder={breadcrumbs.length === 1} />
            </Box>

            {breadcrumbs.length > 1 && folderId && <DLXDriveDocumentActions folderId={folderId} />}

            <Box>
              <div style={{ height: 400 }}>
                <DataGridPro
                  apiRef={apiRef}
                  columns={DEFAULT_COLUMNS}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                  unstable_dataSource={dataSource}
                  pagination
                  paginationModel={documentsRequest.paginationModel}
                  filterModel={documentsRequest.filterModel}
                  sortModel={documentsRequest.sortModel}
                  resetPageOnSortFilter
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowClick={(params) => {
                    const { id } = params.row;

                    navigate(`/standard/documents/${id}`);
                  }}
                  pageSizeOptions={pageSizeOptions}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: { showQuickFilter: true },
                    loadingOverlay: {
                      variant: 'skeleton',
                      noRowsVariant: 'skeleton',
                    },
                    columnsManagement: {
                      getTogglableColumns,
                    },
                  }}
                  autosizeOptions={autosizeOptions}
                  onPaginationModelChange={(newPaginationModel) => {
                    searchParams.set('paginationModel', JSON.stringify(newPaginationModel));
                    setSearchParams(searchParams);
                  }}
                  onSortModelChange={(newSortModel) => {
                    searchParams.set('sortModel', JSON.stringify(newSortModel));
                    setSearchParams(searchParams);
                  }}
                  onFilterModelChange={(newFilterModel) => {
                    searchParams.set('filterModel', JSON.stringify(newFilterModel));
                    setSearchParams(searchParams);
                  }}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    console.log(newRowSelectionModel);
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                />
              </div>
            </Box>
          </Box>
        </Stack>

        <Fade in={checkedItems.length > 0} timeout={800}>
          <Box>
            <DLXDriveBulkActionComponent />
          </Box>
        </Fade>
      </Container>

      <Drawer open={showDrawer && showFolderTree} onClose={() => setShowFolderTree(false)}>
        <FolderTree
          showDrawer={showDrawer || false}
          setShowFolderTree={setShowFolderTree}
          folderTreeExpandedItems={folderTreeExpandedItems}
          setFolderTreeExpandedItems={setFolderTreeExpandedItems}
        />
      </Drawer>
    </Page>
  );
};

export default Folders;
