import { Draggable } from 'react-beautiful-dnd';
import React, { FC, useState, useEffect } from 'react';

import { Checkbox, ListItem, FormControlLabel } from '@mui/material';

import FormDefaultSigners from './FormDefaultSigners';
import { DraggableApproverProps } from '../../../@types/forms/types';
import { FormApprovalType } from '../../../@types/forms/enums/FormApprovalType';

// ----------------------------------------------------------------------

export type FormApproversListItemProps = {
  item: DraggableApproverProps;
  handleItemChange: (item: DraggableApproverProps) => void;
  index: number;
  itemOrderNumber: number;
};

const FormApproversListItem: FC<FormApproversListItemProps> = ({
  item,
  handleItemChange,
  index,
  itemOrderNumber,
}) => {
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);

  useEffect(() => {
    handleItemChange({ ...item, signerEmails: emailAddresses });
  }, [emailAddresses]);

  useEffect(() => {
    if (item.signerEmails && item.signerEmails.length > 0) {
      setEmailAddresses(item.signerEmails);
    }
  }, [item]);

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <ListItem
          sx={{ p: 0, m: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <FormControlLabel
            control={
              <Checkbox
                id={item.id}
                disabled={item.id === 'doclogix-approval'}
                checked={item.checked}
                onChange={(event) => {
                  handleItemChange({ ...item, checked: event.target.checked });
                }}
              />
            }
            label={
              itemOrderNumber === -1
                ? item.approvalName
                : `${itemOrderNumber + 1}. ${item.approvalName}`
            }
          />

          {item.approvalType === FormApprovalType.DefaultSignersApproval && item.checked && (
            <FormDefaultSigners
              emailAddresses={emailAddresses}
              setEmailAddresses={setEmailAddresses}
            />
          )}
        </ListItem>
      )}
    </Draggable>
  );
};

export default FormApproversListItem;
