import React from 'react';

import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import useSettings from '../../../hooks/useSettings';

// ----------------------------------------------------------------------

type ThemeModeChangerProps = {
  id: string;
};

const ThemeModeChanger: React.FC<ThemeModeChangerProps> = ({ id }) => {
  const theme = useTheme();
  const { onToggleMode } = useSettings();

  return (
    <IconButton sx={{ ml: 1 }} onClick={onToggleMode} size="large" id={id}>
      {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

export default ThemeModeChanger;
