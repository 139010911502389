import { GridColDef } from '@mui/x-data-grid-pro';

import { DocumentStatuses } from '../enums/documentStatuses';
import { DocumentColumnType } from '../enums/documentColumnType';
import GetStatusName from '../../../utils/folders/getStatusName';
import RenderTagField from '../../../utils/folders/renderTagField';
import RenderNameField from '../../../utils/folders/renderNameField';
import RenderStatusField from '../../../utils/folders/renderStatusField';
import RenderDateTimeField from '../../../utils/folders/renderDateTimeField';
import RenderSignerDetailsField from '../../../utils/folders/renderSignerDetailsField';

export const DEFAULT_COLUMNS: GridColDef<(typeof FAKE_RESPONSE_FOR_DATA_GRID.rows)[number]>[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: DocumentColumnType.Number,
    filterable: false,
  },
  {
    field: 'Extension',
    headerName: 'Extension',
    type: DocumentColumnType.String,
    filterable: false,
  },
  {
    field: 'Name',
    headerName: 'Name',
    type: DocumentColumnType.String,
    hideable: false,
    flex: 1,
    minWidth: 250,
    renderCell: (params) => {
      const { Extension, Name } = params.row;

      return <RenderNameField name={Name} extension={Extension} />;
    },
  },
  {
    field: 'Created',
    headerName: 'Created',
    type: DocumentColumnType.DateTime,
    minWidth: 160,
    valueGetter: (value: string) => new Date(value),
    renderCell: (params) => {
      const { Created } = params.row;

      return <RenderDateTimeField date={Created} format="dateTime" />;
    },
  },
  {
    field: 'Modified',
    headerName: 'Modified',
    type: DocumentColumnType.DateTime,
    width: 160,
    valueGetter: (value: string) => new Date(value),
    renderCell: (params) => {
      const { Modified } = params.row;

      return <RenderDateTimeField date={Modified} format="dateTime" />;
    },
  },
  {
    field: 'Owner',
    headerName: 'Owner',
    type: DocumentColumnType.String,
    minWidth: 160,
  },
  {
    field: 'Status',
    headerName: 'Status',
    type: DocumentColumnType.SingleSelect,
    minWidth: 160,
    valueOptions: [
      {
        value: DocumentStatuses.New,
        label: <GetStatusName status={DocumentStatuses.New} />,
      },
      {
        value: DocumentStatuses.Completed,
        label: <GetStatusName status={DocumentStatuses.Completed} />,
      },
      {
        value: DocumentStatuses.WaitingForOthers,
        label: <GetStatusName status={DocumentStatuses.WaitingForOthers} />,
      },
      {
        value: DocumentStatuses.WaitingForMe,
        label: <GetStatusName status={DocumentStatuses.WaitingForMe} />,
      },
      {
        value: DocumentStatuses.Rejected,
        label: <GetStatusName status={DocumentStatuses.Rejected} />,
      },
      {
        value: DocumentStatuses.Voided,
        label: <GetStatusName status={DocumentStatuses.Voided} />,
      },
      {
        value: DocumentStatuses.WaitingForOthersApprove,
        label: <GetStatusName status={DocumentStatuses.WaitingForOthersApprove} />,
      },
      {
        value: DocumentStatuses.WaitingForMeApprove,
        label: <GetStatusName status={DocumentStatuses.WaitingForMeApprove} />,
      },
      {
        value: DocumentStatuses.WaitingForRelatedDocuments,
        label: <GetStatusName status={DocumentStatuses.WaitingForRelatedDocuments} />,
      },
      {
        value: DocumentStatuses.WaitingForMeToAcknowledge,
        label: <GetStatusName status={DocumentStatuses.WaitingForMeToAcknowledge} />,
      },
      {
        value: DocumentStatuses.WaitingForOthersToAcknowledge,
        label: <GetStatusName status={DocumentStatuses.WaitingForOthersToAcknowledge} />,
      },
    ],
    renderCell: (params) => {
      const { Status } = params.row;

      return <RenderStatusField status={Status} />;
    },
  },
  {
    field: 'Signatures',
    headerName: 'Signatures',
    type: DocumentColumnType.String,
    renderCell: (params) => {
      const { Signatures, SignerDetails } = params.row;

      return <RenderSignerDetailsField signatures={Signatures} signerDetails={SignerDetails} />;
    },
  },
  {
    field: 'SignerDetails',
    headerName: 'Signer details',
    type: DocumentColumnType.String,
    filterable: false,
  },
  {
    field: 'Comment',
    headerName: 'Comment',
    type: DocumentColumnType.String,
  },
  {
    field: 'Tags',
    headerName: 'Tags',
    type: DocumentColumnType.SingleSelect,
    renderCell: (params) => {
      const { Tags } = params.row;

      return <RenderTagField tags={Tags} />;
    },
  },
  {
    field: 'Actions',
    headerName: 'Actions',
    type: DocumentColumnType.Number,
    filterable: false,
  },
];

// TODO Fake Data
export const FAKE_RESPONSE_FOR_DATA_GRID = {
  actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  breadcrumbs: [
    {
      id: 2,
      name: 'Root',
    },
    {
      id: 481,
      name: 'newDocs',
    },
  ],
  color: '#5C62FF',
  icon: 'CalculateRoundedIcon',
  id: 481,
  isPinned: true,
  name: 'newDocs',
  parentFolderId: 2,
  rowCount: 2,
  rows: [
    {
      id: 74457,
      Extension: 'adoc',
      Name: 'colorPdf.adoc',
      Created: '2025-02-13T14:22:14.236388',
      Modified: '2025-02-13T14:22:14.514847',
      Owner: 'TESTNUMBER MULTIPLE OK',
      Status: 3,
      Signatures: '0/2',
      SignerDetails: [
        {
          email: 'akazakevicius@doclogix.lt',
          fullName: 'TESTNUMBER MULTIPLE OK',
        },
        {
          email: 'asveikauskas@doclogix.lt',
          fullName: 'TESTNUMBER MULTIPLE',
        },
      ],
      Comment:
        'The first argument of this function is the error object, and the second argument is the fetch parameters of type GridGetRowsParams',
      Tags: [
        {
          color: '#97294e',
          id: 13,
          name: 'the other tag',
        },
        {
          color: '#bc2121',
          id: 14,
          name: 'Tarptautinės sutart.',
        },
      ],
      Actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    },
    {
      id: 74458,
      Extension: 'asice',
      Name: 'colorPdf.asice',
      Created: '2025-02-13T14:22:48.69159',
      Modified: '2025-02-13T14:22:48.929381',
      Owner: 'TESTNUMBER MULTIPLE OK',
      Status: 6,
      Signatures: '0/1',
      SignerDetails: [
        {
          email: 'akazakevicius@doclogix.lt',
          fullName: 'TESTNUMBER MULTIPLE OK',
        },
      ],
      Comment: null,
      Tags: [],
      Actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    },
  ],
};
