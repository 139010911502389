import React from 'react';

import { Box, Tooltip, Typography } from '@mui/material';

// ----------------------------------------------------------------

type SignerDetailsProps = {
  email: string;
  fullName: string;
};

type RenderSignerDetailsFieldProps = {
  signatures: string;
  signerDetails: SignerDetailsProps[];
};

const RenderSignerDetailsField: React.FC<RenderSignerDetailsFieldProps> = ({
  signatures,
  signerDetails,
}) => {
  if (signerDetails.length > 0) {
    return (
      <Tooltip
        title={signerDetails.map((signer) => (
            <Box sx={{ p: 1 }}>
              <Typography variant="subtitle2">{signer.fullName}</Typography>
              <Typography variant="caption">{signer.email}</Typography>
            </Box>
          ))}
        arrow
        placement="bottom"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -20],
                },
              },
            ],
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {signatures}
        </Box>
      </Tooltip>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {signatures}
    </Box>
  );
};

export default RenderSignerDetailsField;
