import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import useNotifications from '../../hooks/useNotifications';
import documentService from '../../services/documentService';
import { SelectedItemProp } from '../documents/DocumentsList';
import { DLXDriveSelectedItem } from '../../@types/dlx-drive/types';
import { EParakstsInitSignatureRequest } from '../../@types/documents/types';
import { DocumentStatuses } from '../../@types/documents/enums/documentStatuses';
import { TimeStamps, TimeStampPages } from '../documents/signing/DocumentSigning';
import { initEParakstsSignatureSession } from '../../redux/slices/signatureSession';
import { FOLDER_ACTIONS_MENU_ICON_SIZE } from '../../utils/constants/dlxDriveConstants';
import { DocumentSignatureType } from '../../@types/documents/enums/documentSignatureTypes';
import {
  setDriveListIdState,
  addStartLoadingState,
  clearCheckedItemsState,
} from '../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveBulkSignDialogProps = {
  isBulkSignDialogOpen: boolean;
  setIsBulkSignDialogOpen: (value: boolean) => void;
  isDocumentsListItems?: boolean;
  selectedIds?: SelectedItemProp[];
  handleRemoveItem?: (id: number) => void;
};

const DLXDriveBulkSignDialog: React.FC<DLXDriveBulkSignDialogProps> = ({
  isBulkSignDialogOpen,
  setIsBulkSignDialogOpen,
  isDocumentsListItems,
  selectedIds,
  handleRemoveItem,
}) => {
  const { t, i18n } = useLocales();
  const { isLoading, checkedItems } = useSelector((state) => state.dlxDrive);
  const { settings } = useSelector((state) => state.company);
  const [itemsToSign, setItemsToSign] = useState<DLXDriveSelectedItem[]>([]);
  const { enqueueError } = useNotifications();

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'id',
      headerName: t('dlxDrive.dataGrid.columnId'),
    },
    {
      field: 'name',
      headerName: t('dlxDrive.dataGrid.columnName'),
      flex: 1,
    },
    {
      field: 'remove',
      headerName: t('dlxDrive.dataGrid.columnRemove'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const newItemsToApprove = itemsToSign.filter((item) => item.id !== params.row.id);

          setItemsToSign(newItemsToApprove);
        };

        return (
          <IconButton onClick={onClick} id="wG0ovZ">
            <img
              src="/icons/dlx-drive/actions/delete.svg"
              width={FOLDER_ACTIONS_MENU_ICON_SIZE}
              height={FOLDER_ACTIONS_MENU_ICON_SIZE}
              alt={t('delete')}
            />
          </IconButton>
        );
      },
    },
  ];

  const rows = itemsToSign.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const handleClose = () => {
    setIsBulkSignDialogOpen(false);
  };

  const getItemType = (item: DLXDriveSelectedItem) => {
    let type: string;

    switch (item.name.split('.').pop()) {
      case 'adoc':
        type = 'adoc';
        break;
      case 'asice':
        type = 'asice';
        break;
      case 'bdoc':
        type = 'bdoc';
        break;
      case 'edoc':
        type = 'edoc';
        break;
      default:
        type = 'pdf';
        break;
    }

    return type;
  };

  const handleSign = async () => {
    addStartLoadingState();

    try {
      const documents = itemsToSign.map((item) => ({
        id: item.id,
        type: getItemType(item),
      }));

      const ids = itemsToSign.map((item) => item.id);

      const initSign = await documentService.getInitSignBulk(ids, DocumentSignatureType.EParaksts);

      if (!initSign || initSign?.isBlocked) {
        enqueueError('documents.snackbar.documentIsBeingSigned');
        return;
      }

      const timestampLogo = settings?.timestampImage || undefined;

      let originUrl = `${window.location.href}`;
      const paramsStartIndex = originUrl.indexOf('?');

      if (paramsStartIndex !== -1) {
        originUrl = originUrl.slice(0, paramsStartIndex);
      }

      const lastCharacter = originUrl.slice(-1);

      if (lastCharacter === '/') {
        originUrl = originUrl.slice(0, -1);
      }

      const eParakstsData = {
        signType: 1,
        fileExtension: 'pdf',
        documentId: undefined,
        personalCode: undefined,
        countryCode: undefined,
        documentIds: undefined,
        timestampPosition: TimeStamps.TopLeft,
        timestampImage: timestampLogo,
        companyCode: undefined,
        companyAddress: undefined,
        companyName: undefined,
        language: i18n.language,
        documentName: undefined,
        timestampPage: TimeStampPages.First,
        originUrl,
        signCoordinates: undefined,
        comment: undefined,
        documents,
      } as unknown as EParakstsInitSignatureRequest;

      await initEParakstsSignatureSession(eParakstsData);

      clearCheckedItemsState();
    } catch (error) {
      console.error(error);
    } finally {
      setDriveListIdState();
      setIsBulkSignDialogOpen(false);
    }
  };

  useEffect(() => {
    if (isBulkSignDialogOpen && !isDocumentsListItems) {
      const newItemsToSign = checkedItems.filter(
        (item) =>
          item.status &&
          item.status === DocumentStatuses.WaitingForMe &&
          item.name.split('.').pop() !== 'adoc'
      );

      setItemsToSign(newItemsToSign);
    }

    if (selectedIds && isDocumentsListItems) {
      const newItemsToSign: DLXDriveSelectedItem[] = [];

      selectedIds.map((item) => {
        if (item.status === DocumentStatuses.WaitingForMe && item.extension !== 'adoc') {
          const newItem = {
            isFolder: item.isFolder,
            id: item.id,
            name: item.name,
            actions: [],
            status: item.status,
          } as DLXDriveSelectedItem;

          newItemsToSign.push(newItem);
        }
      });

      setItemsToSign(newItemsToSign);
    }
  }, [isBulkSignDialogOpen, checkedItems]);

  useEffect(() => {
    if (itemsToSign.length === 0) {
      setIsBulkSignDialogOpen(false);
    }
  }, [itemsToSign]);

  return (
    <Dialog open={isBulkSignDialogOpen} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>{t('documents.bulkDocumentSigning')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('documents.massApproving.messages.signDialogMessage')}
        </DialogContentText>

        <DataGridPro
          columns={columns}
          rows={rows}
          rowHeight={50}
          sx={{ width: '100%', mt: 2 }}
          disableColumnFilter
          disableColumnMenu
          disableMultipleColumnsSorting
          disableChildrenSorting
          localeText={{ footerTotalRows: t('dlxDrive.dataGrid.footerTotalRows') }}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={itemsToSign.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={handleSign}
          id="yd0sown5"
        >
          {`${t('shared.buttons.sign')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveBulkSignDialog;
