import React from 'react';

import Label from '../../components/Label';
import useLocales from '../../hooks/useLocales';
import { getStatusString } from '../documentUtils';
import { DocumentStatuses } from '../../@types/documents/enums/documentStatuses';

// ----------------------------------------------------------------------

type RenderStatusFieldProps = {
  status: DocumentStatuses;
};

const RenderStatusField: React.FC<RenderStatusFieldProps> = ({ status }) => {
  const { t } = useLocales();

  let color: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

  switch (status) {
    case DocumentStatuses.New:
      color = 'secondary';
      break;

    case DocumentStatuses.Completed:
      color = 'success';
      break;

    case DocumentStatuses.WaitingForMe:
    case DocumentStatuses.Rejected:
    case DocumentStatuses.Voided:
      color = 'error';
      break;

    case DocumentStatuses.WaitingForOthers:
      color = 'warning';
      break;

    case DocumentStatuses.WaitingForOthersApprove:
      color = 'warning';
      break;

    case DocumentStatuses.WaitingForMeToAcknowledge:
      color = 'error';
      break;

    case DocumentStatuses.WaitingForOthersToAcknowledge:
      color = 'warning';
      break;

    case DocumentStatuses.WaitingForMeApprove:
      color = 'error';
      break;

    default:
      color = 'default';
  }

  const statusLabel = getStatusString(status);

  return (
    <Label variant="ghost" color={color}>
      {t(statusLabel)}
    </Label>
  );
};

export default RenderStatusField;
