// NEW FORM BUILDER COMPONENT

import dayjs from 'dayjs';
import * as Yup from 'yup';
import * as pdf from 'react-pdf';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import { Form, useFormik, FormikProvider } from 'formik';
import React, { useRef, useState, useEffect } from 'react';
import { Position, DraggableData, ResizableDelta } from 'react-rnd';

import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Card,
  Grid,
  Link,
  Button,
  Switch,
  Tooltip,
  Checkbox,
  useTheme,
  Accordion,
  FormGroup,
  TextField,
  CardHeader,
  IconButton,
  Typography,
  CardContent,
  Autocomplete,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { Tag } from 'src/@types/tags/tag';

import AddEmailers from 'src/sections/forms/edit-form/AddEmailers';
import TagSelectionPanel from 'src/sections/shared/tags-selection-panel/TagSelectionPanel';

import reorder from '../approvers/utils/reorder';
import DateElement from '../elements/DateElement';
import { useSelector } from '../../../redux/store';
import { PATH_ADMIN } from '../../../routes/paths';
import ElementLabel from '../elements/ElementLabel';
import { getSession } from '../../../utils/auth/jwt';
import FormsElementButton from './FormsElementButton';
import FormApprovers from '../approvers/FormApprovers';
import ElementWrapper from '../elements/ElementWrapper';
import CheckboxElement from '../elements/CheckboxElement';
import UserDataElement from '../elements/UserDataElement';
import DropdownElement from '../elements/DropdownElement';
import useAppSettings from '../../../hooks/useAppSettings';
import ElementSettingsDialog from './ElementSettingsDialog';
import DateRangeElement from '../elements/DateRangeElement';
import HyperlinkElement from '../elements/HyperlinkElement';
import SignatureElement from '../elements/SignatureElement';
import TypeAddEditDialog from '../shared/TypeAddEditDialog';
import StaticTextElement from '../elements/StaticTextElement';
import InputFieldElement from '../elements/InputFieldElement';
import CustomListElement from '../elements/CustomListElement';
import useNotifications from '../../../hooks/useNotifications';
import RadioButtonElement from '../elements/RadioButtonElement';
import AddressBookElement from '../elements/AddressBookElement';
import { getDepartments } from '../../../redux/slices/departments';
import { Department } from '../../../@types/departments/departments';
import { ModuleTypes } from '../../../@types/module/enums/moduleTypes';
import { handleTextAlign } from '../../../utils/forms/formBuilderUtils';
import layerBackground from '../../../assets/layer_background_pattern.png';
import { FormElementType } from '../../../@types/forms/enums/FormElementType';
import RegistrationNumberElement from '../elements/RegistrationNumberElement';
import { FormApprovalType } from '../../../@types/forms/enums/FormApprovalType';
import { ValidationPatterns } from '../../../utils/constants/validationPatterns';
import { FormProps, TypeProps, ModuleProps } from '../create-form/FormsCreateNewForm';
import { getFile, getForm, clearFormsState } from '../../../redux/slices/formBuilder';
import { DocumentModuleTypes } from '../../../@types/shared/enums/documentModuleTypes';
import { ELEMENT_NO_VALIDATION } from '../../../@types/forms/constants/ElementValidations';
import { editFormById, getFormTypes, getFormTemplatesList } from '../../../services/formService';
import {
  TextStyle,
  FontFamily,
  TextAlignment,
  TextDecoration,
} from '../../../@types/forms/enums/ElementProperties';
import {
  ElementProps,
  DocumentPageSize,
  FormApprovalStepProps,
  FormTypeTemplateProps,
  DraggableApproverProps,
} from '../../../@types/forms/types';

const { Page, pdfjs, Document } = pdf;

// ----------------------------------------------------------------------

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type FormsEditFormProps = {
  titleLocale: string;
};

type RelatedFormTemplatesProps = {
  id: number;
  name: string;
};

type FormViewportProps = {
  pageNumber: number;
  width: number;
  height: number;
  scale: number;
};

const FormsEditForm: React.FC<FormsEditFormProps> = ({ titleLocale }) => {
  const { t, currentLang } = useLocales();
  const modulesData = useAppSettings();
  const { form, file } = useSelector((state) => state.formBuilder);
  const { enqueueSuccess, enqueueError } = useNotifications();
  const { user } = getSession();
  const isSuperAdmin = user.roles ? user.roles.findIndex((r) => r === 'superadmin') > -1 : false;
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [availableModules, setAvailableModules] = useState<ModuleProps[]>([
    {
      formModuleType: ModuleTypes.HR,
      moduleTitle: 'HR',
    },
  ]);
  const [availableTypes, setAvailableTypes] = useState<TypeProps[]>([]);
  const [selectedModule, setSelectedModule] = useState<ModuleProps | null>(null);
  const [selectedType, setSelectedType] = useState<TypeProps | null>(null);
  const [layerSize, setLayerSize] = useState<DocumentPageSize | null>(null);
  const [formViewport, setFormViewport] = useState<FormViewportProps[] | null>(null);
  const [elements, setElements] = useState<ElementProps[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isAddTypeDialogOpen, setIsAddTypeDialogOpen] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<ElementProps | null>(null);
  const [dragResizeElement, setDragResizeElement] = useState<string | null>(null);
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);
  const [isRelatedFormsExpanded, setIsRelatedFormsExpanded] = useState(false);
  const [isEmailReceiversExpanded, setIsEmailReceiversExpanded] = useState(false);
  const [pageNumberScale, setPageNumberScale] = useState(1);
  const [formTemplatesList, setFormTemplatesList] = useState<RelatedFormTemplatesProps[]>([]);
  const [selectedFormTemplate, setSelectedFormTemplate] =
    useState<RelatedFormTemplatesProps | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null);
  const [relatedFormTemplates, setRelatedFormTemplates] = useState<number[]>([]);
  const [departmentIds, setDepartmentIds] = useState<string[]>([]);
  const elementLayer = useRef<HTMLElement | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [formTags, setFormTags] = useState<Tag[]>([]);
  const { departments } = useSelector((state) => state.departments);
  const [emailers, setEmailers] = useState<string[]>([]);
  const [customApprovers, setCustomApprovers] = useState<string[]>([]);
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const defaultToday = dayjs().format('YYYY-MM-DD');
  const [isEditMode, setIsEditMode] = useState(true);
  const [formApprovalSteps, setFormApprovalSteps] = useState<FormApprovalStepProps[]>([]);
  const [orderOfApprovers, setOrderOfApprovers] = useState<DraggableApproverProps[]>([]);
  const [orderOfIntegrationApprovers, setOrderOfIntegrationApprovers] = useState<
    DraggableApproverProps[]
  >([]);
  const [isDocLogixIntegration, setIsDocLogixIntegration] = useState(false);

  const DLX_APPROVERS: DraggableApproverProps[] = [
    {
      id: 'doclogix-approval',
      approvalType: FormApprovalType.DocLogixApproval,
      approvalName: t('formTemplates.fields.approvalInDoclogix'),
      checked: true,
      order: 1,
      signerEmails: [],
    },
    {
      id: 'manager-approval-doclogix',
      approvalType: FormApprovalType.ManagerApproval,
      approvalName: t('formTemplates.fields.managerApproval'),
      checked: false,
      order: 2,
      signerEmails: [],
    },
  ];

  const APPROVERS: DraggableApproverProps[] = [
    {
      id: 'manager-approval',
      approvalType: FormApprovalType.ManagerApproval,
      approvalName: t('formTemplates.fields.managerApproval'),
      checked: false,
      order: 1,
      signerEmails: [],
    },
    {
      id: 'custom-approval',
      approvalType: FormApprovalType.DefaultSignersApproval,
      approvalName: t('forms.customApprovers'),
      checked: false,
      order: 2,
      signerEmails: [],
    },
    {
      id: 'ceo-approval',
      approvalType: FormApprovalType.CEOApproval,
      approvalName: t('formTemplates.fields.ceoApproval'),
      checked: false,
      order: 3,
      signerEmails: [],
    },
    {
      id: 'owner-approval',
      approvalType: FormApprovalType.OwnerApproval,
      approvalName: t('formTemplates.fields.ownerApproval'),
      checked: false,
      order: 4,
      signerEmails: [],
    },
  ];

  const baseElementInitValues = {
    doclogixCoreAttributeShortName: null,
    x: 12,
    y: 36,
    width: 164,
    height: 1,
    font: FontFamily.Arial,
    fontSize: 12,
    textAlignment: TextAlignment.Left,
    textStyle: TextStyle.Regular,
    textDecoration: TextDecoration.None,
    color: '#000',
    rotate: 0,
    required: false,
    validations: ELEMENT_NO_VALIDATION,
  };

  const staticTextInitValues = {
    label: t('forms.elements.StaticText'),
    staticText: '',
    enableResizing: { left: true, right: true },
  };

  const inputFieldInitValues = {
    label: t('forms.elements.InputField'),
    inputType: 'text',
    numberOfCharacters: [5, 15],
    enableResizing: { left: true, right: true },
  };

  const dropdownInitValues = {
    label: t('forms.elements.Dropdown'),
    enableResizing: { left: true, right: true },
    options: [],
  };

  const checkboxInitValues = {
    label: t('forms.elements.Checkbox'),
    enableResizing: { left: true, right: true },
    options: [],
  };

  const radioInitValues = {
    label: t('forms.elements.RadioButton'),
    enableResizing: { left: true, right: true },
    options: [],
  };

  const dateInitValues = {
    label: t('forms.elements.Date'),
    enableResizing: { left: true, right: true },
    disablePast: false,
  };

  const dateRangeInitValues = {
    label: t('forms.elements.DateRange'),
    enableResizing: { left: true, right: true },
    disablePast: false,
    calendarEventType: null,
  };

  const customListInitValues = {
    label: t('forms.elements.CustomList'),
    enableResizing: { left: true, right: true },
    list: null,
  };

  const addressBookInitValues = {
    label: t('forms.elements.AddressBook'),
    enableResizing: { left: true, right: true },
    isForExternal: false,
    isForInternal: false,
    isForAll: false,
  };

  const imageInitValues = {
    label: t('forms.elements.Image'),
    enableResizing: { left: true, right: true },
  };

  const hyperlinkInitValues = {
    label: t('forms.elements.Hyperlink'),
    enableResizing: { left: true, right: true },
  };

  const signatureInitValues = {
    label: t('forms.elements.Signature'),
    height: 80,
    enableResizing: {
      bottom: true,
      bottomLeft: true,
      bottomRight: true,
      left: true,
      right: true,
      top: true,
      topLeft: true,
      topRight: true,
    },
  };

  const userInitValues = {
    label: t('forms.elements.User'),
    enableResizing: { left: true, right: true },
  };

  const registrationNumberInitValues = {
    label: t('forms.elements.RegistrationNumber'),
    enableResizing: { left: true, right: true },
    prefix: '',
  };

  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required(t('shared.errors.nameRequired')),
    formModuleType: Yup.number()
      .min(0, t('shared.errors.moduleRequired'))
      .required(t('shared.errors.moduleRequired')),
    formTypeId: Yup.number()
      .min(0, t('shared.errors.typeRequired'))
      .required(t('shared.errors.typeRequired')),
    approvalInDoclogix: Yup.boolean(),
    managerApproval: Yup.boolean(),
    ceoApproval: Yup.boolean(),
    doclogixCoreProfileId: Yup.number(),
    doclogixCoreFileId: Yup.number(),
    relatedFormTemplates: Yup.array().of(Yup.number()),
    departmentIds: Yup.array().of(Yup.number()),
    sortWeight: Yup.string().matches(ValidationPatterns.numbers, t('shared.errors.onlyNumbers')),
    defaultFormTemplate: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      id: -1,
      name: '',
      formModuleType: -1,
      formTypeId: -1,
      approvalInDoclogix: false,
      managerApproval: false,
      ceoApproval: false,
      sortWeight: 0,
      doclogixCoreProfileId: -1,
      doclogixCoreFileId: -1,
      relatedFormTemplates,
      departmentIds,
      tags: formTags.length > 0 ? formTags.map((item) => item.id) : [],
      defaultFormTemplate: false,
      approvalSteps: [],
    },

    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const tagsForSend = formTags.length > 0 ? formTags.map((item) => item.id) : [];

      const model = {
        id: values.id,
        name: values.name,
        formModuleType: values.formModuleType,
        formTypeId: values.formTypeId,
        approvalInDoclogix: values.approvalInDoclogix,
        managerApproval: values.managerApproval,
        ceoApproval: values.ceoApproval,
        sortWeight: values.sortWeight,
        doclogixCoreProfileId: values.doclogixCoreProfileId,
        doclogixCoreFileId: values.doclogixCoreFileId,
        relatedFormTemplates,
        departmentIds,
        structure: JSON.stringify(elements),
        tags: tagsForSend,
        mailGetterEmails: emailers,
        defaultSigners: customApprovers,
        defaultFormTemplate: values.defaultFormTemplate,
        // approvalSteps: isDocLogixIntegration ? DLX_APPROVERS_STEPS : formApprovalSteps,
        approvalSteps: formApprovalSteps,
      } as unknown as FormProps;

      try {
        await editFormById(model);
        enqueueSuccess(t('forms.snackbar.formCreated'));
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
      } finally {
        clearState();
        clearFormsState();
        navigate(PATH_ADMIN.forms);
      }
    },
  });

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(orderOfApprovers, source.index, destination.index);

    setOrderOfApprovers(newItems);
  };

  const getAvailableTypes = async () => {
    const module = selectedModule?.formModuleType;

    if (module !== undefined) {
      const newAvailableTypes = await getFormTypes(module);

      setAvailableTypes(newAvailableTypes);
    }
  };

  const clearState = () => {
    setNumPages(null);
    setPageNumber(1);
    setSelectedModule(null);
    setSelectedType(null);
    setFormViewport(null);
    setElements([]);
    setSelectedElement(null);
    setPageNumberScale(1);
    setRelatedFormTemplates([]);
  };

  const moduleProps = {
    options: availableModules,
    getOptionLabel: (option: ModuleProps) => option.moduleTitle,
  };

  const typeProps = {
    options: availableTypes,
    getOptionLabel: (option: TypeProps) => {
      if (currentLang.value === 'en') {
        return option.nameEN;
      }

      if (currentLang.value === 'lt') {
        return option.nameLT;
      }

      if (currentLang.value === 'lv') {
        return option.nameLV;
      }

      if (currentLang.value === 'ru') {
        return option.nameRU;
      }

      return '';
    },
  };

  const elementsOrder = [
    FormElementType.StaticText,
    FormElementType.InputField,
    FormElementType.Dropdown,
    FormElementType.Checkbox,
    FormElementType.RadioButton,
    FormElementType.Date,
    FormElementType.DateRange,
    FormElementType.Image,
    FormElementType.CustomList,
    FormElementType.AddressBook,
    FormElementType.Hyperlink,
    FormElementType.Signature,
    FormElementType.User,
    FormElementType.RegistrationNumber,
  ];

  const handleCreateSteps = () => {
    let step = 1;
    const newFormApprovalSteps: FormApprovalStepProps[] = [];
    const order = isDocLogixIntegration ? orderOfIntegrationApprovers : orderOfApprovers;

    for (let i = 0; i < order.length; i++) {
      if (order[i].checked && order[i].approvalType !== FormApprovalType.DefaultSignersApproval) {
        const newStep: FormApprovalStepProps = {
          order: step,
          approvalType: order[i].approvalType,
          signerEmails: null,
        };

        step++;
        newFormApprovalSteps.push(newStep);
      }

      if (
        order[i].checked &&
        order[i].approvalType === FormApprovalType.DefaultSignersApproval &&
        order[i].signerEmails &&
        order[i].signerEmails.length > 0
      ) {
        const newStep: FormApprovalStepProps = {
          order: step,
          approvalType: order[i].approvalType,
          signerEmails:
            order[i].signerEmails && order[i].signerEmails.length > 0
              ? order[i].signerEmails.join(',')
              : null,
        };

        step++;
        newFormApprovalSteps.push(newStep);
      }
    }

    setFormApprovalSteps(newFormApprovalSteps);
  };

  const handlePdfNavButtonClick = (i: number) => {
    setPageNumber(pageNumber + i);
  };

  const onDocumentLoadSuccess = async (pdf: any) => {
    const { numPages } = pdf;
    const viewportPages: FormViewportProps[] = [];
    const layerWidth = layerSize?.width;

    setNumPages(numPages);

    for (let i = 0; i < numPages; i++) {
      let page;
      try {
        page = await pdf.getPage(i + 1);
      } catch (e) {
        enqueueError(e);
      }

      const viewport = page.getViewport({ scale: 1 });
      const { width, height } = viewport;

      if (i === 0 && layerWidth !== undefined) {
        setPageNumberScale(layerWidth / width);
      }

      viewportPages.push({
        pageNumber: i + 1,
        width,
        height,
        scale: layerWidth ? layerWidth / width : 1,
      });
    }

    setFormViewport(viewportPages);
  };

  const handleElementButtonClick = (type: number) => {
    if (type === FormElementType.StaticText) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...staticTextInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.InputField) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...inputFieldInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.Dropdown) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...dropdownInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.Checkbox) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...checkboxInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.RadioButton) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...radioInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.Date) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...dateInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.DateRange) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...dateRangeInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.CustomList) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...customListInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.AddressBook) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...addressBookInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.Image) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...imageInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.Hyperlink) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...hyperlinkInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.Signature) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...signatureInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.User) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...userInitValues,
      };

      setElements([...elements, newElement]);
    }

    if (type === FormElementType.RegistrationNumber) {
      const newElement = {
        id: `el-${Date.now()}`,
        type,
        page: pageNumber,
        ...baseElementInitValues,
        ...registrationNumberInitValues,
      };

      setElements([...elements, newElement]);
    }
  };

  const handleDragStart = (id: string) => {
    setDragResizeElement(id);
  };

  const handleDragStop = (id: string, d: DraggableData) => {
    const newElements = elements.map((e) => {
      if (e.id === id) {
        e.x = d.x / pageNumberScale;
        e.y = d.y / pageNumberScale;

        return e;
      }

      return e;
    });

    setElements(newElements);
    setDragResizeElement(null);
  };

  const handleResizeStart = (id: string) => {
    setDragResizeElement(id);
  };

  const handleResizeStop = (id: string, delta: ResizableDelta, position: Position) => {
    const newElements = elements.map((e) => {
      if (e.id === id) {
        if (delta.width !== 0) {
          e.width += delta.width / pageNumberScale;
        }

        if (delta.height !== 0) {
          e.height += delta.height / pageNumberScale;
        }

        e.x = position.x / pageNumberScale;
        e.y = position.y / pageNumberScale;

        return e;
      }

      return e;
    });

    setElements(newElements);
    setDragResizeElement(null);
  };

  const handleSettingsButtonClick = (el: ElementProps) => {
    setSelectedElement(el);
    setIsDialogOpen(true);
  };

  const handleElementChange = (el: ElementProps) => {
    const newElements = elements.filter((element) => element.id !== el.id);
    newElements.push(el);

    setElements(newElements);
  };

  const handleDeleteElement = (id?: string) => {
    if (!id) {
      id = selectedElement?.id;
    }

    const newElements = [...elements];
    const elementIndex = newElements.findIndex((e) => e.id === id);

    if (elementIndex > -1) {
      newElements.splice(elementIndex, 1);

      setElements(newElements);
    }
  };

  const handleFormTemplateInputChange = (value: RelatedFormTemplatesProps | null) => {
    const newSelectedFormTemplate = formTemplatesList.find((t) => t.id === value?.id);

    if (newSelectedFormTemplate) {
      setSelectedFormTemplate(newSelectedFormTemplate);
    }
  };

  const handleDepartmentInputChange = (value: Department | null) => {
    if (value) {
      setSelectedDepartment(value);
    }
  };

  const handleAddRelatedFormTemplate = () => {
    if (selectedFormTemplate) {
      setRelatedFormTemplates([...relatedFormTemplates, selectedFormTemplate.id]);
      setSelectedFormTemplate(null);
    }
  };

  const handleAddDepartment = () => {
    if (selectedDepartment) {
      setDepartmentIds([...departmentIds, selectedDepartment.id]);
      setSelectedDepartment(null);
    }
  };

  const handleDeleteRelatedFormTemplate = (index: number) => {
    const newRelatedFormTemplates = [...relatedFormTemplates];

    newRelatedFormTemplates.splice(index, 1);
    setRelatedFormTemplates(newRelatedFormTemplates);
  };

  const handleDeleteDepartment = (index: number) => {
    const newEmailToDepartments = [...departmentIds];

    newEmailToDepartments.splice(index, 1);
    setDepartmentIds(newEmailToDepartments);
  };

  const handleOnCloseDialog = () => {
    setIsAddTypeDialogOpen(!isAddTypeDialogOpen);
  };

  const handleOnSaveDialog = () => {
    getAvailableTypes().then();
    handleOnCloseDialog();
  };

  const handleItemChange = (item: DraggableApproverProps) => {
    if (isDocLogixIntegration) {
      const findIndex = orderOfIntegrationApprovers.findIndex(
        (approver) => approver.id === item.id
      );

      const newOrderOfIntegrationApprovers = [...orderOfIntegrationApprovers];

      newOrderOfIntegrationApprovers[findIndex] = item;

      setOrderOfIntegrationApprovers(newOrderOfIntegrationApprovers);
    } else {
      const findIndex = orderOfApprovers.findIndex((approver) => approver.id === item.id);

      const newOrderOfApprovers = [...orderOfApprovers];

      newOrderOfApprovers[findIndex] = item;

      setOrderOfApprovers(newOrderOfApprovers);
    }
  };

  const getAvailableModules = () => {
    const modules: ModuleProps[] = [];

    if (modulesData.modules.includes(ModuleTypes.HR)) {
      modules.push({
        formModuleType: DocumentModuleTypes.HR,
        moduleTitle: t('formTemplates.modules.hr'),
      });
    }

    if (modulesData.modules.includes(ModuleTypes.Accounting)) {
      modules.push({
        formModuleType: DocumentModuleTypes.Accounting,
        moduleTitle: t('formTemplates.modules.accounting'),
      });
    }

    setAvailableModules(modules);
  };

  const fetchFormTemplatesList = async () => {
    const list = await getFormTemplatesList();
    const newFormTemplatesList = list.items.map((t: FormTypeTemplateProps) => ({
      id: t.id,
      name: t.name,
    }));

    setFormTemplatesList(newFormTemplatesList);
  };

  useEffect(() => {
    handleCreateSteps();
  }, [orderOfApprovers, orderOfIntegrationApprovers]);

  useEffect(() => {
    if (elementLayer.current) {
      setLayerSize({
        width: elementLayer.current.offsetWidth,
        height: elementLayer.current.offsetHeight,
      });
    }

    if (formViewport !== null) {
      const pageNumberScale = formViewport[pageNumber - 1].scale;
      setPageNumberScale(pageNumberScale);
    }
  }, [pageNumber]);

  useEffect(() => {
    getAvailableTypes().then();
  }, [selectedModule]);

  useEffect(() => {
    if (form !== null) {
      const {
        id,
        name,
        formModuleType,
        formTypeId,
        approvalInDoclogix,
        managerApproval,
        ceoApproval,
        sortWeight,
        doclogixCoreProfileId,
        doclogixCoreFileId,
        relatedFormTemplates,
        departmentIds,
        structure,
        tags,
        mailGetterEmails,
        defaultSigners,
        defaultFormTemplate,
        approvalSteps,
      } = form;

      if (tags) {
        setFormTags(tags);
      }

      if (mailGetterEmails) {
        setEmailers(mailGetterEmails);
      }

      if (defaultSigners) {
        setCustomApprovers(defaultSigners);
      }

      if (approvalSteps) {
        const isDLXIntegration = approvalSteps.find(
          (step: FormApprovalStepProps) => step.approvalType === FormApprovalType.DocLogixApproval
        );

        if (isDLXIntegration) {
          setIsDocLogixIntegration(true);

          const newApprovers = DLX_APPROVERS.map((approver) => {
            if (approver.approvalType === FormApprovalType.DocLogixApproval) {
              return { ...approver, checked: true };
            }

            if (approver.approvalType === FormApprovalType.ManagerApproval) {
              const isManager = approvalSteps.find(
                (step: FormApprovalStepProps) =>
                  step.approvalType === FormApprovalType.ManagerApproval
              );

              return { ...approver, checked: !!isManager };
            }

            return approver;
          });

          setOrderOfIntegrationApprovers(newApprovers);
          setOrderOfApprovers(APPROVERS);
        } else {
          if (approvalSteps.length === 0) {
            setOrderOfApprovers(APPROVERS);
          } else {
            let newOrderOfApprovers: DraggableApproverProps[] = [];

            for (let i = 0; i < approvalSteps.length; i++) {
              const findApprover = APPROVERS.find(
                (approver) => approver.approvalType === approvalSteps[i].approvalType
              );

              if (findApprover) {
                newOrderOfApprovers[approvalSteps[i].order - 1] = {
                  ...findApprover,
                  order: approvalSteps[i].order,
                  checked: true,
                  signerEmails:
                    approvalSteps[i].signerEmails && approvalSteps[i].signerEmails.length > 0
                      ? approvalSteps[i].signerEmails.split(',')
                      : null,
                };
              }
            }

            let order = approvalSteps.length + 1;

            for (let i = 0; i < APPROVERS.length; i++) {
              const findApprover = newOrderOfApprovers.find(
                (approver) => approver.approvalType === APPROVERS[i].approvalType
              );

              if (!findApprover) {
                newOrderOfApprovers.push({ ...APPROVERS[i], order });
                order++;
              }
            }

            setOrderOfApprovers(newOrderOfApprovers);
          }

          setOrderOfIntegrationApprovers(DLX_APPROVERS);
        }

        setFormApprovalSteps(approvalSteps);
      }

      // FORMIK
      formik.setValues({
        id,
        name,
        formModuleType,
        formTypeId,
        approvalInDoclogix,
        managerApproval,
        ceoApproval,
        sortWeight,
        doclogixCoreProfileId,
        doclogixCoreFileId,
        relatedFormTemplates,
        departmentIds,
        defaultFormTemplate,
        approvalSteps,
        tags: formTags.length > 0 ? formTags.map((item) => item.id) : [],
      });

      // MODULE
      const filteredModule = availableModules.find((m) => m.formModuleType === formModuleType);

      if (filteredModule !== undefined) {
        setSelectedModule(filteredModule);
      }

      // ELEMENTS
      if (structure !== null) {
        const elementsStructure = JSON.parse(structure);
        setElements([...elementsStructure]);
      }

      if (relatedFormTemplates) {
        setRelatedFormTemplates(relatedFormTemplates);
      }

      if (departmentIds) {
        setDepartmentIds(departmentIds);
      }
    }
  }, [form]);

  useEffect(() => {
    const filteredType = availableTypes.filter((t) => t.id === formik.values.formTypeId);

    if (filteredType.length > 0) {
      setSelectedType(filteredType[0]);
    }
  }, [availableTypes]);

  useEffect(() => {
    clearState();
    clearFormsState();
    fetchFormTemplatesList().then();
    getAvailableModules();

    if (id !== undefined) {
      getFile(parseInt(id, 10)).then();
      getForm(parseInt(id, 10)).then();
    }

    if (!departments) {
      getDepartments({ searchQuery: null }).then();
    }
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>{`${t(titleLocale)}: ${form ? form?.name : ''}`}</Grid>

            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={<Switch />}
                onClick={() => setIsEditMode(!isEditMode)}
                label={t('forms.preview')}
              />
            </Grid>
          </Grid>
        }
      />
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <Card>
                  <CardContent>
                    {/* PDF NAVIGATION */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        alignItems: 'center',
                        mb: 1,
                      }}
                    >
                      <IconButton
                        aria-label="arrow-back"
                        size="large"
                        disabled={pageNumber === 1}
                        onClick={() => handlePdfNavButtonClick(-1)}
                      >
                        <ArrowBackIosNewIcon />
                      </IconButton>

                      <Typography>
                        {t('forms.documentNavigation.page')} {pageNumber}{' '}
                        {t('forms.documentNavigation.of')} {numPages}
                      </Typography>

                      <IconButton
                        aria-label="arrow-forward"
                        size="large"
                        disabled={pageNumber === numPages}
                        onClick={() => handlePdfNavButtonClick(1)}
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Box>

                    <Box sx={{ position: 'relative' }}>
                      {/* PDF DOCUMENT */}
                      <Box>
                        <Document
                          file={file}
                          onLoadSuccess={onDocumentLoadSuccess}
                          externalLinkTarget="_blank"
                        >
                          <Page
                            pageNumber={pageNumber}
                            width={layerSize !== null ? layerSize.width : undefined}
                          />
                        </Document>
                      </Box>

                      {/* LAYER FOR DRAG & DROP ELEMENTS */}
                      <Box
                        ref={elementLayer}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundImage: isEditMode ? `url(${layerBackground})` : 'none',
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 1,
                            zIndex: 1000,
                            overflow: 'hidden',
                          }}
                        >
                          {isEditMode &&
                            elements.length > 0 &&
                            formViewport &&
                            elements
                              .filter((element) => element.page === pageNumber)
                              .map((el) => (
                                <>
                                  {/* Element isn't Image */}
                                  {/* Element isn't StaticText */}
                                  {/* Element isn't Checkbox */}
                                  {/* Element isn't RadioButton */}
                                  {/* Element isn't Date */}
                                  {/* Element isn't DateRange */}
                                  {/* Element isn't Hyperlink */}
                                  {/* Element isn't Signature */}
                                  {/* Element isn't User */}
                                  {/* Element isn't RegistrationNumber */}
                                  {el.type !== FormElementType.Image &&
                                    el.type !== FormElementType.StaticText &&
                                    el.type !== FormElementType.Checkbox &&
                                    el.type !== FormElementType.RadioButton &&
                                    el.type !== FormElementType.Date &&
                                    el.type !== FormElementType.DateRange &&
                                    el.type !== FormElementType.Hyperlink &&
                                    el.type !== FormElementType.Signature &&
                                    el.type !== FormElementType.User &&
                                    el.type !== FormElementType.RegistrationNumber && (
                                      <ElementWrapper
                                        key={el.id}
                                        element={el}
                                        pageNumberScale={pageNumberScale}
                                        isDialogOpen={isDialogOpen}
                                        handleSettingsButtonClick={handleSettingsButtonClick}
                                        handleDragStart={handleDragStart}
                                        handleDragStop={handleDragStop}
                                        handleResizeStart={handleResizeStart}
                                        handleResizeStop={handleResizeStop}
                                        handleDeleteElement={handleDeleteElement}
                                      >
                                        <ElementLabel
                                          el={el}
                                          label={el.label}
                                          pageNumberScale={pageNumberScale}
                                        />
                                      </ElementWrapper>
                                    )}

                                  {/* ELEMENT => Image */}
                                  {el.type === FormElementType.Image && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      {el.file && <img src={`${el.file}`} alt={el.label} />}
                                      {!el.file && (
                                        <ElementLabel
                                          el={el}
                                          label={el.label}
                                          pageNumberScale={pageNumberScale}
                                        />
                                      )}
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => StaticText */}
                                  {el.type === FormElementType.StaticText && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <StaticTextElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                      />
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => Checkbox */}
                                  {el.type === FormElementType.Checkbox && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <CheckboxElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage={false}
                                      />
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => RadioButton */}
                                  {el.type === FormElementType.RadioButton && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <RadioButtonElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage={false}
                                      />
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => Date */}
                                  {el.type === FormElementType.Date && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <DateElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage={false}
                                      />
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => DateRange */}
                                  {el.type === FormElementType.DateRange && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <DateRangeElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage={false}
                                      />
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => Hyperlink */}
                                  {el.type === FormElementType.Hyperlink && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <HyperlinkElement el={el} pageNumberScale={pageNumberScale} />
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => Registration Number */}
                                  {el.type === FormElementType.RegistrationNumber && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <RegistrationNumberElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                      />
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => Signature */}
                                  {el.type === FormElementType.Signature && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <SignatureElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage={false}
                                      />
                                    </ElementWrapper>
                                  )}

                                  {/* ELEMENT => User */}
                                  {el.type === FormElementType.User && (
                                    <ElementWrapper
                                      key={el.id}
                                      element={el}
                                      pageNumberScale={pageNumberScale}
                                      isDialogOpen={isDialogOpen}
                                      handleSettingsButtonClick={handleSettingsButtonClick}
                                      handleDragStart={handleDragStart}
                                      handleDragStop={handleDragStop}
                                      handleResizeStart={handleResizeStart}
                                      handleResizeStop={handleResizeStop}
                                      handleDeleteElement={handleDeleteElement}
                                    >
                                      <UserDataElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage={false}
                                      />
                                    </ElementWrapper>
                                  )}
                                </>
                              ))}
                          {!isEditMode &&
                            elements &&
                            elements.length > 0 &&
                            formViewport &&
                            elements
                              .filter((element) => element.page === pageNumber)
                              .map((el) => {
                                const scaledDimensions = {
                                  x: el.x * pageNumberScale,
                                  y: el.y * pageNumberScale,
                                  width: el.width * pageNumberScale,
                                  height: el.height * pageNumberScale,
                                  fontSize: el.fontSize * pageNumberScale,
                                };

                                // ELEMENT => StaticText
                                if (el.type === FormElementType.StaticText) {
                                  return (
                                    <Box
                                      key={el.id}
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <StaticTextElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isMobileView={false}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => InputField
                                if (el.type === FormElementType.InputField) {
                                  return (
                                    <Box
                                      key={el.id}
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <InputFieldElement
                                        isChild={el.isChild}
                                        el={el}
                                        scaledDimensions={scaledDimensions}
                                        handleTextAlign={handleTextAlign}
                                        handleInputFieldChange={() => {}}
                                        pageNumberScale={pageNumberScale}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => Dropdown
                                if (el.type === FormElementType.Dropdown) {
                                  return (
                                    <Box
                                      key={el.id}
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <DropdownElement
                                        el={el}
                                        scaledDimensions={scaledDimensions}
                                        handleTextAlign={handleTextAlign}
                                        handleDropdownChange={() => {}}
                                        pageNumberScale={pageNumberScale}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => Checkbox
                                if (el.type === FormElementType.Checkbox) {
                                  return (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                      }}
                                    >
                                      <CheckboxElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        handleCheckboxChange={() => {}}
                                        isFormFillerPage
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => RadioButton
                                if (el.type === FormElementType.RadioButton) {
                                  return (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                      }}
                                    >
                                      <RadioButtonElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        handleRadioButtonChange={() => {}}
                                        isFormFillerPage
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => Date
                                if (el.type === FormElementType.Date) {
                                  return (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                      }}
                                    >
                                      <DateElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage
                                        elementContentValue={dayjs(defaultToday)}
                                        handleDateChange={() => {}}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => DateRange
                                if (el.type === FormElementType.DateRange) {
                                  return (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                      }}
                                    >
                                      <DateRangeElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage
                                        elementContentValue={[
                                          dayjs(defaultToday),
                                          dayjs(defaultToday),
                                        ]}
                                        handleDateRangeChange={() => {}}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => Image

                                if (el.type === FormElementType.Image) {
                                  return (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: '100%',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <img
                                        src={`${el.file}`}
                                        alt={`${el.label}`}
                                        width={el.width * pageNumberScale}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => CustomList
                                if (el.type === FormElementType.CustomList) {
                                  return (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <CustomListElement
                                        el={el}
                                        scaledDimensions={scaledDimensions}
                                        handleTextAlign={handleTextAlign}
                                        handleDropdownChange={() => {}}
                                        pageNumberScale={pageNumberScale}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => AddressBook
                                if (el.type === FormElementType.AddressBook) {
                                  return (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <AddressBookElement
                                        el={el}
                                        groupedUsers={[]}
                                        handleAddressBookChange={() => {}}
                                        pageNumberScale={pageNumberScale}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => Hyperlink
                                if (el.type === FormElementType.Hyperlink) {
                                  return (
                                    <Box
                                      key={el.id}
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <HyperlinkElement el={el} pageNumberScale={pageNumberScale} />
                                    </Box>
                                  );
                                }

                                // ELEMENT => Signature
                                if (el.type === FormElementType.Signature) {
                                  const size = {
                                    width: scaledDimensions.width,
                                    height: scaledDimensions.height,
                                  };

                                  return (
                                    <Box
                                      key={el.id}
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                        height: scaledDimensions.height,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <SignatureElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage
                                        handleSignatureChange={() => {}}
                                        size={size}
                                      />
                                    </Box>
                                  );
                                }

                                // ELEMENT => User
                                if (el.type === FormElementType.User) {
                                  return (
                                    <Box
                                      key={el.id}
                                      sx={{
                                        position: 'absolute',
                                        top: scaledDimensions.y,
                                        left: scaledDimensions.x,
                                        width: scaledDimensions.width,
                                        height: scaledDimensions.height,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <UserDataElement
                                        el={el}
                                        pageNumberScale={pageNumberScale}
                                        isFormFillerPage
                                        handleProfileOptionLabelChange={() => {}}
                                      />
                                    </Box>
                                  );
                                }
                              })}
                        </Box>

                        <ElementSettingsDialog
                          open={isDialogOpen}
                          handleClose={setIsDialogOpen}
                          handleDelete={handleDeleteElement}
                          element={selectedElement}
                          elements={elements}
                          setElements={setElements}
                          handleChange={(changedElement) => handleElementChange(changedElement)}
                        />
                      </Box>
                    </Box>

                    {/* PDF NAVIGATION */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        alignItems: 'center',
                        mt: 1,
                      }}
                    >
                      <IconButton
                        aria-label="arrow-back"
                        size="large"
                        disabled={pageNumber === 1}
                        onClick={() => handlePdfNavButtonClick(-1)}
                      >
                        <ArrowBackIosNewIcon />
                      </IconButton>

                      <Typography>
                        {t('forms.documentNavigation.page')} {pageNumber}{' '}
                        {t('forms.documentNavigation.of')} {numPages}
                      </Typography>

                      <IconButton
                        aria-label="arrow-forward"
                        size="large"
                        disabled={pageNumber === numPages}
                        onClick={() => handlePdfNavButtonClick(1)}
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: 3,
                }}
              >
                {/* Elements */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                    <CardHeader title={t('forms.elementsTitle')} />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Box
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          {elementsOrder.map((el, i) => (
                            <FormsElementButton
                              key={`element-${i + 1}`}
                              type={el}
                              onClick={handleElementButtonClick}
                            />
                          ))}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>

                  {/* Approvers */}
                  <FormApprovers
                    isDocLogixIntegration={isDocLogixIntegration}
                    setIsDocLogixIntegration={setIsDocLogixIntegration}
                    items={isDocLogixIntegration ? orderOfIntegrationApprovers : orderOfApprovers}
                    handleItemChange={handleItemChange}
                    onDragEnd={onDragEnd}
                  />

                  {/* Email receivers */}
                  <Accordion sx={{ p: 3 }} expanded={isEmailReceiversExpanded}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        px: 3,
                        py: isEmailReceiversExpanded ? 3 : 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      onClick={() => setIsEmailReceiversExpanded(!isEmailReceiversExpanded)}
                      id="RQjyHC"
                    >
                      <Typography variant="h6"> {t('forms.emailReceivers')}</Typography>
                      <Box sx={{ px: 2, display: isEmailReceiversExpanded ? 'block' : 'none' }}>
                        <Tooltip
                          title={
                            <Box sx={{ p: 1 }}>
                              <Typography variant="body1">
                                {t('forms.emailReceiversInfo')}
                              </Typography>
                            </Box>
                          }
                          arrow
                          placement="bottom-end"
                        >
                          <HelpIcon
                            sx={{
                              color: isLight ? theme.palette.grey[600] : theme.palette.grey[500],
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ py: 3, pl: 3, pr: 2, pt: 0 }}>
                      <Typography variant="body2">{t('forms.departments')}</Typography>

                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          my: 1,
                          mr: 0,
                          pr: 0,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Autocomplete
                            id="edit-form-email-receivers-select-department"
                            value={selectedDepartment}
                            onChange={(_, newInputValue) => {
                              handleDepartmentInputChange(newInputValue);
                            }}
                            options={
                              departments
                                ? departments.items.filter((d) => {
                                    if (departmentIds.indexOf(d.id) === -1) {
                                      return d.name;
                                    }
                                  })
                                : []
                            }
                            getOptionLabel={(option) => option.name}
                            fullWidth
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('forms.chooseDepartment')}
                                fullWidth
                              />
                            )}
                          />

                          <IconButton
                            disabled={selectedDepartment === null}
                            onClick={() => handleAddDepartment()}
                            id="dzYlHN"
                          >
                            <AddIcon color={selectedDepartment ? 'primary' : 'disabled'} />
                          </IconButton>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 1,
                            width: '100%',
                            maxHeight: '160px',
                            overflow: 'auto',
                          }}
                        >
                          {departmentIds.length > 0 &&
                            departmentIds.map((d, i) => (
                              <Box
                                key={`form-template-${d}`}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Typography noWrap>
                                  {departments
                                    ? departments.items.find((dep) => dep.id === d)?.name
                                    : ''}
                                </Typography>

                                <IconButton onClick={() => handleDeleteDepartment(i)} id="QT00g0">
                                  <ClearIcon fontSize="small" color="error" />
                                </IconButton>
                              </Box>
                            ))}
                        </Box>
                      </FormGroup>

                      <AddEmailers emailers={emailers} setEmailers={setEmailers} />
                    </AccordionDetails>
                  </Accordion>

                  {/* Settings */}
                  <Accordion sx={{ p: 1 }} expanded={isSettingsExpanded}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ px: 3, py: isSettingsExpanded ? 3 : 2 }}
                      onClick={() => setIsSettingsExpanded(!isSettingsExpanded)}
                      id="irT7Y6"
                    >
                      <Typography variant="h6"> {t('forms.settings')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 3, pt: 0 }}
                    >
                      <TextField
                        label={t('formTemplates.writeName')}
                        id="template-name"
                        {...formik.getFieldProps('name')}
                        error={Boolean(formik.touched.name && formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                      />

                      <Autocomplete
                        {...moduleProps}
                        id="select-module"
                        value={selectedModule}
                        onChange={(_, value) => {
                          if (value !== undefined && value !== null) {
                            setSelectedModule(value);
                          }

                          formik.setFieldValue('formModuleType', value?.formModuleType);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label={t('formTemplates.selectModule')}
                            {...formik.getFieldProps('formModuleType')}
                            {...params}
                            error={Boolean(
                              formik.touched.formModuleType && formik.errors.formModuleType
                            )}
                            helperText={
                              formik.touched.formModuleType && formik.errors.formModuleType
                            }
                          />
                        )}
                        size="small"
                      />

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Autocomplete
                          id="edit-form-settings-select-type"
                          sx={{ width: '100%' }}
                          disabled={availableTypes.length === 0}
                          {...typeProps}
                          value={selectedType}
                          onChange={(_, value) => {
                            formik.setFieldValue('formTypeId', value?.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              label={t('formTemplates.selectType')}
                              {...formik.getFieldProps('formTypeId')}
                              {...params}
                              error={Boolean(formik.touched.formTypeId && formik.errors.formTypeId)}
                              helperText={formik.touched.formTypeId && formik.errors.formTypeId}
                            />
                          )}
                          size="small"
                        />

                        <Link
                          id="edit-form-settings-add-type"
                          sx={{ minWidth: 'max-content', textAlign: 'left' }}
                          component="button"
                          variant="body1"
                          onClick={(event) => {
                            event.preventDefault();
                            setIsAddTypeDialogOpen(true);
                          }}
                        >
                          {t('forms.buttons.addAnotherType')}
                        </Link>
                      </Box>

                      <TagSelectionPanel tags={formTags} setTags={setFormTags} />

                      <Box sx={{ mt: 2, mb: 1 }}>
                        <TextField
                          sx={{ width: '100%' }}
                          label={t('formTemplates.sortWeight')}
                          id="sort-weight"
                          {...formik.getFieldProps('sortWeight')}
                          error={Boolean(formik.touched.sortWeight && formik.errors.sortWeight)}
                          helperText={formik.touched.sortWeight && formik.errors.sortWeight}
                          size="small"
                        />
                      </Box>

                      {isSuperAdmin && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                          <TextField
                            sx={{ width: '100%' }}
                            label={t('formTemplates.DLXDocProfileID')}
                            id="dlx-doc-profile-id"
                            {...formik.getFieldProps('doclogixCoreProfileId')}
                            error={Boolean(
                              formik.touched.doclogixCoreProfileId &&
                                formik.errors.doclogixCoreProfileId
                            )}
                            helperText={
                              formik.touched.doclogixCoreProfileId &&
                              formik.errors.doclogixCoreProfileId
                            }
                            size="small"
                          />

                          <TextField
                            sx={{ width: '100%' }}
                            label={t('formTemplates.DLXDocFileID')}
                            id="dlx-doc-file-id"
                            {...formik.getFieldProps('doclogixCoreFileId')}
                            error={Boolean(
                              formik.touched.doclogixCoreFileId && formik.errors.doclogixCoreFileId
                            )}
                            helperText={
                              formik.touched.doclogixCoreFileId && formik.errors.doclogixCoreFileId
                            }
                            size="small"
                          />

                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.getFieldProps('defaultFormTemplate').value}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    formik.setValues({
                                      ...formik.values,
                                      defaultFormTemplate: event.target.checked,
                                    });
                                  }}
                                />
                              }
                              label={t('forms.defaultFormTemplate')}
                            />
                          </FormGroup>
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  {/* Related forms */}
                  <Accordion sx={{ p: 1 }} expanded={isRelatedFormsExpanded}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ px: 3, py: isRelatedFormsExpanded ? 3 : 2 }}
                      onClick={() => setIsRelatedFormsExpanded(!isRelatedFormsExpanded)}
                      id="8Pg0uq"
                    >
                      <Typography variant="h6"> {t('forms.relatedForms')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ py: 3, pl: 3, pr: 2, pt: 0 }}>
                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                          <Autocomplete
                            id="edit-form-related-forms-select-template"
                            value={selectedFormTemplate}
                            onChange={(_, newInputValue) => {
                              handleFormTemplateInputChange(newInputValue);
                            }}
                            options={formTemplatesList.filter((t) => {
                              if (
                                relatedFormTemplates.indexOf(t.id) === -1 &&
                                t.id !== formik.values.id
                              ) {
                                return t.name;
                              }
                            })}
                            getOptionLabel={(option) => option.name}
                            fullWidth
                            size="small"
                            renderInput={(params) => (
                              <TextField {...params} label={t('templates.templates')} />
                            )}
                          />

                          <IconButton
                            disabled={selectedFormTemplate === null}
                            onClick={() => handleAddRelatedFormTemplate()}
                            id="qhzA5Z"
                          >
                            <AddIcon color={selectedFormTemplate ? 'primary' : 'disabled'} />
                          </IconButton>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 1,
                            width: '100%',
                            maxHeight: '160px',
                            overflow: 'auto',
                          }}
                        >
                          {relatedFormTemplates.length > 0 &&
                            relatedFormTemplates.map((t, i) => (
                              <Box
                                key={`form-template-${t}`}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Typography noWrap>
                                  {formTemplatesList
                                    ? formTemplatesList.find((temp) => temp.id === t)?.name
                                    : t}
                                </Typography>

                                <IconButton
                                  onClick={() => handleDeleteRelatedFormTemplate(i)}
                                  id="ax8Xk3"
                                >
                                  <ClearIcon fontSize="small" color="error" />
                                </IconButton>
                              </Box>
                            ))}
                        </Box>
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                </Box>

                {/* Action */}
                <Button type="submit" variant="contained" size="medium" fullWidth id="yd0sown5">
                  {t('forms.buttons.save')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Form>
      </FormikProvider>

      <TypeAddEditDialog
        onClose={handleOnCloseDialog}
        onSave={handleOnSaveDialog}
        formId={-1}
        isTypeAddEditDialogOpen={isAddTypeDialogOpen}
      />
    </Card>
  );
};

export default FormsEditForm;
