import axios from 'src/utils/httpClients/axios';

import { AUTH0_API } from 'src/config';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { AddressBookFilter } from 'src/@types/addressBook/addressBookFilter';
import { UpdateUser, AddressBookUser } from 'src/@types/users/addressBookUser';

import { AddressBookFilterState } from '../../@types/addressBook/types';

const baseUrl = `${AUTH0_API.domain}api/address-book`;

async function getPagedUsers(filter: AddressBookFilter) {
  const response = await axios.get<PagedResponse<AddressBookUser>>(baseUrl, {
    params: { ...filter },
  });

  const data = {
    total: response.data.total,
    items: response.data.items,
  };

  return data;
}

async function getFavoriteUsers(filter: AddressBookFilter) {
  const response = await axios.get<PagedResponse<AddressBookUser>>(`${baseUrl}/favorites`, {
    params: { ...filter },
  });

  const data = {
    total: response.data.total,
    items: response.data.items,
  };

  return data;
}

async function updateUser(model: UpdateUser, id: string) {
  await axios.put(`${baseUrl}/${id}`, model);
}

async function createUser(model: UpdateUser) {
  await axios.post(baseUrl, model);
}

async function switchFavorite(id: string) {
  await axios.put(`${baseUrl}/${id}/switch-favorite`);
}

async function exportCsv(model: AddressBookFilterState) {
  const response = await axios.post(`${baseUrl}/export-csv`, {
    ...model,
    offset: model.page - 1,
    limit: model.pageSize,
    timezoneOffset: new Date().getTimezoneOffset(),
  });
  return new Blob([response.data]);
}

const aspAddressBookService = {
  getPagedUsers,
  getFavoriteUsers,
  updateUser,
  createUser,
  switchFavorite,
  exportCsv,
};

export default aspAddressBookService;
