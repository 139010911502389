import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Form, useFormik, FormikProvider } from 'formik';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Grid,
  Stack,
  Button,
  Dialog,
  Checkbox,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
  InputAdornment,
  FormControlLabel,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';

import { useSelector } from 'src/redux/store';
import { getAddressBookUsers } from 'src/redux/slices/addressBook';
import { AddressBookUser } from 'src/@types/users/addressBookUser';
import { AddressBookFilter } from 'src/@types/addressBook/addressBookFilter';
import { Department, UpdateDepartment } from 'src/@types/departments/departments';
import { createDepartment, updateDepartment } from 'src/redux/slices/departments';

import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import ErrorForInput from 'src/components/ErrorForInput';

// ----------------------------------------------------------------------

type DepartmentsDialogProps = {
  open: boolean;
  department?: Department;
  handleClose: (update?: boolean) => void;
};

export default function DepartmentsDialog({
  open,
  department,
  handleClose,
}: DepartmentsDialogProps) {
  const { t } = useLocales();
  const { enqueueSuccess } = useNotifications();
  const { isLoading: usersLoading, users } = useSelector((state) => state.addressBook);
  const [selectedUsers, setSelectedUsers] = useState(department?.userIds || []);
  const [sortedUsers, setSortedUsers] = useState<AddressBookUser[]>();
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const usersFilter = new AddressBookFilter();
    getAddressBookUsers(usersFilter).then(() => {
      const sorted = users?.items.slice();
      setSortedUsers(sorted?.sort((a, b) => (selectedUsers.includes(a.id) ? -1 : 0)));
    });
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('departments.errors.nameRequired')),
    manager: Yup.string().required(t('departments.errors.managerRequired')),
  });

  const formik = useFormik({
    initialValues: {
      name: department?.name || '',
      manager: department?.managerUserId || '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const model = {
        name: values.name,
        managerUserId: values.manager,
        userIds: selectedUsers,
      } as UpdateDepartment;

      try {
        if (department) {
          await updateDepartment(model, department.id);
          enqueueSuccess('departments.snackbar.editDepartmentSuccessfully');
        } else {
          await createDepartment(model);
          enqueueSuccess('departments.snackbar.createDepartmentSuccessfully');
        }

        handleClose(true);
        setSubmitting(false);
      } catch (error) {
        setErrors(error);
        setSubmitting(false);
      }
    },
  });

  const onClose = () => {
    handleClose();
  };

  const getTitle = () =>
    department ? t('departments.editDepartment') : t('departments.createDepartment');

  const handleSelectUser = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedUsers(selectedUsers.filter((userId) => userId !== id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  const filterTerms = filter.toLowerCase().trim().split(/\s+/);
  const filteredUsers = sortedUsers?.filter((user) =>
    filterTerms.every(
      (term) =>
        user.email.toLowerCase().includes(term) ||
        user.firstName.toLowerCase().includes(term) ||
        user.lastName.toLowerCase().includes(term)
    )
  );

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => handleClose()}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ mb: 2 }}>{getTitle()}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={formik.isSubmitting}
                  label={t('departments.fields.name')}
                  size="small"
                  type="text"
                  {...formik.getFieldProps('name')}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  id="uhxd4jlp"
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={users?.items.find((t) => t.id === formik.values.manager) || null}
                  options={users?.items || []}
                  fullWidth
                  size="small"
                  getOptionLabel={(option) => option.email}
                  onChange={(e, value) => {
                    formik.setFieldValue('manager', value?.id);
                  }}
                  id="yvvxwg4w"
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...formik.getFieldProps('manager')}
                      error={Boolean(formik.touched.manager && formik.errors.manager)}
                      {...params}
                      type="text"
                      label={t('departments.fields.manager')}
                      id="editDepartment01"
                    />
                  )}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id} id={`editDepartment02_${option.id}`}>
                      {option.email}
                    </MenuItem>
                  )}
                />
                <ErrorForInput
                  isError={Boolean(formik.touched.manager && formik.errors.manager)}
                  message={formik.touched.manager && formik.errors.manager}
                />
              </Grid>
            </Grid>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('departments.addUsers')}
            </Typography>
            {users && (
              <>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  label={t('shared.fields.search')}
                  value={filter}
                  sx={{ mb: 2 }}
                  onChange={(e) => setFilter(e.target.value)}
                  id="k5ipi7sj"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setFilter('')}
                          size="large"
                          id="1qHDjC"
                        >
                          <Iconify icon="codicon:close" width="24" height="24" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Scrollbar
                  autoHide={false}
                  sx={{ minHeight: '250px', maxHeight: '250px', mb: 3, pr: 2 }}
                >
                  <Stack direction="column" spacing={1.5}>
                    {filteredUsers?.map((u, index) => (
                      <FormControlLabel
                        key={index}
                        label={
                          <>
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                              {`${u.firstName} ${u.lastName}`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {u.email}
                            </Typography>
                          </>
                        }
                        sx={{ marginX: 0 }}
                        control={
                          <Checkbox
                            checked={selectedUsers.includes(u.id) || false}
                            onChange={() =>
                              handleSelectUser(u.id, selectedUsers.includes(u.id) || false)
                            }
                            id={`editDepartment03_${index}`}
                          />
                        }
                      />
                    ))}
                  </Stack>
                </Scrollbar>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined" color="inherit" id="mz5nnxqa">
              {t('shared.buttons.cancel')}
            </Button>
            <LoadingButton loading={usersLoading} type="submit" variant="contained" id="yd0sown5">
              {t('shared.buttons.save')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
