import React from 'react';

import TagLabel from '../../components/TagLabel';
import Scrollbar from '../../components/Scrollbar';

// ----------------------------------------------------------------

type TagProps = {
  color: string;
  id: number;
  name: string;
};

type RenderTagFieldProps = {
  tags: TagProps[];
};

const RenderTagField: React.FC<RenderTagFieldProps> = ({ tags }) => (
    <Scrollbar>
      {tags.length > 0 && tags.map((tag) => <TagLabel color={tag.color}>{tag.name}</TagLabel>)}
    </Scrollbar>
  );

export default RenderTagField;
