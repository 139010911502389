import { PagedResponse } from 'src/@types/shared/pagedResponse';

import { AddressBookUser } from '../users/addressBookUser';

export type AddressBookState = {
  isLoading: boolean;
  error: Error | string | null;
  users: PagedResponse<AddressBookUser> | null;
  externalUsers: PagedResponse<AddressBookUser> | null;
  internalUsers: PagedResponse<AddressBookUser> | null;
  selectedId: number | null;
};

export type UsersFilterState = {
  page: number;
  pageSize: number;
  searchQuery: string;
};

export type AddressBookFilterState = {
  page: number;
  pageSize: number;
  searchQuery: string;
  nameFilterQuery: string;
  emailFilterQuery: string;
  jobTitleFilterQuery: string;
  departmentFilterQuery: string;
};

export enum AddressBookType {
  ExternalUser = 'externalUser',
  InternalUser = 'internal',
}
