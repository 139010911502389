// NEW FORM BUILDER COMPONENT

import { debounce } from 'lodash';
import fileDownload from 'js-file-download';
import React, { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Card,
  Stack,
  Table,
  Button,
  Skeleton,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  TableContainer,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { useSelector } from 'src/redux/store';
import { SortedTableCell } from 'src/@types/shared/sortedTableCell';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';
import { useConfirmationModalContext } from 'src/contexts/ConfirmationModalContext';
import { FormTemplatesFilter } from 'src/@types/form-templates/formTemplatesFilter';
import {
  getTypes,
  removeFormType,
  setStartLoading,
  setFinishLoading,
} from 'src/redux/slices/formBuilder';

import Tip from 'src/components/Tip';
import Mobile from 'src/components/Mobile';
import Desktop from 'src/components/Desktop';
import Scrollbar from 'src/components/Scrollbar';
import SortedHeader from 'src/components/tables/SortedHeader';

import Item3dotDialog from 'src/sections/shared/item3dotDialog';

import { fDateTime } from '../../utils/formatTime';
import formService from '../../services/formService';
import { FormTypeProps } from '../../@types/forms/types';
import TypeAddEditDialog from './shared/TypeAddEditDialog';
import { SKELETON_BGCOLOR, SKELETON_ANIMATION } from '../../utils/constants/dlxDriveConstants';

// ----------------------------------------------------------------------

enum FormTemplatesSortingColumns {
  Name = 0,
}

const tableColumns: SortedTableCell[] = [
  {
    // sortingKey: FormTemplatesSortingColumns.Name,
    title: 'shared.fields.name',
  },
  {
    title: 'formTemplates.module',
  },
  {
    title: '',
  },
];

type FormTemplatesFilterState = {
  page: number;
  pageSize: number;
  searchQuery: string;
  sortingColumn: FormTemplatesSortingColumns | null;
  sortDescending: boolean | null;
};

type FormTypesListProps = {
  downloadTypesReport: boolean;
  setDownloadTypesReport: (value: boolean) => void;
};

const FormTypesList: React.FC<FormTypesListProps> = ({
  downloadTypesReport,
  setDownloadTypesReport,
}) => {
  const { t } = useLocales();
  const { types, isLoading } = useSelector((state) => state.formBuilder);
  const isMobile = useResponsive('down', 'sm');

  // ---- Not done backend part ----
  // const [searchButtonPressed, setSearchButtonPressed] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    pageSize: isMobile ? 5 : 10,
    searchQuery: '',
    sortingColumn: null,
    sortDescending: null,
  } as FormTemplatesFilterState);

  // ---- Not done backend part ----
  // function pageCount() {
  //   return Math.ceil((types?.total || 0) / filter.pageSize);
  // }

  // const handlePageChange = (event: any, value: number) => {
  //   setFilter({ ...filter, page: value });
  // };

  const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, searchQuery: event.target.value, page: 1 });
  };

  const handleSortingChange = (
    column: FormTemplatesSortingColumns | null,
    sortDescending: boolean
  ) => {
    setFilter({ ...filter, sortingColumn: column, sortDescending, page: 1 });
  };

  const handleOnCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOnSaveDialog = () => {
    handleReload();
    handleOnCloseDialog();
  };

  const reloadFormTemplates = async (filterState: FormTemplatesFilterState) => {
    const formTypesFilter = new FormTemplatesFilter();
    formTypesFilter.limit = filterState.pageSize;
    formTypesFilter.offset = filterState.page - 1;
    formTypesFilter.searchQuery = filterState.searchQuery !== '' ? filterState.searchQuery : null;
    formTypesFilter.sortingColumn = filterState.sortingColumn;
    formTypesFilter.sortDescending =
      filterState.sortingColumn !== null ? filterState.sortDescending : null;

    try {
      setStartLoading();

      await getTypes(formTypesFilter);
    } catch (error) {
      console.error(error);
    } finally {
      setFinishLoading();
    }
  };

  const debounceReloadFormTemplates = useCallback(debounce(reloadFormTemplates, 500), []);

  const onRemove = () => {
    reloadFormTemplates(filter);
  };

  const handleReload = () => {
    reloadFormTemplates(filter);
  };

  const createReport = async () => {
    const name = `${t('forms.formTypesReport')}-${fDateTime(new Date())}.csv`;
    const report = await formService.exportTypesCsv(filter);

    fileDownload(report, name);
  };

  useEffect(() => {
    if (downloadTypesReport) {
      createReport().then();

      setDownloadTypesReport(false);
    }
  }, [downloadTypesReport]);

  useEffect(() => {
    debounceReloadFormTemplates(filter);
  }, [filter]);

  function TableHeader() {
    return (
      <Stack
        direction="row"
        justifyContent={isMobile ? 'left' : 'right'}
        sx={{ px: isMobile ? 0 : 1, mb: isMobile ? 1 : 2 }}
      >
        {/* ---- Not done backend part ----*/}

        {/* <TextField */}
        {/*  size="small" */}
        {/*  sx={{ width: '200px' }} */}
        {/*  label={t('shared.fields.search')} */}
        {/*  value={filter.searchQuery} */}
        {/*  onChange={handleSearchQueryChange} */}
        {/*  id="65wq3ldh" */}
        {/* /> */}

        <Button variant="contained" onClick={() => setIsDialogOpen(true)} id="c93nppcy">
          {t('shared.buttons.create')}
        </Button>
      </Stack>
    );
  }

  return (
    <Box>
      {TableHeader()}

      <Mobile>
        <Scrollbar>
          <SortedHeader
            columns={tableColumns.filter((c) => c.sortingKey !== undefined)}
            handleSortingChange={handleSortingChange}
            isDownload={false}
            isCardView
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              opacity: 1,
            }}
          >
            {!isLoading &&
              types?.map((item) => (
                <FormTemplateItem
                  key={item.id}
                  formType={item}
                  onRemove={onRemove}
                  handleReload={handleReload}
                />
              ))}

            {isLoading &&
              [1, 2, 3, 4].map((item) => (
                <Box key={`skeleton=${item}`}>
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height="182px"
                    animation={SKELETON_ANIMATION}
                    sx={{ backgroundColor: SKELETON_BGCOLOR }}
                  />
                </Box>
              ))}
          </Box>
        </Scrollbar>
      </Mobile>

      <Desktop>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 300 }}>
            <Table>
              <TableHead>
                <SortedHeader
                  columns={tableColumns}
                  handleSortingChange={handleSortingChange}
                  isDownload={false}
                  isCardView={false}
                />
              </TableHead>

              <TableBody>
                {!isLoading &&
                  types &&
                  types.map((item) => (
                    <FormTemplateItem
                      key={item.id}
                      formType={item}
                      onRemove={onRemove}
                      handleReload={handleReload}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Desktop>

      {/* ---- Not done backend part ---- */}

      {/* <Divider /> */}

      {/* <Box sx={{ p: 2 }}> */}
      {/*  <Pagination */}
      {/*    sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }} */}
      {/*    count={pageCount()} */}
      {/*    onChange={handlePageChange} */}
      {/*    page={filter.page} */}
      {/*  /> */}
      {/* </Box> */}

      <TypeAddEditDialog
        onClose={handleOnCloseDialog}
        onSave={handleOnSaveDialog}
        formId={-1}
        isTypeAddEditDialogOpen={isDialogOpen}
      />
    </Box>
  );
};

type FormTemplateItemProps = {
  formType: FormTypeProps;
  onRemove: () => void;
  handleReload: () => void;
};

function FormTemplateItem({ formType, onRemove, handleReload }: FormTemplateItemProps) {
  const { t } = useLocales();
  const theme = useTheme();
  const { isLoading } = useSelector((state) => state.formBuilder);
  const modalContext = useConfirmationModalContext();
  const [isTypeAddEditDialogOpen, setIsTypeAddEditDialogOpen] = useState(false);

  const isLight = theme.palette.mode === 'light';

  function onFormTemplatesSelected() {
    setIsTypeAddEditDialogOpen(true);
  }

  const handleOnCloseDialog = () => {
    setIsTypeAddEditDialogOpen(false);
  };

  const handleOnSaveDialog = () => {
    handleReload();
    handleOnCloseDialog();
  };

  const rowStyle = {
    boxShadow: isLight ? '2px 3px 4px #CCCCCC' : 'none',
    cursor: 'pointer',
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const result = await modalContext.showConfirmation(
      t('components.confirmationModal.confirmation'),
      t('formTemplates.formTypes.areYouSureWantToRemovePermanently')
    );

    if (result) {
      await removeFormType(formType.id);
      onRemove();
    }
  };

  const moduleTitle = () => {
    if (formType.moduleType === DocumentModuleTypes.HR) {
      return t('formTemplates.modules.hr');
    }
    if (formType.moduleType === DocumentModuleTypes.Accounting) {
      return t('formTemplates.modules.accounting');
    }
    return null;
  };

  return (
    <>
      <Mobile>
        {!isLoading && (
          <Card
            sx={{
              gap: 0.5,
              pl: 2,
              py: 2,
              border: '1px solid #fff',
              display: 'flex',
            }}
          >
            <Box sx={{ flex: 1 }} onClick={onFormTemplatesSelected} id="x8js1aeu">
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                  <img
                    src="https://flagcdn.com/20x15/gb.png"
                    width="20"
                    height="15"
                    alt="English"
                  />

                  <Typography variant="body2">{`${formType.nameEN ? formType.nameEN : '-'}`}</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                  <img
                    src="https://flagcdn.com/20x15/lt.png"
                    width="20"
                    height="15"
                    alt="Lietuvių"
                  />

                  <Typography variant="body2">{`${formType.nameLT ? formType.nameLT : '-'}`}</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                  <img
                    src="https://flagcdn.com/20x15/lv.png"
                    width="20"
                    height="15"
                    alt="Latviski"
                  />

                  <Typography variant="body2">{`${formType.nameLV ? formType.nameLV : '-'}`}</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                  <img
                    src="https://flagcdn.com/20x15/ee.png"
                    width="20"
                    height="15"
                    alt="Eesti keel"
                  />

                  <Typography variant="body2">{`${formType.nameEE ? formType.nameEE : '-'}`}</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                  <img
                    src="https://flagcdn.com/20x15/ru.png"
                    width="20"
                    height="15"
                    alt="Русский"
                  />

                  <Typography variant="body2">{`${formType.nameRU ? formType.nameRU : '-'}`}</Typography>
                </Box>
              </Box>

              <Box sx={{ color: theme.palette.text.secondary, display: 'flex', mt: 2 }}>
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {t('formTemplates.module')}:{' '}
                </Typography>

                <Typography variant="body2" color={theme.palette.text.primary}>
                  {moduleTitle()}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Item3dotDialog handleRemove={handleRemove} isLoading={isLoading} />
            </Box>
          </Card>
        )}
      </Mobile>

      <Desktop>
        {!isLoading && (
          <TableRow sx={rowStyle} className="activeHover">
            <TableCell
              onClick={onFormTemplatesSelected}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                <img src="https://flagcdn.com/20x15/gb.png" width="20" height="15" alt="English" />

                <Typography variant="body2">{`${formType.nameEN ? formType.nameEN : '-'}`}</Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                <img src="https://flagcdn.com/20x15/lt.png" width="20" height="15" alt="Lietuvių" />

                <Typography variant="body2">{`${formType.nameLT ? formType.nameLT : '-'}`}</Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                <img src="https://flagcdn.com/20x15/lv.png" width="20" height="15" alt="Latviski" />

                <Typography variant="body2">{`${formType.nameLV ? formType.nameLV : '-'}`}</Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                <img
                  src="https://flagcdn.com/20x15/ee.png"
                  width="20"
                  height="15"
                  alt="Eesti keel"
                />

                <Typography variant="body2">{`${formType.nameEE ? formType.nameEE : '-'}`}</Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
                <img src="https://flagcdn.com/20x15/ru.png" width="20" height="15" alt="Русский" />

                <Typography variant="body2">{`${formType.nameRU ? formType.nameRU : '-'}`}</Typography>
              </Box>
            </TableCell>

            <TableCell onClick={onFormTemplatesSelected}>{moduleTitle()}</TableCell>

            <TableCell>
              <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                <Tip titleLocale="shared.buttons.delete">
                  <IconButton size="small" onClick={handleRemove} id="wxpasfp3">
                    <img src="/icons/icon_trashbin.svg" width={20} height={20} alt="delete" />
                  </IconButton>
                </Tip>
              </Box>
            </TableCell>
          </TableRow>
        )}
      </Desktop>

      <TypeAddEditDialog
        onClose={handleOnCloseDialog}
        onSave={handleOnSaveDialog}
        formId={-1}
        isTypeAddEditDialogOpen={isTypeAddEditDialogOpen}
        selectedType={formType}
      />
    </>
  );
}

export default FormTypesList;
