import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';

import useMenuDrawer from 'src/hooks/useMenuDrawer';

import Logo from '../../../components/Logo';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import cssStyles from '../../../utils/cssStyles';
import Iconify from '../../../components/Iconify';
import ThemeModeChanger from './ThemeModeChanger';
import NotificationsPopover from './NotificationsPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { ModuleTypes } from '../../../@types/module/enums/moduleTypes';
import ModuleCheck from '../../../components/access-checks/ModuleCheck';
import { DASHBOARD_HEADER_MOBILE, DASHBOARD_HEADER_DESKTOP } from '../../../config';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  boxShadow: 'none',
  width: `100%`,
  ...cssStyles(theme).bgBlur(),
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.shorter,
  }),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: DASHBOARD_HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
    minHeight: DASHBOARD_HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardHeader() {
  const menuDrawer = useMenuDrawer();

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {!menuDrawer.isLargeScreen && !menuDrawer.isOpen && (
            <IconButtonAnimate
              onClick={() => menuDrawer.onToggleMenu(true)}
              sx={{ color: 'text.primary', maxHeight: 40 }}
              id="dashboardHeader01"
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          {menuDrawer.isLargeScreen && (
            <Box
              sx={{ maxWidth: '200px' }}
              onClick={() => menuDrawer.onToggleMenu(false)}
              id="dashboardHeader02"
            >
              <Logo disabledLink />
            </Box>
          )}

          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <LanguagePopover id="dashboardHeader03" />

            <NotificationsPopover id="dashboardHeader04" />

            <ModuleCheck module={ModuleTypes.WorkflowsModule}>
              <ThemeModeChanger id="dashboardHeader05" />
            </ModuleCheck>

            <AccountPopover id="dashboardHeader06" />
          </Stack>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
