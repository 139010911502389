import { useState, useEffect } from 'react';

import { Box, Grid } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';
import useAppSettings from 'src/hooks/useAppSettings';

import documentService from 'src/services/documentService';
import { DocumentStatusCount } from 'src/@types/documents/types';
import { ModuleTypes } from 'src/@types/module/enums/moduleTypes';
import { DocumentStatuses } from 'src/@types/documents/enums/documentStatuses';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';

import DashboardSectionHeader from '../DashboardSectionHeader';
import ModuleCheck from '../../../components/access-checks/ModuleCheck';
import DashboardB2BDocumentStatusShortcut from './DashboardB2BDocumentStatusShortcut';

// ----------------------------------------------------------------------

export default function DashboardB2BQuickActions() {
  const isMobile = useResponsive('down', 'sm');
  const [statusCounts, setStatusCounts] = useState<DocumentStatusCount[]>([]);

  const modulesData = useAppSettings();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const docTypes: DocumentModuleTypes[] = [];

    if (modulesData.modules.includes(ModuleTypes.Standard)) {
      docTypes.push(DocumentModuleTypes.Standard);
    }
    if (modulesData.modules.includes(ModuleTypes.HR)) {
      docTypes.push(DocumentModuleTypes.HR);
    }
    if (modulesData.modules.includes(ModuleTypes.Accounting)) {
      docTypes.push(DocumentModuleTypes.Accounting);
    }

    const data = await documentService.getStatusCount(docTypes);
    setStatusCounts(data);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function StatusBlock({ status }: { status: DocumentStatuses }) {
    const countItems = statusCounts.filter((x) => x.status === status);
    return (
      <Grid item xs>
        <DashboardB2BDocumentStatusShortcut status={status} countItems={countItems} />
      </Grid>
    );
  }

  return (
    <Box>
      <DashboardSectionHeader localeText="dashboard.quickActions" />
      <Grid container direction={isMobile ? 'column' : 'row'} spacing={3}>
        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForMe} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForOthers} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForMeApprove} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForOthersApprove} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.Voided} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForMeToAcknowledge} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForOthersToAcknowledge} />
        </Grid>

        <ModuleCheck module={ModuleTypes.FormsModule}>
          <Grid item xs={isMobile ? 12 : 4}>
            <StatusBlock status={DocumentStatuses.WaitingForRelatedDocuments} />
          </Grid>
        </ModuleCheck>
      </Grid>
    </Box>
  );
}
