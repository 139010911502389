import { toLocal } from 'src/utils/dateTimeUtils';
import { getStatusString } from 'src/utils/documentUtils';

import { DocumentStatuses } from 'src/@types/documents/enums/documentStatuses';

import { Tag } from '../tags/tag';
import {
  DocumentBreadcrumbsType,
  DocumentFormColumnValue,
  DocumentSignersDetailsType,
} from './types';

export class Document {
  id: number;

  name: string;

  extension: string;

  created: Date;

  comment: string;

  ownerEmail: string;

  ownerFullName: string;

  isEditable: boolean;

  status: DocumentStatuses;

  alreadySigned: number;

  totalSigners: number;

  tags: Tag[];

  isFormDocument: boolean;

  isSentForApproval: boolean;

  isOnlyApprove: boolean;

  isOnlyHandwritten: boolean;

  modified: Date;

  formValues: DocumentFormColumnValue[];

  generalCustomMessage?: string;

  generalCustomMessageAck?: string;

  generalCustomMessageMail?: string;

  voided?: Date;

  voidedCustomMessage: string;

  canAccessInFolders?: boolean;

  privacyEnabled?: boolean;

  prefixName?: string;

  isFormBuilderDocument: boolean;

  hasMailGetters: boolean;

  hasUnseenAccompanyingDocuments: boolean;

  breadcrumbs: DocumentBreadcrumbsType[];

  templateName: string | null;

  approvalsLocked: boolean;

  isIntegrationEnabled: boolean;

  isIntegrationManagerNeeded: boolean;

  isSentToDoclogix: boolean;

  signerDetails: DocumentSignersDetailsType[];

  public get statusString(): string {
    return getStatusString(this.status);
  }

  public get isDoclogixDocument(): boolean {
    return this.ownerEmail.toLowerCase() === 'doclogix';
  }

  parseJson(json: any): Document | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: Document = new Document();

    model.id = json.id;
    model.name = json.name;
    model.extension = json.extension;
    model.created = toLocal(json.created);
    model.comment = json.comment;
    model.ownerEmail = json.ownerEmail;
    model.ownerFullName = json.ownerFullName;
    model.isEditable = json.isEditable;
    model.status = json.status;
    model.alreadySigned = json.alreadySigned;
    model.totalSigners = json.totalSigners;
    model.tags = (json.tags || []).map((x: any) => new Tag().parseJson(x));
    model.isFormDocument = json.isFormDocument;
    model.isSentForApproval = json.isSentForApproval;
    model.isOnlyApprove = json.isOnlyApprove;
    model.isOnlyHandwritten = json.isOnlyHandwritten;
    model.modified = toLocal(json.modified);
    model.formValues = json.formValues;
    model.generalCustomMessage = json.generalCustomMessage;
    model.generalCustomMessageAck = json.generalCustomMessageAck;
    model.generalCustomMessageMail = json.generalCustomMessageMail;
    model.voided = json.voided;
    model.voidedCustomMessage = json.voidedCustomMessage;
    model.isFormBuilderDocument = json.isFormBuilderDocument;
    model.hasMailGetters = json.hasMailGetters;
    model.hasUnseenAccompanyingDocuments = json.hasUnseenAccompanyingDocuments;
    model.breadcrumbs = json.breadcrumbs;
    model.templateName = json.templateName;
    model.approvalsLocked = json.approvalsLocked;
    model.isIntegrationEnabled = json.isIntegrationEnabled;
    model.isIntegrationManagerNeeded = json.isIntegrationManagerNeeded;
    model.isSentToDoclogix = json.isSentToDoclogix;
    model.signerDetails = json.signerDetails;

    return model;
  }
}
